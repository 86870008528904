import React from 'react';

type props = {
  timings: {
      day: string;
      timings: { opens: string; closes: string; }[];
    }[];
}

const DietitianTimings:React.FC<props> = ({timings}) => {
  const parsedTimings = timings.map((timingStr:any) => JSON.parse(timingStr));
  return (
    <div className="main p-3">
    <div className="timings-div d-flex flex-column gap-2">
      {parsedTimings.map((dayData, index) => (
        <>
            {Object.keys(dayData).map((day: string, dayIndex: number) => (
              <div key={index} className="timings-card d-flex justify-content-between p-2">

            <div  key={dayIndex} className="col d-flex gap-3 justify-content-between align-items-center">

                <p className="col-lg-4 day m-0">{day}</p>
                <div className='col-lg-8'>
                  {dayData[day].map((time: string, timeIndex: number) => (
                    <p className="time mb-1" key={timeIndex}>
                      {time}
                    </p>
                  ))}
                </div>
              </div>
              </div>
            ))}
         
         </>      
         
         ))}
    </div>
  </div>
  );
}

export default DietitianTimings;
