
import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../../../components/ui/navbar/sidenav.components';
import { Button } from 'primereact/button';
import "./rejectedStores.page.css"
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { RejectedStoreTable } from '../../../components/organicStores/RejectedStoreTable/rejectedStore-table.component';
import { DeleteStoreByID, GetOrganicStoresTableData } from '../../../api/organicStores.api';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import Loader from '../../../components/ui/loader/loader.components';


export default function RejectedStores() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(undefined);
    const [activeTooltipId, setActiveTooltipId] = useState<string | null>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [storeIdToDelete, setStoreIdToDelete] = useState<string | null>(null);

    const toast = useRef<Toast>(null);

    const show = (message: any, severity: any, summary: any) => {
        console.log("ouside of toast");
        if (toast.current) {
            console.log("going in toast");
            toast.current.show({
                severity: severity,
                summary: summary,
                detail: message,
            });
        } else {
            console.log("toast issue");
        }
    };

    const toggleTooltip = (restaurantId: string) => {
        setActiveTooltipId((prevId) => (prevId === restaurantId ? null : restaurantId));
    };
    const toggleEditButton = () => {

    };

    var width = window.screen.width;
    var c_rows = 10;
    if (width >= 1920) {
        c_rows = 15
    }
    else if (width >= 1400 || width <= 1600) {
        c_rows = 10
    }
    const [lazyParams, setLazyParams] = useState({
        approvalstatus: 2,
        first: 0,
        rows: c_rows,
        page: 0,
        sortField: '',
        sortOrder: 0,
        filters: {
            'storename': { value: '', matchMode: 'contains' },
            'city': { value: '', matchMode: 'contains' },
            'agentname': { value: '', matchMode: 'contains' },
        }
    });

    let loadLazyTimeout: any = 0;

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(async () => {
            console.log(lazyParams);
            const respObj = await GetOrganicStoresTableData(lazyParams, 2);
            console.log("Inside pending", respObj.data.organicstores);
            setCustomers(respObj.data.organicstores);
            setTotalRecords(respObj.data.totalRecords);
            setLoading(false);
        }, 100);
    }

    const onPage = (event: any) => {
        setLazyParams(event);
    }

    const onSort = (event: any) => {
        setLazyParams(event);
    }

    const onFilter = (event: any) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!(event.target as HTMLElement).closest('.tooltip-box, .pi-ellipsis-v')) {
                setActiveTooltipId(null);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const openDeleteDialog = (storeId: string) => {
        setStoreIdToDelete(storeId);
        setActiveTooltipId(null);
        setVisible(true);
    };

    const handleDeleteStore = async () => {
        setVisible(false);
        if (storeIdToDelete) {
            setLoading(true);
            try {
                const response = await DeleteStoreByID(storeIdToDelete);
                if (response?.status === 200) {
                    setCustomers((prevCustomers: any) =>
                        prevCustomers.filter((customer: { _id: string; }) => customer._id !== storeIdToDelete)
                    );
                    console.log("Iam hitting delete 200");
                    setActiveTooltipId(null);
                    setTimeout(() => {
                        setLoading(false);

                    }, 1000);
                    show("Organic Store Deleted Successfully", "info", "Info");

                }
            } catch (error) {
                console.error("Error deleting store:", error);
            }
        }
    };


    const representativeBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <img alt={rowData.representative.name} src={`https://www.primereact.org/images/avatar/${rowData.representative.image}`} width={32} style={{ verticalAlign: 'middle' }} />
                <span className="vertical-align-middle ml-2">{rowData.representative.name}</span>
            </React.Fragment>
        );
    }

    const countryBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <img alt="flag" src="https://www.primereact.org/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} width={30} />
                <span className="vertical-align-middle ml-2">{rowData.country.name}</span>
            </React.Fragment>
        );
    }
    const viewProduct = (product: any) => {
        // setDeleteProductDialog(true);
        navigate(`/organicstores/rejectedstores/details/${product._id}`)
        // window.open(`/organicstores/rejectedstores/details/${product._id}`);
    }
    const actionBodyTemplate = (rowData: any) => {
        const isTooltipVisible = activeTooltipId === rowData._id;
        return (
            <React.Fragment>
                <Tooltip
                    target=".p-dt-tooltip"
                    // content="View"
                    mouseTrack
                    mouseTrackLeft={10}
                />
                <div className='d-flex align-items-center gap-5'>
                    <Button icon="pi pi-eye" label='View Details' className="p-dt-tooltip p-button-rounded mr-2 view-bt" onClick={() => viewProduct(rowData)} />
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        <div onClick={() => toggleTooltip(rowData._id)} style={{ cursor: 'pointer' }}>
                            <i className="pi pi-ellipsis-v"></i>
                        </div>
                        {isTooltipVisible && (
                            <div className="tooltip-box">
                                <button className="tooltip-button mb-1"
                                    onClick={() => {
                                        navigate(`/organicstores/rejectedstores/editorganicstore/${rowData._id}`, { state: { from: window.location.pathname } });
                                    }}                                 >
                                    <i className="pi pi-pencil mr-2" style={{ fontSize: "12px" }}></i>Edit Organic Store
                                </button>
                                <button className="tooltip-button"
                                    onClick={() => openDeleteDialog(rowData._id)}
                                >
                                    <i className="pi pi-trash mr-2" style={{ fontSize: "12px" }}></i>Delete Organic Store
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
    return (
        <>
            <main>
                <Toast ref={toast}></Toast>

                <div>
                    <Navbar />
                    <section className="page-content">
                        <div className="ym-content-table">
                            <div className="page_title d-sm-flex">
                                <div>
                                    <span className="event-span-head">Rejected Organic Stores</span>
                                </div>
                            </div>
                            <hr className="mb-0" />
                            <div className="card pt-3 pb-3 pl-0 pr-0" style={{ border: "none" }}>
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <RejectedStoreTable
                                        customers={customers}
                                        first={lazyParams.first}
                                        sortField={lazyParams.sortField}
                                        sortOrder={lazyParams.sortOrder}
                                        totalRecords={totalRecords}
                                        onPage={onPage}
                                        onSort={onSort}
                                        onFilter={onFilter}
                                        filters={lazyParams.filters}
                                        loading={loading}
                                        countryBodyTemplate={countryBodyTemplate}
                                        representativeBodyTemplate={representativeBodyTemplate}
                                        actionBodyTemplate={actionBodyTemplate} />
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Dialog
                visible={visible}
                closeOnEscape={false}
                style={{ width: "25vw" }}
                className="delete-model"
                breakpoints={{
                    "1920px": "50vw",
                    "1420px": "50vw",
                    "1120px": "50vw",
                    "1090px": "40vw",
                    "980px": "50vw",
                    "840px": "60vw",
                    "740px": "50vw",
                    "641px": "70vw",
                    "541px": "70vw",
                    "490px": "80vw",
                    "350px": "90vw",
                }}
                onHide={() => setVisible(false)}
            >
                <div className="d-flex  justify-content-center align-items-center flex-column p-4 gap-2">
                    <div className="delete-icon-bg p-3 d-flex justify-content-center align-items-center">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 11C11.5523 11 12 11.4477 12 12V24C12 24.5523 11.5523 25 11 25C10.4477 25 10 24.5523 10 24V12C10 11.4477 10.4477 11 11 11Z" fill="#D83B01" />
                            <path d="M16 11C16.5523 11 17 11.4477 17 12V24C17 24.5523 16.5523 25 16 25C15.4477 25 15 24.5523 15 24V12C15 11.4477 15.4477 11 16 11Z" fill="#D83B01" />
                            <path d="M22 12C22 11.4477 21.5523 11 21 11C20.4477 11 20 11.4477 20 12V24C20 24.5523 20.4477 25 21 25C21.5523 25 22 24.5523 22 24V12Z" fill="#D83B01" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M29 6C29 7.10457 28.1046 8 27 8H26V26C26 28.2091 24.2091 30 22 30H10C7.79086 30 6 28.2091 6 26V8H5C3.89543 8 3 7.10457 3 6V4C3 2.89543 3.89543 2 5 2H12C12 0.895431 12.8954 0 14 0H18C19.1046 0 20 0.895431 20 2H27C28.1046 2 29 2.89543 29 4V6ZM8.23607 8L8 8.11803V26C8 27.1046 8.89543 28 10 28H22C23.1046 28 24 27.1046 24 26V8.11803L23.7639 8H8.23607ZM5 6V4H27V6H5Z" fill="#D83B01" />
                        </svg>
                    </div>
                    <div className="d-head">
                        Are you sure you want to delete organic store?
                    </div>
                    <div className="d-caption">
                        This action is permanent. Would you like to continue?
                    </div>
                </div>
                <div className="dlt-dialog-btns d-flex justify-content-between align-items-center pl-4 pr-4">
                    <Button
                        label="No"
                        onClick={() => setVisible(false)}
                        className="no_btn_dlt"
                    ></Button>
                    <Button
                        label="Yes, Delete"
                        type="submit"
                        onClick={handleDeleteStore}
                        className="yes_dlt_btn"
                    ></Button>
                </div>

            </Dialog>
        </>


    );
}
