import React, { useEffect, useRef, useState } from "react";
import "./EditRestaurant.css";
import { devsas, sas } from "../../../config/constants";
import { Button } from "primereact/button";
import { response } from "express";
import { Toast } from "primereact/toast";
import Navbar from "../../../components/ui/navbar/sidenav.components";
import { TabPanel, TabView } from "primereact/tabview";
import { getCategories, UpadteRestaurant } from "../../../api/restaurant-details.api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetRestByID } from "../../../api/pendingTable.api";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from 'react-hook-form';
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { ScrollPanel } from "primereact/scrollpanel";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import DropFileInput from "../../../components/drop-file-input/drop-file-input";
import Loader from "../../../components/ui/loader/loader.components";
import { RadioButton } from "primereact/radiobutton";

interface eateryType {
  name: string;
  code: string;
}
interface Cuisine {
  name: string;
  code: string;
}
interface valuess {
  name: string;
  code: string;
}
interface ServiceOption {
  name: string;
  code: string;
}

type resObj = {
  // lat: any;
  _id: string;
  name: string;
  address1: string;
  locality: string;
  sizeofestablishment: string;
  city: string;
  location: {
    coordinates: number[];
  };
  state: string;
  maplink: string;
  latitude: string;
  longitude: string;
  country: string;
  diettype: any[];
  pincode: string;
  landmark: string;
  website: string;
  email: string;
  phoneno: string;
  eaterytype: any[];
  foodtype: any[];
  preference: string[];
  cuisine: string[];
  ambience: string;
  serving: string[];
  mealoftheday: string[];
  serviceoptions: string[];
  values: any[];
  features: string[];
  specialitems: string[];
  source: string;
  sourcelink: string;
  about: string[];
  whyinyeldam: string[];
  zomatolink: string;
  swiggylink: string;
  dineoutlink: string;
  averagecost: string;
  district: string;
  menuitems: string[];
  menuimages: any[];
  thumbnails: any[];
  images: any[];
  tags: string;
  offers: string[];
  timings: string[];
  hygiene: number;
  health: number;
  taste: number;
  costeffective: number,
  valuesrating: number,
  hospitality: number,
  facebookurl: string;
  instaurl: string;
  youtubeurl: string;
  twitterurl: string;
};
const initialState = {
  name: "",
  _id: "",
  address1: "",
  locality: "",
  city: "",
  state: "",
  maplink: "",
  diettype: [],
  country: "",
  pincode: "",
  landmark: "",
  location: {
    coordinates: [0, 0],
  },
  latitude: "",
  longitude: "",
  website: "",
  email: "",
  phoneno: "",
  eaterytype: [],
  foodtype: [],
  preference: [],
  cuisine: [],
  ambience: "",
  serving: [],
  mealoftheday: [],
  serviceoptions: [],
  values: [],
  features: [],
  specialitems: [],
  source: "",
  sourcelink: "",
  about: [],
  whyinyeldam: [],
  images: [],
  zomatolink: "",
  swiggylink: "",
  dineoutlink: "",
  averagecost: "",
  sizeofestablishment: "",
  district: "",
  menuitems: [],
  menuimages: [],
  restaurantimages: [],
  thumbnails: [],
  tags: "",
  offers: [],
  timings: [],
  hygiene: 0,
  health: 0,
  taste: 0,
  costeffective: 0,
  valuesrating: 0,
  hospitality: 0,
  facebookurl: "",
  instaurl: "",
  youtubeurl: "",
  twitterurl: "",
};
const EditRestaurant: React.FC<any> = ({ }) => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [resObj, setResObj] = useState<resObj>(initialState);
  const [isAdding, setIsadding] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [thumbnailImages, setThumbnailImages] = useState<any[]>([]);
  const [menuImages, setMenuImages] = useState<any[]>([]);
  const [restaurantImages, setRestantImages] = useState<any[]>([]);
  const [spitems, setspitems] = useState<any>([]);
  const [menutems, setmenutems] = useState<any>([]);
  const [whyyeldam, setwhyyeldam] = useState<any>(null);
  const [aboutrest, setaboutrest] = useState<any>(null);
  const [services, setServices] = useState<any[]>([]);
  const [restaurantTages, setRestaurantTages] = useState(null);
  const [cuisine, setCuisine] = useState<any[]>([]);
  const [serviceopt, setServiceopt] = useState<any[]>([]);
  const [ambience, setAmbience] = useState<any[]>([]);
  const [mod, setMod] = useState<any[]>([]);
  const [valueslist, setValueslist] = useState<any[]>([]);
  const [cuisinelist, setCuisinelist] = useState([]);
  const [diettypelist, setDiettypelist] = useState<any[]>([]);
  const [foodtypelist, setFoodtypelist] = useState<any[]>([]);
  const [eaterytypelist, setEaterytypelist] = useState<any[]>([]);
  const [ambiencelist, setAmbiencelist] = useState([]);
  const [serviceoptionslist, setServiceoptionslist] = useState<ServiceOption[]>([]);
  const toast = useRef<Toast>(null);
  const { id, type } = useParams();
  const navigate = useNavigate();

  const mealOptions = ["Breakfast", "Lunch", "Dinner", "Brunch", "Snacks"];

  const get_categories = async () => {
    const response = await getCategories();
    console.log("resoojosdbvksv", response.data.data)
    response.data.data.forEach((item: any) => {
      switch (item.type) {
        case 'ambience':
          setAmbiencelist(item.value);
          break;
        case 'cuisines':
          setCuisinelist(item.value);
          break;
        case 'values':
          setValueslist(item.value);
          break;
        case 'serviceoptions':
          setServiceoptionslist(item.value);
          break;
        case 'eaterytype':
          setEaterytypelist(item.value);
          break;
        case 'foodtype':
          setFoodtypelist(item.value);
          break;
        case 'diettype':
          setDiettypelist(item.value);
          break;
        default:
          break;
      }
    });
  };

  const handleDeleteClick = (index: any, purpose: any) => {
    const updatedRestObj = { ...resObj };
    console.log("updatedRestObj", updatedRestObj);
    if (purpose === "ThumbnailImages") {
      const updatedThumbnails = [...resObj.thumbnails];
      updatedThumbnails.splice(index, 1);
      updatedRestObj.thumbnails = updatedThumbnails;
    } else if (purpose === "RestaurantImages") {
      const updatedImages = [...updatedRestObj.images];
      updatedImages.splice(index, 1);
      updatedRestObj.images = updatedImages;
    } else if (purpose === "MenuImages") {
      const updatedMenuImages = [...updatedRestObj.menuimages];
      updatedMenuImages.splice(index, 1);
      updatedRestObj.menuimages = updatedMenuImages;
    }

    setResObj(updatedRestObj);
  };
  const loadLazyData = async () => {
    try {
      setIsadding(true);

      const resData = await GetRestByID(id as string);

      console.log("resData", resData.data.data);
      console.log("resDataimage", resData.data.data?.thumbnails[0]);

      if (resData.data.data) {
        const restaurantData = resData.data.data;

        setspitems(restaurantData.specialitems.join(","))

        setResObj({
          ...restaurantData,
          latitude: restaurantData.location?.coordinates[1]?.toString() || "",
          longitude: restaurantData.location?.coordinates[0]?.toString() || ""
        });
      }

      setIsadding(false);
    } catch (error) {
      console.error("Error loading data:", error);
      setIsadding(false);
    }
  };


  const defaultValues = {
    restaurantname: "",
    latitude: "",
    longitude: "",
    address: "",
    city: "",
    district: "",
    location: {
      coordinates: [0, 0],
    },
    state: "",
    country: "",
    pincode: "",
    specialitem: "",
    average: "",
    eaterytype: "",
    foodtype: "",
    ambience: "",
    aboutrestaurant: "",
    whyyeldam: "",
    tags: []
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    // getValues,
    reset,
  } = useForm({ defaultValues });





  const About = (e: any) => {
    const restabout = e.target.value;
    const about = restabout.split(".");
    setaboutrest(about);
    setResObj({ ...resObj, about: about });
  };

  const handleNext = () => {
    console.log("clicked next");
    if (thumbnailImages.length > 5) {
      show(
        "You can upload up to 5 thumbnail images only.",
        "error",
        "Limit Exceeded"
      );
      return; 
    }
    if (menuImages.length > 20) {
      show("You can upload up to 20 menu images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }
    if (restaurantImages.length > 25) {
      show("You can upload up to 25 restaurant images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }
    if (!validateFields()) {
      return;
    }
    setActiveIndex(prevIndex => (prevIndex + 1));
    console.log(resObj);
    console.log("bbb", activeIndex);
  };

  const handleBack = () => {
    setActiveIndex(prevIndex => (prevIndex - 1));
    reset(defaultValues);
  };
  const Services = (e: any) => {
    let _services = [...services];

    if (e.checked) _services.push(e.value);
    else _services.splice(_services.indexOf(e.value), 1);

    setServices(_services);
    setResObj({ ...resObj, serving: _services });
  };

  const MOD = (e: any) => {
    let _mod = [...mod];

    if (e.checked) _mod.push(e.value);
    else _mod.splice(_mod.indexOf(e.value), 1);

    setMod(_mod);
    setResObj({ ...resObj, mealoftheday: _mod });
  };

  const [preferences, setPreferences] = useState<string[]>([]);
  const handlePreferenceChange = (e: any) => {
    const selectedPreference = e.value;

    setPreferences([selectedPreference]);
    setResObj({
      ...resObj,
      preference: [selectedPreference]
    });
  };


  const [foodtypes, setFoodtypes] = useState<ServiceOption[]>([]);
  const handlefoodtypechange = (e: any) => {
    let _foodtypes = [...foodtypes];
    const selectedFoodType = foodtypelist.find(item => item.name === e.value);
    if (e.checked && !_foodtypes.some(type => type.name === e.value)) {
      if (selectedFoodType) {
        _foodtypes.push(selectedFoodType);
      }
    } else if (!e.checked) {
      _foodtypes = _foodtypes.filter(type => type.name !== e.value);
    }
    setFoodtypes(_foodtypes);
    setResObj({ ...resObj, foodtype: _foodtypes });
  };

  const [value, setValue] = useState<ServiceOption[]>([]);
  const handlevalueschange = (e: any) => {
    let _valuess = [...value];
    const selectedvalues = valueslist.find(item => item.name === e.value);
    if (e.checked && !_valuess.some(type => type.name === e.value)) {
      if (selectedvalues) {
        _valuess.push(selectedvalues)
      }
    } else if (!e.checked) {
      _valuess = _valuess.filter(type => type.name !== e.value);
    }
    setValue(_valuess);
    setResObj({ ...resObj, values: _valuess })
  };

  const [eatery, setEatery] = useState<ServiceOption[]>([]);

  const handleEaterytypeChange = (e: any) => {
    const selectedEateryType = eaterytypelist.find(item => item.name === e.value);

    if (selectedEateryType) {
      setEatery([selectedEateryType]);
      setResObj({
        ...resObj,
        eaterytype: [selectedEateryType]
      });
    }
  };


  const [diet, setDiet] = useState<ServiceOption[]>([]);
  const handlediettypeChange = (e: any) => {
    let _diettypes = [...diet];
    const slecteddiettypes = diettypelist.find(item => item.name === e.value);
    if (e.checked && !_diettypes.some(type => type.name === e.value)) {
      if (slecteddiettypes) {
        _diettypes.push(slecteddiettypes);
      }
    } else if (!e.checked) {
      _diettypes = _diettypes.filter(type => type.name !== e.value);
    }
    setDiet(_diettypes);
    setResObj({ ...resObj, diettype: _diettypes });
  };

  const handleserviceOptions = (e: any) => {
    let _serviceOptions = [...serviceopt];
    if (e.checked) {
      _serviceOptions.push(e.value);
    } else {
      _serviceOptions = _serviceOptions.filter(item => item !== e.value);
    }
    setServiceopt(_serviceOptions);
    setResObj({ ...resObj, serviceoptions: _serviceOptions });
  };





  const [features, setFeatures] = useState<string[]>([]);
  const Features = (e: any) => {
    let _features = [...features];

    if (e.checked) _features.push(e.value);
    else _features.splice(_features.indexOf(e.value), 1);

    setFeatures(_features);
    setResObj({ ...resObj, features: _features });
  };


  const getFormErrorMessage = (name: string) => {
    const errorObject = errors[name as keyof typeof defaultValues];

    return errorObject ? (
      <small className="p-error">{errorObject.message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const fetchAddress = async (lat: any, long: any) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBadx8pwSuzfCbHeQaRPa36kaOFhMj7KrQ`
      );
      const data = await response.json();
      console.log(data);

      let address = '';
      let district = '';
      let city = '';
      let state = '';
      let pincode = '';
      let countryName = '';
      let locality = '';

      data.results.forEach((result: any) => {
        result.address_components.forEach((component: any) => {
          if (component.types.includes('street_number') || component.types.includes('route') || component.types.includes('street_address')) {
            address += component.long_name + ', ';
          } else if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
          } else if (component.types.includes('administrative_area_level_3')) {
            district = component.long_name;
          } else if (component.types.includes('postal_code')) {
            pincode = component.long_name;
          } else if (component.types.includes('country')) {
            countryName = component.long_name;
          }

          else if (component.types.includes('sublocality_level_1') || component.types.includes('sublocality_level_2') || component.types.includes('neighborhood')) {
            locality = component.long_name;
          }
        });
      });


      locality = locality || address.slice(0, -2);

      setResObj({
        ...resObj,
        locality: locality,
        district: district,
        city: city,
        state: state,
        pincode: pincode,
        country: countryName,
      });

    } catch (error) {
      console.error("Error fetching city:", error);
    }
  };
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  const handleCheckboxChange = (day: string) => {
    setCheckedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };
  const onFromTimeChange = (e: any) => {
    setfromtimes(e.value);
  };

  const onToTimeChange = (e: any) => {
    settotimes(e.value);
  };


  const onAmbienceChange = (e: any) => {
    setAmbience(e.value);
    setResObj({ ...resObj, ambience: e.value });
  };



  const onCuisineChange = (e: any) => {
    setCuisine(e.value);
    setResObj({
      ...resObj,
      cuisine: e.value,
    });
  };



  const onTagchange = (e: any) => {
    setRestaurantTages(e.value);
    console.log(e.value)
    setResObj({ ...resObj, tags: e.value.name });
  };

  const splitaray = (val: string): string[] => {
    return val.split(',').map(item => item.trimStart()).filter(item => item.length > 0);
  };

  const SpecialItemss = (e: any) => {
    const value = e.target.value.trimStart();
    setspitems(value);
    setResObj({
      ...resObj,
      specialitems: splitaray(value)
    });
  };

  const MenuItemss = (items: any) => {
    console.log("menuitems", items);
    setmenutems(items);
    setResObj({ ...resObj, menuitems: items });
  };
  const ResetForm = () => {
    console.log("resetting the form");
    setIsadding(true);
    window.location.reload();
    setResObj(initialState);
  };
  const WhyinYeldam = (e: any) => {
    const why = e.target.value;
    const whyus = why.split(".");
    setwhyyeldam(whyus);
    setResObj({ ...resObj, whyinyeldam: whyus });
  };
  const Timings = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "01:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
    "11:59pm",
    "12:00am",
  ];
  const daysOfWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [checkedDays, setCheckedDays] = useState(() => {
    const initialCheckedState: Record<string, boolean> = {};
    daysOfWeek.forEach((day) => {
      initialCheckedState[day] = false;
    });
    return initialCheckedState;
  });
  //for timing
  const initialSelectedTimings = {};
  const [selectedTimings, setSelectedTimings] = useState<
    Record<string, string[]>
  >(initialSelectedTimings);
  const [totimes, settotimes] = useState();
  const [fromtimes, setfromtimes] = useState();

  const handleAddTiming = () => {
    if (fromtimes && totimes) {
      const selectedDays = Object.keys(checkedDays).filter(
        (day) => checkedDays[day]
      );

      setSelectedTimings((prevSelectedTimings) => {
        const updatedTimings = { ...prevSelectedTimings };

        selectedDays.forEach((dayKey) => {
          const existingTimings = updatedTimings[dayKey] || [];
          const newTiming = `${fromtimes} to ${totimes}`;
          updatedTimings[dayKey] = [...existingTimings, newTiming];
        });

        console.log("selectedTimings", updatedTimings);

        // Directly set the updatedTimings without wrapping it in an array
        //setResObj({ ...resObj, timings: updatedTimings });

        return updatedTimings;
      });
    }
  };
  const [hygienerating, sethygienerating] = useState<number | null>(null);
  const [healthrating, sethealthrating] = useState<number | null>(null);
  const [tasterating, settasterating] = useState<number | null>(null);
  const [effectivecostrating, seteffectivecostrating] = useState<number | null>(null);
  const [valuesrating, setvaluesrating] = useState<number | null>(null);
  const [hospitalityrating, sethospitalityrating] = useState<number | null>(null);
  const [boxNumberColors, setBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [hygieneboxNumberColors, sethygieneBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [tasteboxNumberColors, settasteBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [costboxNumberColors, setcostBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [valueboxNumberColors, setvalueBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [hospitalityboxNumberColors, sethospitalityBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );

  const handleHygieneRatingClick = (value: number) => {
    sethygienerating(value);
    setResObj(prevState => ({ ...prevState, hygiene: value }));
    console.log("hygiene", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    // Update background color
    const updatedColors = { ...hygieneboxNumberColors, [value - 1]: backgroundColor };
    sethygieneBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;

  };

  const handleHealthRatingClick = (value: number) => {
    sethealthrating(value);
    setResObj(prevState => ({ ...prevState, health: value }));
    console.log("health", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }

    // Update background color
    const updatedColors = { ...boxNumberColors, [value - 1]: backgroundColor };
    setBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleTasteRatingClick = (value: number) => {
    settasterating(value);
    setResObj(prevState => ({ ...prevState, taste: value }));
    console.log("taste", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...tasteboxNumberColors, [value - 1]: backgroundColor };
    settasteBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleCostEffectiveRatingClick = (value: number) => {
    seteffectivecostrating(value);
    setResObj(prevState => ({ ...prevState, costeffective: value }));
    console.log("cost", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...costboxNumberColors, [value - 1]: backgroundColor };
    setcostBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleValueRatingClick = (value: number) => {
    setvaluesrating(value);
    setResObj(prevState => ({ ...prevState, valuesrating: value }));
    console.log("values", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...valueboxNumberColors, [value - 1]: backgroundColor };
    setvalueBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleHospitalityRatingClick = (value: number) => {
    sethospitalityrating(value);
    setResObj(prevState => ({ ...prevState, hospitality: value }));
    console.log("hospitality", value);
    let backgroundColor = "";

    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...hospitalityboxNumberColors, [value - 1]: backgroundColor };
    sethospitalityBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const validateFields = (): boolean => {
    const show = (message: any, severity: any, summary: any) => {
      if (toast.current) {
        toast.current.show({
          severity: severity,
          summary: summary,
          detail: message,
        });
      }
    };


    if (activeIndex === 0) {
      if (
        !resObj.name || resObj.name.length === 0 ||
        !resObj.latitude || resObj.latitude.length === 0 ||
        !resObj.longitude || resObj.longitude.length === 0 ||
        !resObj.address1 || resObj.address1.length === 0 ||
        !resObj.locality || resObj.locality.length === 0 ||
        !resObj.city || resObj.city.length === 0 ||
        !resObj.district || resObj.district.length === 0 ||
        !resObj.state || resObj.state.length === 0 ||
        !resObj.country || resObj.country.length === 0 ||
        !resObj.pincode || resObj.pincode.length === 0
      ) {
        show("Please check all Mandatory fields in restaurant details", "warn", "Warn");
        return false;
      }
    }
    else if (activeIndex === 1) {
      if (

        !resObj.averagecost || resObj.averagecost.length === 0 ||
        !resObj.eaterytype || resObj.eaterytype.length === 0 ||
        !resObj.foodtype || resObj.foodtype.length === 0 ||
        !resObj.preference || resObj.preference.length === 0 ||
        !resObj.mealoftheday || resObj.mealoftheday.length === 0 ||
        !resObj.values || resObj.values.length === 0 ||
        !resObj.serviceoptions || resObj.serviceoptions.length === 0
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
    }
    else if (activeIndex === 3) {
      if (
        !resObj.thumbnails || resObj.thumbnails.length === 0 ||
        !resObj.images || resObj.images.length === 0
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
    }
    else if (activeIndex === 5) {
      if (
        (!resObj.about || resObj.about.length === 0 || resObj.about[0].trim() === '') ||
        (!resObj.whyinyeldam || resObj.whyinyeldam.length === 0 || resObj.whyinyeldam[0].trim() === '')
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
    }

    return true;
  };

  const handleEditRestaurant = async () => {
    setIsadding(true);

    if (!resObj.name || resObj.name.length === 0 ||
      !resObj.latitude || resObj.latitude.length === 0 ||
      !resObj.longitude || resObj.longitude.length === 0 ||
      !resObj.address1 || resObj.address1.length === 0 ||
      !resObj.locality || resObj.locality.length === 0 ||
      !resObj.city || resObj.city.length === 0 ||
      !resObj.district || resObj.district.length === 0 ||
      !resObj.state || resObj.state.length === 0 ||
      !resObj.country || resObj.country.length === 0 ||
      !resObj.pincode || resObj.pincode.length === 0 ||
      !resObj.averagecost || resObj.averagecost.length === 0 ||
      !resObj.eaterytype || resObj.eaterytype.length === 0 ||
      !resObj.foodtype || resObj.foodtype.length === 0 ||
      !resObj.preference || resObj.preference.length === 0 ||
      !resObj.mealoftheday || resObj.mealoftheday.length === 0 ||
      !resObj.values || resObj.values.length === 0 ||
      !resObj.serviceoptions || resObj.serviceoptions.length === 0 ||
      !resObj.thumbnails || resObj.thumbnails.length === 0 ||
      !resObj.images || resObj.images.length === 0 ||
      (!resObj.about || resObj.about.length === 0 || resObj.about[0].trim() === '') ||
      (!resObj.whyinyeldam || resObj.whyinyeldam.length === 0 || resObj.whyinyeldam[0].trim() === '')
    ) {
      show("Please check all Mandatory fields", "warn", "Warn");
      setIsadding(false)
      return;
    }
    if (resObj) {

      const updatedResObj = {
        ...resObj,
        location: {
          coordinates: [
            parseFloat(resObj.longitude) || 0,
            parseFloat(resObj.latitude) || 0,
          ],
        },
      };

      const formData = new FormData();

      // Basic details
      formData.append("name", updatedResObj.name);
      formData.append("phoneno", updatedResObj.phoneno);
      formData.append("email", updatedResObj.email);
      formData.append("website", updatedResObj.website);
      formData.append("facebookurl", updatedResObj.facebookurl);
      formData.append("instaurl", updatedResObj.instaurl);
      formData.append("youtubeurl", updatedResObj.youtubeurl)
      formData.append("twitterurl", updatedResObj.twitterurl);
      formData.append("address1", updatedResObj.address1);
      formData.append("locality", updatedResObj.locality);
      formData.append("latitude", updatedResObj.latitude);
      formData.append("longitude", updatedResObj.longitude);
      formData.append("city", updatedResObj.city);
      formData.append("district", updatedResObj.district);
      formData.append("state", updatedResObj.state);
      formData.append("country", updatedResObj.country);
      formData.append("pincode", updatedResObj.pincode);
      formData.append("averagecost", updatedResObj.averagecost);
      formData.append("ambience", updatedResObj.ambience);

      // Add preference
      updatedResObj.preference.forEach((pref: string, index: number) => {
        formData.append(`preference[${index}]`, pref);
      });

      // Add serving
      updatedResObj.serving.forEach((serve: string, index: number) => {
        formData.append(`serving[${index}]`, serve);
      });

      // Add meal of the day
      updatedResObj.mealoftheday.forEach((meal: string, index: number) => {
        formData.append(`mealoftheday[${index}]`, meal);
      });

      // Special items 
      updatedResObj.specialitems.forEach((special: any, index: number) => {
        formData.append(`specialitems[${index}]`, special);
      });

      // Menu Items
      updatedResObj.menuitems.forEach((menu: any, index: number) => {
        formData.append(`menuitems[${index}]`, menu);
      })

      // Eatery types (handling object structure)
      updatedResObj.eaterytype.forEach((eateryType: any, index: number) => {
        formData.append(`eaterytype[${index}][name]`, eateryType.name);
        formData.append(`eaterytype[${index}][code]`, eateryType.code);
      });

      // Food type 
      updatedResObj.foodtype.forEach((foodtype: any, index: number) => {
        formData.append(`foodtype[${index}][name]`, foodtype.name);
        formData.append(`foodtype[${index}][code]`, foodtype.code);
      });

      // Diet type
      updatedResObj.diettype.forEach((diettype: any, index: number) => {
        formData.append(`diettype[${index}][name]`, diettype.name);
        formData.append(`diettype[${index}][code]`, diettype.code);
      });

      // values
      updatedResObj.values.forEach((value: any, index: number) => {
        formData.append(`values[${index}][name]`, value.name);
        formData.append(`values[${index}][code]`, value.code);
      });

      // Cuisine types
      updatedResObj.cuisine.forEach((cuisine: any, index: number) => {
        formData.append(`cuisine[${index}][name]`, cuisine.name);
        formData.append(`cuisine[${index}][code]`, cuisine.code);
      });

      // Service options
      updatedResObj.serviceoptions.forEach((serviceoption: string, index: number) => {
        formData.append(`serviceoptions[${index}]`, serviceoption);
      });

      // Timings - Only append if they've changed
      if (JSON.stringify(selectedTimings) !== JSON.stringify(initialSelectedTimings)) {
        formData.append("timings", JSON.stringify(selectedTimings));
      }

          // Thumbnails (files or URLs)
          updatedResObj.thumbnails.forEach((thumbnail: File | string) => {
            if (typeof thumbnail === "string") {
                formData.append("existingThumbnailURLs", thumbnail);
            } else {
                formData.append("thumbnails", thumbnail);
            }
        });

        // Images (files or URLs)
        updatedResObj.images.forEach((image: File | string) => {
            if (typeof image === "string") {
                formData.append("existingImageURLs", image);
            } else {
                formData.append("images", image);
            }
        });

        // Menu Images (files or URLs)
        updatedResObj.menuimages.forEach((menuimage: File | string) => {
            if (typeof menuimage === "string") {
                formData.append("existingMenuImageURLs", menuimage);
            } else {
                formData.append("menuimages", menuimage);
            }
        });

      // Fecilities & features
      updatedResObj.features.forEach((features: any, index: number) => {
        formData.append(`features`, features);
      })

      // About 
      updatedResObj.about.forEach((about: any, index: number) => {
        formData.append(`about`, about);
      });

      // why in yeldam
      updatedResObj.whyinyeldam.forEach((why: any, index: number) => {
        formData.append(`whyinyeldam`, why);
      });

      console.log('about', resObj.about, resObj.whyinyeldam);

      try {
        const response = await UpadteRestaurant(updatedResObj._id, formData);

        const currentPath = window.location.pathname;
        const productId = updatedResObj._id;
        if (response.data && response.data.message === "Restaurant succesfully updated.") {
          console.log("I am hitting the 200 status code")
          show("Restaurant Updated Successfully", "success", "Success");
          let targetUrl = '';
          setTimeout(async () => {
            if (currentPath.includes("/pending")) {
              targetUrl = `/restaurants/pending/details/${productId}`;
            } else if (currentPath.includes("/approved")) {
              targetUrl = `/restaurants/approved/details/${productId}`;
            }
            else if (currentPath.includes("/rejected")) {
              targetUrl = `/restaurants/rejected/details/${productId}`;
            }

            if (targetUrl) {
              navigate(targetUrl);
              setIsadding(false);
            }
          }, 1000);
          // setIsadding(false);

        }
        else {
          setIsadding(false);
          show("Invalid Access in ", "error", "Error");
        }

      } catch (error: any) {
        console.error("Error updating restaurant:", error);
        setIsadding(false);
        show("Error updating restaurant: " + error.message, "error", "Error");
      }
    }
  };


  useEffect(() => {
    setIsadding(true);
    loadLazyData();
  }, [id]);
  useEffect(() => {
    get_categories();
  }, []);

  console.log("updating the resobj in admin", resObj);

  const handleEditClick = () => {

    const fromPath = location.state?.from;

    console.log("Current Path:", window.location.pathname);
    console.log("From Path:", fromPath);

    if (fromPath) {

      navigate(fromPath);
    } else {

      const currentPath = window.location.pathname;

      if (currentPath.includes("/pending")) {
        navigate(`/restaurants/pending/details/${id}`);
      } else if (currentPath.includes("/approved")) {
        navigate(`/restaurants/approved/details/${id}`);
      } else if (currentPath.includes("/rejected")) {
        navigate(`/restaurants/rejected/details/${id}`);
      } else {

        navigate('/');
      }
    }
  };
  return (
    <>
      {!isAdding ? "" : <Loader />}

      <main>
        <div>
          <Navbar />
          <section
            className="page-content pr-0 pb-0"
          >
            <div className='pl-5'>
              <p className='m-0 edit-info-heading'
                onClick={handleEditClick}
              >
                <i className="pi pi-arrow-left mr-2"></i>
                Edit Restaurant</p>
            </div>
            <hr style={{ color: "#ececec", opacity: "100%" }} />
            <Toast ref={toast}></Toast>
            <div className='edit-background mx-1'>
              <TabView
                className='editing-business'
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
              >
                <TabPanel header={"Restaurant Details"}>

                  <>
                    <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", backgroundColor: "#FAFAFA" }}>
                      <div className='col-12 p-3 d-flex flex-column gap-3'>
                        <div className='d-flex'>
                          <div className="col-8 py-0 flex flex-column email-entry">
                            <Controller
                              name="restaurantname"
                              control={control}
                              rules={{
                                required: "Restaurant name  is required.",
                              }}
                              render={({ field, fieldState }) => (
                                <>
                                  <label className="edit-details-head mb-1">
                                    Restaurant Name{" "}
                                    <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputText
                                      placeholder="Enter Restaurant Name"
                                      maxLength={50}
                                      id={field.name}
                                      value={resObj.name}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        value = value.trimStart();
                                        if (value.length > 0) {
                                          field.onChange(e);
                                          setResObj({
                                            ...resObj,
                                            name: value,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            name: '',
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                          <div className="col-4 py-0 flex flex-column email-entry">
                            <label className="edit-details-head mb-1">Phone Number </label>
                            <InputText
                              id="phoneno"
                              className="no-spinner"
                              maxLength={14}
                              value={resObj.phoneno}
                              onChange={(e) => {
                                const rawValue = e.target.value;
                                const trimmedValue = rawValue.trimStart();
                                if (trimmedValue) {
                                  setResObj({
                                    ...resObj,
                                    phoneno: trimmedValue,
                                  });
                                } else {
                                  setResObj({
                                    ...resObj,
                                    phoneno: '',
                                  });
                                }
                              }}
                              placeholder="Enter Phone Number"
                              aria-describedby="username-help"

                              keyfilter={/^[0-9+\-\s]*$/}
                            />
                          </div>
                        </div>
                        <div className='d-flex'>
                          <div className="col-4 py-0 flex flex-column email-entry">
                            <label className="edit-details-head mb-1">Email</label>
                            <InputText
                              id="email"
                              maxLength={50}
                              value={resObj.email}
                              placeholder="Enter Email Address"
                              aria-describedby="username-help"
                              onChange={(e) => {
                                const rawValue = e.target.value;
                                const trimmedValue = rawValue.trimStart();
                                if (trimmedValue) {
                                  setResObj({
                                    ...resObj,
                                    email: trimmedValue,
                                  });
                                } else {
                                  setResObj({
                                    ...resObj,
                                    email: '',
                                  });
                                }
                              }}
                            />
                          </div>


                          <div className="col-4 py-0 flex flex-column email-entry">
                            <label className="edit-details-head mb-1">Website</label>
                            <InputText
                              id="website"
                              maxLength={50}
                              value={resObj.website}
                              placeholder="Enter Website URL"
                              aria-describedby="username-help"
                              onChange={(e) => {
                                const rawValue = e.target.value;
                                const trimmedValue = rawValue.trimStart();
                                if (trimmedValue) {
                                  setResObj({
                                    ...resObj,
                                    website: trimmedValue,
                                  });
                                } else {
                                  setResObj({
                                    ...resObj,
                                    website: '',
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className="col-4 py-0 flex flex-column email-entry">
                            <label className="edit-details-head mb-1">Instagram</label>
                            <InputText
                              placeholder="Enter Instagram URL"
                              value={resObj.instaurl}
                              className="col-12"
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                setResObj({
                                  ...resObj,
                                  instaurl: value.length > 0 ? value : '',
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className='d-flex'>
                          <div className="col-4 py-0 flex flex-column email-entry">
                            <label className="edit-details-head mb-1">Facebook</label>
                            <InputText
                              placeholder="Enter Facebook URL"
                              value={resObj.facebookurl}
                              className="col-12"
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                setResObj({
                                  ...resObj,
                                  facebookurl: value.length > 0 ? value : '',
                                });
                              }}
                            />
                          </div>


                          <div className="col-4 py-0 flex flex-column email-entry">
                            <label className="edit-details-head mb-1">X</label>
                            <InputText
                              placeholder="Enter X URL"
                              className="col-12"
                              value={resObj.twitterurl}
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                setResObj({
                                  ...resObj,
                                  twitterurl: value.length > 0 ? value : '',
                                });
                              }}
                            />
                          </div>
                          <div className="col-4 py-0 flex flex-column email-entry">
                            <label className="edit-details-head mb-1">Youtube</label>
                            <InputText
                              placeholder="Enter Youtube URL"
                              className="col-12"
                              value={resObj.youtubeurl}
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                setResObj({
                                  ...resObj,
                                  youtubeurl: value.length > 0 ? value : '',
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-12 py-0 head-contact'>Location</div>
                        <div className='d-flex align-items-center'>
                          <div className="col-4 py-0 flex flex-column email-entry">
                            <Controller
                              name="latitude"
                              control={control}
                              rules={{
                                required: "Latitude is required.",
                                pattern: {
                                  value: /^[+-]?([0-9]*[.]?[0-9]+)$/,
                                  message: "Enter a valid latitude with only numbers and dots.",
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <>
                                  <div className="d-flex align-items-baseline">
                                    <label className="edit-details-head mb-1">
                                      Latitude
                                      <b style={{ color: "red" }}>*</b>
                                    </label>
                                  </div>
                                  <div className="flex flex-column">
                                    <InputText
                                      maxLength={30}
                                      placeholder="Enter a valid latitude"
                                      id={field.name}
                                      value={resObj.latitude}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      onChange={(e) => {
                                        const value = e.target.value.trim();
                                        const latitude = value === "" ? null : parseFloat(value);
                                        field.onChange(e);
                                        setResObj({
                                          ...resObj,
                                          latitude: value,
                                          location: {
                                            ...resObj.location,
                                            coordinates: [
                                              resObj.location.coordinates[0],
                                              latitude !== null ? latitude : 0,
                                            ],
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                          <div className="col-4 py-0 flex flex-column email-entry">
                            <Controller
                              name="longitude"
                              control={control}
                              rules={{
                                required: "Longitude is required.",
                                pattern: {
                                  value: /^[+-]?([0-9]*[.]?[0-9]+)$/,
                                  message: "Enter a valid longitude with only numbers and dots.",
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <>
                                  <div className="d-flex align-items-baseline">
                                    <label className="edit-details-head mb-1">
                                      Longitude
                                      <b style={{ color: "red" }}>*</b>
                                    </label>
                                  </div>
                                  <div className="flex flex-column">
                                    <InputText
                                      maxLength={30}
                                      placeholder="Enter a valid longitude"
                                      id={field.name}
                                      value={resObj.longitude}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      onChange={(e) => {
                                        const value = e.target.value.trim();
                                        const longitude = value === "" ? null : parseFloat(value);
                                        field.onChange(e);
                                        setResObj({
                                          ...resObj,
                                          longitude: value,
                                          location: {
                                            ...resObj.location,
                                            coordinates: [
                                              longitude !== null ? longitude : 0,
                                              resObj.location.coordinates[1],
                                            ],
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                          <div className='col-1'>
                            <Button
                              label='Get Address'
                              className='getData_btn'
                              onClick={() => fetchAddress(resObj.latitude, resObj.longitude)}
                            />
                          </div>
                        </div>

                        <div className="col-12 py-0 flex flex-column email-entry">
                          <Controller
                            name="address"
                            control={control}
                            rules={{ required: "Address  is required." }}
                            render={({ field, fieldState }) => (
                              <>
                                <label className="edit-details-head mb-1">
                                  Address <b style={{ color: "red" }}>*</b>
                                </label>
                                <div className="flex flex-column">
                                  <InputTextarea
                                    maxLength={130}
                                    id={field.name}
                                    {...field}
                                    placeholder="Enter Address Line"
                                    value={resObj.address1}
                                    onChange={(e) => {
                                      const rawValue = e.target.value;
                                      const trimmedValue = rawValue.trimStart();
                                      if (trimmedValue) {
                                        field.onChange(e);
                                        setResObj({
                                          ...resObj,
                                          address1: trimmedValue,
                                        });
                                      } else {
                                        setResObj({
                                          ...resObj,
                                          address1: '',
                                        });
                                      }
                                    }}
                                    rows={5}
                                    cols={30}
                                    className={classNames({
                                      "p-invalid": fieldState.error,
                                    })}
                                  />
                                </div>
                                {getFormErrorMessage(field.name)}
                              </>
                            )}
                          />
                        </div>


                        <div className='d-flex'>
                          <div className="col-4 py-0 flex flex-column email-entry">
                            <label className="edit-details-head mb-1">Locality <b style={{ color: 'red' }}>*</b></label>
                            <InputText
                              placeholder="Enter Locality"
                              value={resObj.locality}
                              className="col-12"
                              onChange={(e) => {
                                const rawValue = e.target.value;
                                const trimmedValue = rawValue.trimStart();
                                if (trimmedValue) {
                                  setResObj({
                                    ...resObj,
                                    locality: trimmedValue,
                                  });
                                } else {
                                  setResObj({
                                    ...resObj,
                                    locality: '',
                                  });
                                }
                              }}
                            />
                          </div>


                          <div className="col-4 py-0 flex flex-column email-entry">
                            <Controller
                              name="city"
                              control={control}
                              rules={{ required: "city is required." }}
                              render={({ field, fieldState }) => (
                                <>
                                  <label className="edit-details-head mb-1">
                                    City<b style={{ color: "red" }}>*</b>
                                  </label>

                                  <div className="flex flex-column">
                                    <InputText
                                      maxLength={30}
                                      id={field.name}
                                      value={resObj.city}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      placeholder="Enter City"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart();
                                        if (trimmedValue) {
                                          setResObj({
                                            ...resObj,
                                            city: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            city: '',
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                          <div className="col-4 py-0 flex flex-column email-entry">
                            <Controller
                              name="pincode"
                              control={control}
                              rules={{ required: "pincode  is required." }}
                              render={({ field, fieldState }) => (
                                <>
                                  <label className="edit-details-head mb-1">
                                    Pincode<b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputText
                                      maxLength={6}
                                      placeholder="Enter Pincode"
                                      id={field.name}
                                      value={resObj.pincode}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart();
                                        if (/^\d{0,6}$/.test(trimmedValue)) {
                                          setResObj({
                                            ...resObj,
                                            pincode: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            pincode: '',
                                          });
                                        }
                                      }}
                                      keyfilter={/^[0-9]*$/}

                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <div className='d-flex'>
                          <div className="col-4 py-0 flex flex-column email-entry">
                            <Controller
                              name="district"
                              control={control}
                              rules={{ required: "district is required." }}
                              render={({ field, fieldState }) => (
                                <>
                                  <label className="edit-details-head mb-1">
                                    District
                                    <b style={{ color: "red" }}>*</b>
                                  </label>

                                  <div className="flex flex-column">
                                    <InputText
                                      maxLength={30}
                                      placeholder="Enter District"
                                      id={field.name}
                                      value={resObj.district}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart();
                                        if (trimmedValue) {
                                          setResObj({
                                            ...resObj,
                                            district: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            district: '',
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>


                          <div className="col-4 py-0 flex flex-column email-entry">
                            <Controller
                              name="state"
                              control={control}
                              rules={{ required: "state is required." }}
                              render={({ field, fieldState }) => (
                                <>
                                  <label className="edit-details-head mb-1">
                                    State<b style={{ color: "red" }}>*</b>
                                  </label>

                                  <div className="flex flex-column">
                                    <InputText
                                      maxLength={30}
                                      id={field.name}
                                      value={resObj.state}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      placeholder="Enter State"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart();
                                        if (trimmedValue) {
                                          setResObj({
                                            ...resObj,
                                            state: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            state: '',
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                          <div className="col-4 py-0 flex flex-column email-entry">
                            <Controller
                              name="country"
                              control={control}
                              rules={{ required: "country is required." }}
                              render={({ field, fieldState }) => (
                                <>
                                  <label className="edit-details-head mb-1">
                                    Country<b style={{ color: "red" }}>*</b>
                                  </label>

                                  <div className="flex flex-column">
                                    <InputText
                                      maxLength={30}
                                      id={field.name}
                                      value={resObj.country}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      placeholder="Entry Country"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart();
                                        if (trimmedValue) {
                                          setResObj({
                                            ...resObj,
                                            country: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            country: '',
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                        </div>

                      </div>
                      <div className="d-flex justify-content-end pr-5 pt-2">
                        <div className="row">
                          <Button className="next" type="submit" onClick={handleNext}>
                            Next <i className="pi pi-angle-right ml-1"></i>
                          </Button>
                        </div>
                      </div>
                    </ScrollPanel>
                  </>

                </TabPanel>
                <TabPanel header={"Food Preference"}>
                  <>
                    <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", backgroundColor: "#FAFAFA" }}>
                      <div className='col-12 p-3 d-flex flex-column gap-5'>
                        {/* preferences */}
                        <div className="row">
                          <label className="edit-details-head mb-2">
                            What food preference do you cater to? <b style={{ color: "red" }}>*</b>
                          </label>
                          <div className="d-flex mt-1 gap-4">
                            <div className="">
                              <div className="flex align-items-center gap-2">
                                <RadioButton
                                  inputId="preference3"
                                  name="preferences"
                                  value="Pure-Veg"
                                  onChange={handlePreferenceChange}
                                  checked={(resObj.preference as string[]).includes("Pure-Veg")}
                                />
                                <label
                                  htmlFor="preference3"
                                  className={
                                    (resObj.preference as string[]).includes("Pure-Veg")
                                      ? "pref-label-selected"
                                      : "pref-label"
                                  }
                                >
                                  Pure-Veg
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center gap-2">
                                <RadioButton
                                  inputId="preference1"
                                  name="preferences"
                                  value="Veg & Non-Veg"
                                  onChange={handlePreferenceChange}
                                  checked={(resObj.preference as string[]).includes("Veg & Non-Veg")}
                                />
                                <label
                                  htmlFor="preference1"
                                  className={
                                    (resObj.preference as string[]).includes("Veg & Non-Veg")
                                      ? "pref-label-selected"
                                      : "pref-label"
                                  }
                                >
                                  Veg & Non-Veg
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center gap-2">
                                <RadioButton
                                  inputId="preference2"
                                  name="preferences"
                                  value="Non-Veg"
                                  onChange={handlePreferenceChange}
                                  checked={(resObj.preference as string[]).includes("Non-Veg")}
                                />
                                <label
                                  htmlFor="preference2"
                                  className={
                                    (resObj.preference as string[]).includes("Non-Veg")
                                      ? "pref-label-selected"
                                      : "pref-label"
                                  }
                                >
                                  Non-Veg
                                </label>
                              </div>
                            </div>
                          </div>

                        </div>

                        {/* food type */}
                        <div className="row">
                          <label className="edit-details-head mb-2">
                            Can you describe the overall food concept of your restaurant (Food Type)? <b style={{ color: "red" }}>*</b>
                          </label>
                          <div className="d-flex mt-1 gap-4">
                            {foodtypelist && foodtypelist.map((item, index) => (
                              <div key={index} className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId={`foodtype${index}`}
                                    name="foodtypes"
                                    value={item.name}
                                    onChange={handlefoodtypechange}
                                    checked={resObj.foodtype && resObj.foodtype.some((type: ServiceOption) => type.name === item.name)}
                                  />
                                  <label
                                    htmlFor={`foodtype${index}`}
                                    className={resObj.foodtype && resObj.foodtype.some((type: ServiceOption) => type.name === item.name)
                                      ? "pref-label-selected"
                                      : "pref-label"
                                    }
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>


                        {/* values */}
                        <div className="row">
                          <label className="edit-details-head mb-2">
                            What sets this business apart from others (Values)? <b style={{ color: "red" }}>*</b>
                          </label>
                          <div className="d-flex mt-1 gap-4">
                            {valueslist && valueslist.map((item, index) => (
                              <div key={index} className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId={`values${index}`}
                                    name="Values"
                                    value={item.name}
                                    onChange={handlevalueschange}
                                    checked={resObj.values && resObj.values.some((type: ServiceOption) => type.name === item.name)}
                                  />
                                  <label
                                    htmlFor={`values${index}`}
                                    className={resObj.values && resObj.values.some((type: ServiceOption) => type.name === item.name)
                                      ? "pref-label-selected"
                                      : "pref-label"
                                    }
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>


                        {/* eatery type */}
                        <div className="row">
                          <label className="edit-details-head mb-2">
                            Choose the category that best describes your establishment (Eatery Type). <b style={{ color: "red" }}>*</b>
                          </label>
                          <div className="d-flex flex-wrap mt-1 gap-4">
                            {eaterytypelist && eaterytypelist.map((item, index) => (
                              <div key={index} className="">
                                <div className="flex align-items-center gap-2">
                                  <RadioButton
                                    inputId={`eaterytype${index}`}
                                    name="eaterytypes"
                                    value={item.name}
                                    onChange={handleEaterytypeChange}

                                    checked={resObj.eaterytype && resObj.eaterytype.some((type: ServiceOption) => type.name === item.name)}
                                  />
                                  <label
                                    htmlFor={`eaterytype${index}`}
                                    className={resObj.eaterytype && resObj.eaterytype.some((type: ServiceOption) => type.name === item.name)
                                      ? "pref-label-selected"
                                      : "pref-label"
                                    }
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>



                        {/* diettype */}
                        <div className="row">
                          <label className="edit-details-head mb-2">
                            What are the diet options you provide (Diet Type)?
                          </label>
                          <div className="d-flex flex-wrap mt-1 gap-4">
                            {diettypelist && diettypelist.map((item, index) => (
                              <div key={index} className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId={`diettype${index}`}
                                    name="diettypes"
                                    value={item.name}
                                    onChange={handlediettypeChange}
                                    // Check if value is already present in resObj.diettype
                                    checked={resObj.diettype && resObj.diettype.some((type: ServiceOption) => type.name === item.name)}
                                  />
                                  <label
                                    htmlFor={`diettype${index}`}
                                    className={resObj.diettype && resObj.diettype.some((type: ServiceOption) => type.name === item.name)
                                      ? "pref-label-selected"
                                      : "pref-label"
                                    }
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>


                        {/* meal of the day */}
                        <div className="row">
                          <label className="ym-input-label mt-2">
                            Which meals of the day do you serve (Dining Options)? <b style={{ color: "red" }}>*</b> {" "}
                          </label>
                          <div className="d-flex flex-wrap mt-1 gap-4">
                            {mealOptions.map((meal, index) => (
                              <div key={index}>
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId={`mod${index + 1}`}
                                    name="mods"
                                    value={meal}
                                    onChange={MOD}
                                    checked={(resObj.mealoftheday as string[]).includes(meal)}
                                  />
                                  <label
                                    htmlFor={`mod${index + 1}`}
                                    className={
                                      (resObj.mealoftheday as string[]).includes(meal)
                                        ? "pref-label-selected"
                                        : "pref-label"
                                    }
                                  >
                                    {meal}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* service options */}
                        <div className="row">
                          <label className="edit-details-head mb-2">
                            What type of service do you offer (Service Options)? <b style={{ color: "red" }}>*</b>
                          </label>
                          <div className="d-flex mt-1 gap-4">
                            {serviceoptionslist && serviceoptionslist.map((item, index) => (
                              <div key={index} className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId={`serviceoption${index}`}
                                    name="serviceoptions"
                                    value={item.name}
                                    onChange={handleserviceOptions}
                                    checked={resObj.serviceoptions && resObj.serviceoptions.some((option: any) => option === item.name)}
                                  />
                                  <label
                                    htmlFor={`serviceoption${index}`}
                                    className={resObj.serviceoptions && resObj.serviceoptions.includes(item.name) ? "pref-label-selected" : "pref-label"}
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* serving */}
                        <div className="row">
                          <label className="edit-details-head mb-2">
                            How is the food served at your restaurant (Serving)?
                          </label>
                          <div className="d-flex mt-1 gap-4">
                            <div className="">
                              <div className="flex align-items-center gap-2">
                                <Checkbox
                                  inputId="service1"
                                  name="services"
                                  value="Ala Carte"
                                  onChange={Services}
                                  checked={(resObj.serving as string[]).includes("Ala Carte")}
                                />
                                <label htmlFor="service1" className="pref-label">
                                  Ala Carte
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center gap-2">
                                <Checkbox
                                  inputId="service2"
                                  name="services"
                                  value="Buffet"
                                  onChange={Services}
                                  checked={(resObj.serving as string[]).includes("Buffet")}
                                />
                                <label htmlFor="service2" className="pref-label">
                                  Buffet
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center gap-2">
                                <Checkbox
                                  inputId="service3"
                                  name="services"
                                  value="Meals"
                                  onChange={Services}
                                  checked={(resObj.serving as string[]).includes("Meals")}
                                />
                                <label htmlFor="service3" className="pref-label">
                                  Meals
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center gap-2" >
                                <Checkbox
                                  inputId="service5"
                                  name="services"
                                  value="Thali"
                                  onChange={Services}
                                  checked={(resObj.serving as string[]).includes("Thali")}
                                />
                                <label htmlFor="service5" className="pref-label">
                                  Thali
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center gap-2" >
                                <Checkbox
                                  inputId="service6"
                                  name="services"
                                  value="Salads"
                                  onChange={Services}
                                  checked={(resObj.serving as string[]).includes("Salads")}
                                />
                                <label htmlFor="service6" className="pref-label">
                                  Salads
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center gap-2" >
                                <Checkbox
                                  inputId="service7"
                                  name="services"
                                  value="Juices"
                                  onChange={Services}
                                  checked={(resObj.serving as string[]).includes("Juices")}
                                />
                                <label htmlFor="service7" className="pref-label">
                                  Juices
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center gap-2" >
                                <Checkbox
                                  inputId="service8"
                                  name="services"
                                  value="Sandwiches"
                                  onChange={Services}
                                  checked={(resObj.serving as string[]).includes("Sandwiches")}
                                />
                                <label htmlFor="service8" className="pref-label">
                                  Sandwiches
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center gap-2" >
                                <Checkbox
                                  inputId="service9"
                                  name="services"
                                  value="Desserts"
                                  onChange={Services}
                                  checked={(resObj.serving as string[]).includes("Desserts")}
                                />
                                <label htmlFor="service9" className="pref-label">
                                  Desserts
                                </label>
                              </div>
                            </div>

                          </div>
                        </div>

                        {/* avg cost, ambience, cuisine */}
                        <div className="row">
                          <div className="col-4 pb-0">
                            <div className="">
                              <Controller
                                name="average"
                                control={control}
                                rules={{
                                  required: "average cost is required.",
                                }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <div className="d-flex align-items-baseline">
                                      <label className="edit-details-head mb-2 ">
                                        Average cost (per 2 person)
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <i
                                        className="bi bi-info-circle ml-1"
                                        title="Please enter the average cost per 2 persons that can be spent in that restaurant"
                                        id="icon"
                                      ></i>
                                    </div>
                                    <div className="flex flex-column">
                                      <InputText
                                        maxLength={6}
                                        id={field.name}
                                        value={resObj.averagecost}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                        style={{ height: "44px", display: "flex", alignItems: "center" }}
                                        placeholder="Enter Amount"
                                        onChange={(e) => {
                                          field.onChange(e);
                                          setResObj({
                                            ...resObj,
                                            averagecost: e.target.value,
                                          });
                                        }}
                                        keyfilter={/^[0-9]*$/}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-4 pb-0">
                            <div className="d-flex align-items-baseline gap-1">
                              <label className="edit-details-head mb-2">
                                Ambience
                              </label>
                              <i
                                className="bi bi-info-circle ml-1"
                                title="Please enter the average cost per 2 persons that can be spent in that restaurant"
                                id="icon"
                              ></i>
                            </div>
                            <div className="flex flex-column">
                              <Dropdown
                                placeholder="Select Ambience"
                                style={{ height: "44px", display: "flex", alignItems: "center" }}
                                options={ambiencelist}
                                value={resObj.ambience}
                                onChange={onAmbienceChange}
                              />
                            </div>
                          </div>
                          <div className="col-4 pb-0">
                            <div className="d-flex align-items-baseline gap-1">
                              <label className="edit-details-head mb-2">Cuisine</label>
                              <i
                                className="bi bi-info-circle ml-1"
                                title="Select the cuisine available in the restaurant"
                                id="icon"
                              ></i>
                            </div>
                            <div className="flex flex-column gap-2">
                              <MultiSelect
                                value={resObj.cuisine}
                                options={cuisinelist}
                                style={{ height: "44px", display: "flex", alignItems: "center" }}
                                onChange={(e: MultiSelectChangeEvent) => {
                                  console.log("{restObj.eaterytype", resObj.eaterytype);
                                  setResObj({ ...resObj, cuisine: e.value });
                                }}
                                optionLabel="name"
                                placeholder="Select Cuisines..."
                                display="chip"
                              />
                            </div>
                          </div>
                        </div>
                        {/* special items, menuitems */}
                        <div className="row">
                          <div className="col-6 pt-0">
                            <Controller
                              name="specialitem"
                              control={control}
                              rules={{ required: "Special items are required." }}
                              render={({ field, fieldState }) => (
                                <>
                                  <div className="d-flex align-items-baseline">
                                    <label className="ym-input-label">
                                      Special Items
                                    </label>
                                    <i
                                      className="bi bi-info-circle ml-1"
                                      title="Enter the special items"
                                      id="icon"
                                    ></i>
                                  </div>
                                  <div className="flex flex-column">
                                    <InputTextarea
                                      maxLength={250}
                                      placeholder="Enter special items separated by commas"
                                      value={spitems}
                                      rows={5}
                                      cols={30}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        SpecialItemss(e);
                                      }}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                          <div className="col-6 pt-0">
                            <Controller
                              name="specialitem"
                              control={control}
                              rules={{ required: "special items is required." }}
                              render={({ field, fieldState }) => (
                                <>
                                  <div className="d-flex align-items-baseline">
                                    <label className="ym-input-label">
                                      Menu Items
                                    </label>
                                    <i
                                      className="bi bi-info-circle ml-1"
                                      title="Enter the special items"
                                      id="icon"
                                    ></i>
                                  </div>
                                  <div className="flex flex-column">
                                    <InputTextarea
                                      placeholder="Enter Menu items"
                                      value={resObj.menuitems.join(", ")}
                                      rows={5}
                                      cols={30}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        const itemsString = e.target.value.trim();
                                        const regex = /"(.*?)"/g;
                                        const cleanedString = itemsString.match(regex) || [];
                                        const items = cleanedString.map((item) => item.replace(/"/g, ''));
                                        MenuItemss(items);

                                      }}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>

                        </div>

                      </div>
                      <div className="d-flex justify-content-between px-5 pt-2">
                        <Button className="ym-add-button-previous" onClick={handleBack} >
                          <i className="pi pi-angle-left mr-1"></i>  Back
                        </Button>
                        <Button className="next" type="submit" onClick={handleNext}>
                          Next <i className="pi pi-angle-right ml-1"></i>
                        </Button>
                      </div>
                    </ScrollPanel>

                  </>
                </TabPanel>
                <TabPanel header={"Timings"}>
                  <>
                    <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", height: "calc(100vh - 24vh)", backgroundColor: "#FAFAFA" }}>
                      <>
                        <div className='col-12 p-3 d-flex flex-column gap-5'>
                          <div className="row ">
                            <div className="col-12">
                              <div className="child-lef t" id="Timings">
                                <label className="ym-input-label mb-2">
                                  Timings
                                </label>

                                <div className="">
                                  <div className="row">
                                    <div className="d-flex gap-6 mb-5">
                                      {daysOfWeek.map((day: string) => (
                                        <div className="" key={day}>
                                          <div className="d-flex align-items-center">
                                            <Checkbox
                                              inputId={`day${day}`}
                                              name="day"
                                              value={day}
                                              checked={checkedDays[day]}
                                              onChange={() => handleCheckboxChange(day)}
                                            />
                                            <label htmlFor={`day${day}`} className="ml-2 pref-label">
                                              {day}
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div className=" d-flex from-to gap-2 align-items-center mb-3">
                                      <div className="from d-flex flex-column col-lg-3">
                                        <span className="restaurant-name">From</span>
                                        <Dropdown
                                          style={{
                                            width: "100%",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: "white",
                                          }}
                                          id="eater"
                                          placeholder="Select"
                                          value={fromtimes}
                                          options={Timings}
                                          onChange={onFromTimeChange}
                                        />
                                      </div>
                                      <div className="from d-flex flex-column col-3">
                                        <span className="restaurant-name">To</span>
                                        <Dropdown
                                          style={{
                                            width: "100%",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: "white",
                                          }}
                                          id="eater"
                                          placeholder="Select"
                                          value={totimes}
                                          options={Timings}
                                          onChange={onToTimeChange}
                                        />
                                      </div>
                                      <div className="mt-4">
                                        <button
                                          className="add-button"
                                          onClick={handleAddTiming}
                                        >
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center mt-3">
                                      <div className="">
                                        {Object.entries(selectedTimings).map(
                                          ([day, timing]) => (
                                            <div
                                              className="Sunday-timing col-12 gap-3 py-0 d-flex align-items-center mt-3"
                                              key={day}
                                            >
                                              <div className="day col-2 p-0 ">{day}</div>
                                              <div className="col-10 d-flex align-items-center p-0">
                                                <div className="col gap-1">
                                                  {timing.map((time, index) => (
                                                    <div className="col d-flex align-items-center p-0 mt-1 gap-1">
                                                      <span className="col-8 time" key={index}>
                                                        {time}
                                                      </span>

                                                      <i
                                                        className="col-4 bi bi-trash-fill"
                                                        style={{
                                                          color: "#818181",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          const updatedTimings = {
                                                            ...selectedTimings,
                                                          };
                                                          updatedTimings[day] =
                                                            updatedTimings[day].filter(
                                                              (t) => t !== time
                                                            );

                                                          // Check if there are no times left, delete the entire day
                                                          if (
                                                            updatedTimings[day].length === 0
                                                          ) {
                                                            delete updatedTimings[day];
                                                          }

                                                          setSelectedTimings(updatedTimings);
                                                        }}
                                                      ></i>
                                                      <br />
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    {/* <div className="row d-flex justify-content-start align-items-center mt-3">
                                                            <RestaurantTimings
                                                                timing={resObj.timings}
                                                                editable={true}
                                                            />
                                                        </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </ScrollPanel>
                    <div className="button-container">
                      <Button className="ym-add-button-previous" onClick={handleBack} >
                        <i className="pi pi-angle-left mr-1"></i>  Back
                      </Button>
                      <Button className="next" type="submit" onClick={handleNext}>
                        Next <i className="pi pi-angle-right ml-1"></i>
                      </Button>
                    </div>
                  </>
                </TabPanel>

                <TabPanel header={"Photos"}>
                  <>
                    <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", height: "calc(100vh - 24vh)", backgroundColor: "#FAFAFA" }}>
                      <>
                        <div className='col-12 p-3 d-flex flex-column gap-5'>
                          <div className="row m-0">
                            <div className="col-12">
                              <div className="child-left" id="uploadimages">
                                <div className="">
                                  <ScrollPanel style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                                    <div className="row m-0">
                                      <div className="col-12">
                                        <div className="child-left" id="uploadimages">
                                          <div className="d-flex flex-column gap-5">

                                            <div className="">
                                              <label className="edit-details-head mb-2">
                                                Thumbnail Images  <b style={{ color: "red" }}>*</b> <span style={{ fontSize: "12px" }}>(Add a min 1 or a max 5 Thumbnail Images)</span>
                                              </label>
                                              <div className="d-flex gap-4">
                                                <DropFileInput
                                                  uploadedFiles={thumbnailImages}
                                                  setUploadedFiles={setThumbnailImages}
                                                  resObj={resObj}
                                                  setResObj={setResObj}
                                                  purpose="ThumbnailImages"
                                                />

                                                {Array.isArray(resObj.thumbnails) &&
                                                  resObj.thumbnails.every(
                                                    (item: any) => typeof item === "string"
                                                  ) &&
                                                  resObj.thumbnails.map((item: any, index: any) => {
                                                    return (
                                                      <div
                                                        id="Imagecrd"
                                                        style={{ position: "relative" }}
                                                        key={index}
                                                      >
                                                        <img
                                                          className="uploadimage fefe"
                                                          key={item}
                                                          src={item + "?" + sas}
                                                          alt=""
                                                        />

                                                        <i
                                                          className="pi pi-trash icontrash"
                                                          onClick={() =>
                                                            handleDeleteClick(index, "ThumbnailImages")
                                                          }
                                                          style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            right: "0",
                                                            margin: "8px",
                                                          }}
                                                        ></i>
                                                      </div>
                                                    );
                                                  })}

                                              </div>
                                            </div>

                                            <div className="">
                                              <label className="edit-details-head mb-2">
                                                Restaurant Images <b style={{ color: "red" }}>*</b>
                                              </label>
                                              <div className="d-flex gap-4">
                                                <DropFileInput
                                                  uploadedFiles={restaurantImages}
                                                  setUploadedFiles={setRestantImages}
                                                  resObj={resObj}
                                                  setResObj={setResObj}
                                                  purpose="RestaurantImages"
                                                />

                                                <div className="grid mt-2 ml-1 gap-3">
                                                  {Array.isArray(resObj.images) &&
                                                    resObj.images.every(
                                                      (item: any) => typeof item === "string"
                                                    ) &&
                                                    resObj.images.map((item: any, index: any) => {
                                                      // const imageUrl = URL.createObjectURL(item);

                                                      return (
                                                        <div
                                                          id="Imagecrd"
                                                          style={{ position: "relative" }}
                                                          key={index}
                                                        >
                                                          <img
                                                            className="uploadimage"
                                                            key={item.id}
                                                            src={item + "?" + sas}
                                                            alt=""
                                                          />

                                                          <i
                                                            className="pi pi-trash icontrash"
                                                            onClick={() =>
                                                              handleDeleteClick(index, "RestaurantImages")
                                                            }
                                                            style={{
                                                              position: "absolute",
                                                              top: "0",
                                                              right: "0",
                                                              margin: "8px",
                                                            }}
                                                          ></i>
                                                        </div>
                                                      );
                                                    })}
                                                </div>
                                              </div>
                                            </div>


                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </ScrollPanel>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </ScrollPanel>
                    <div className="button-container">
                      <Button className="ym-add-button-previous" onClick={handleBack} >
                        <i className="pi pi-angle-left mr-1"></i>  Back
                      </Button>
                      <Button className="next" type="submit" onClick={handleNext}>
                        Next <i className="pi pi-angle-right ml-1"></i>
                      </Button>
                    </div>
                  </>
                </TabPanel>

                <TabPanel header='Menu'>
                  <>
                    <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", height: "calc(100vh - 24vh)", backgroundColor: "#FAFAFA" }}>
                      <>
                        <div className='col-12 p-3 d-flex flex-column gap-5'>
                          <div className="row m-0">
                            <div className="col-12">
                              <div className="child-left" id="uploadimages">
                                <div className="">
                                  <ScrollPanel style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                                    <div className="row m-0">
                                      <div className="col-12">
                                        <div className="child-left" id="uploadimages">
                                          <div className="d-flex flex-column gap-5">
                                            <div className="">
                                              <label className="edit-details-head mb-2">Menu Images</label>
                                              <div className="d-flex gap-4">
                                                <DropFileInput
                                                  uploadedFiles={menuImages}
                                                  setUploadedFiles={setMenuImages}
                                                  resObj={resObj}
                                                  setResObj={setResObj}
                                                  purpose="MenuImages"
                                                />
                                                <div className="grid mt-2 ml-1 gap-3">
                                                  {Array.isArray(resObj.menuimages) &&
                                                    resObj.menuimages.every(
                                                      (item: any) => typeof item === "string"
                                                    ) &&
                                                    resObj.menuimages.map((item: any, index: any) => {
                                                      // const imageUrl = URL.createObjectURL(item);

                                                      return (
                                                        <div
                                                          id="Imagecrd"
                                                          style={{ position: "relative" }}
                                                          key={index}
                                                        >
                                                          <img
                                                            className="uploadimage"
                                                            key={item.id}
                                                            src={item + "?" + sas}
                                                            alt=""
                                                          />

                                                          <i
                                                            className="pi pi-trash icontrash"
                                                            onClick={() =>
                                                              handleDeleteClick(index, "MenuImages")
                                                            }
                                                            style={{
                                                              position: "absolute",
                                                              top: "0",
                                                              right: "0",
                                                              margin: "8px",
                                                            }}
                                                          ></i>
                                                        </div>
                                                      );
                                                    })}
                                                </div>

                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </ScrollPanel>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </ScrollPanel>
                    <div className="button-container">
                      <Button className="ym-add-button-previous" onClick={handleBack} >
                        <i className="pi pi-angle-left mr-1"></i>  Back
                      </Button>
                      <Button className="next" type="submit" onClick={handleNext}>
                        Next <i className="pi pi-angle-right ml-1"></i>
                      </Button>
                    </div>
                  </>
                </TabPanel>

                <TabPanel header={" Additional Info"}>
                  <>
                    <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", height: "calc(100vh - 24vh)", backgroundColor: "#FAFAFA" }}>

                      <>
                        <div className='col-12 p-3 d-flex flex-column gap-4'>
                          <div>
                            <label className="edit-details-head mb-2">
                              Features and Facilities
                            </label>

                            <div className="d-flex flex-wrap gap-4">
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature1"
                                    name="features"
                                    value="Lift"
                                    onChange={Features}
                                    checked={(resObj.features as string[]).includes("Lift")}
                                  />
                                  <label htmlFor="feature1" className="pref-label">
                                    Lift
                                  </label>
                                </div>
                              </div>
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature2"
                                    name="features"
                                    value="Air Conditioned"
                                    onChange={Features}
                                    checked={(resObj.features as string[]).includes("Air Conditioned")}
                                  />
                                  <label htmlFor="feature2" className="pref-label">
                                    Air Conditioned
                                  </label>
                                </div>
                              </div>

                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature4"
                                    name="features"
                                    value="Parking"
                                    onChange={Features}
                                    checked={(resObj.features as string[]).includes("Parking")}
                                  />
                                  <label htmlFor="feature4" className="pref-label">
                                    Parking
                                  </label>
                                </div>
                              </div>
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature5"
                                    name="features"
                                    value="Wifi"
                                    onChange={Features}
                                    checked={(resObj.features as string[]).includes("Wifi")}
                                  />
                                  <label htmlFor="feature5" className="pref-label">
                                    Wifi
                                  </label>
                                </div>
                              </div>
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature6"
                                    name="features"
                                    value="Valet Parking"
                                    onChange={Features}
                                    checked={(resObj.features as string[]).includes("Valet Parking")}
                                  />
                                  <label htmlFor="feature6" className="pref-label">
                                    Valet Parking
                                  </label>
                                </div>
                              </div>
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature7"
                                    name="features"
                                    value="Card Accepted"
                                    onChange={Features}
                                    checked={(resObj.features as string[]).includes("Card Accepted")}
                                  />
                                  <label htmlFor="feature7" className="pref-label">
                                    Card Accepted
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3" >
                            <Controller
                              name="aboutrestaurant"
                              control={control}
                              rules={{
                                required: "About restaurant is required.",
                              }}
                              render={({ field, fieldState }) => (
                                <>
                                  <div className="d-flex align-items-baseline gap-1">
                                    <label className="edit-details-head mb-2">
                                      About Restaurant{" "}
                                      <b style={{ color: "red" }}>*</b>
                                    </label>
                                    <i
                                      className="bi bi-info-circle ml-1"
                                      title="Enter About Restaurant"
                                      id="icon"
                                    ></i>
                                    <i></i>
                                  </div>
                                  <div className="flex flex-column">
                                    <InputTextarea
                                      maxLength={1500}
                                      value={resObj.about.join("")}
                                      placeholder="Enter About Restaurant"
                                      onChange={(e) => {
                                        field.onChange(e);
                                        About(e);
                                      }}
                                      rows={5}
                                      cols={30}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                          {/* why yeldam */}
                          <div className="row">
                            <Controller
                              name="whyyeldam"
                              control={control}
                              rules={{ required: "Why yeldam  is required." }}
                              render={({ field, fieldState }) => (
                                <>
                                  <label className="edit-details-head mb-2">
                                    Why in YELDAM{" "}
                                    <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputTextarea
                                      maxLength={1500}
                                      placeholder="Why Did You Choose This Restaurant?"
                                      value={resObj.whyinyeldam.join("")}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        WhyinYeldam(e);
                                      }}
                                      rows={5}
                                      cols={30}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                          {/* yeldam Ratings */}
                          {/* <div className="row Yeldam-ratings">
                            <label className="ym-input-label">
                              Yeldam Ratings{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div className="hygine-rating-div">
                              <span className="rating-category">Hygiene</span>
                              <div className="rating d-flex justify-content-between mt-2 mb-2">
                                {[...Array(10)].map((_, index) => (
                                  <span
                                    key={index}
                                    onClick={() => handleHygieneRatingClick(index + 1)}
                                    className="box-number"
                                    style={{ backgroundColor: hygieneboxNumberColors[index] }}

                                  >
                                    {index + 1}
                                  </span>
                                ))}
                              </div>

                            </div>
                            <div className="hygine-rating-div">
                              <span className="rating-category">Health</span>
                              <div className="rating d-flex justify-content-between mt-2 mb-2">
                                {[...Array(10)].map((_, index) => (
                                  <span
                                    key={index}
                                    onClick={() => handleHealthRatingClick(index + 1)}
                                    className="box-number"
                                    style={{ backgroundColor: boxNumberColors[index] }}

                                  >
                                    {index + 1}
                                  </span>
                                ))}
                              </div>

                            </div>
                            <div className="hygine-rating-div">
                              <span className="rating-category">Taste</span>
                              <div className="rating d-flex justify-content-between mt-2 mb-2">
                                {[...Array(10)].map((_, index) => (
                                  <span
                                    key={index}
                                    onClick={() => handleTasteRatingClick(index + 1)}
                                    className="box-number"
                                    style={{ backgroundColor: tasteboxNumberColors[index] }}
                                  >
                                    {index + 1}
                                  </span>
                                ))}
                              </div>

                            </div>
                            <div className="hygine-rating-div">
                              <span className="rating-category">Cost Effective</span>
                              <div className="rating d-flex justify-content-between mt-2 mb-2">
                                {[...Array(10)].map((_, index) => (
                                  <span
                                    key={index}
                                    onClick={() => handleCostEffectiveRatingClick(index + 1)}
                                    className="box-number"
                                    style={{ backgroundColor: costboxNumberColors[index] }}
                                  >
                                    {index + 1}
                                  </span>
                                ))}
                              </div>

                            </div>
                            <div className="hygine-rating-div">
                              <span className="rating-category">Values</span>
                              <div className="rating d-flex justify-content-between mt-2 mb-2">
                                {[...Array(10)].map((_, index) => (
                                  <span
                                    key={index}
                                    onClick={() => handleValueRatingClick(index + 1)}
                                    className="box-number"
                                    style={{ backgroundColor: valueboxNumberColors[index] }}
                                  >
                                    {index + 1}
                                  </span>
                                ))}
                              </div>

                            </div>
                            <div className="hygine-rating-div">
                              <span className="rating-category">Hospitality</span>
                              <div className="rating d-flex justify-content-between mt-2 mb-2">
                                {[...Array(10)].map((_, index) => (
                                  <span
                                    key={index}
                                    onClick={() => handleHospitalityRatingClick(index + 1)}
                                    className="box-number"
                                    style={{ backgroundColor: hospitalityboxNumberColors[index] }}
                                  >
                                    {index + 1}
                                  </span>
                                ))}
                              </div>

                            </div>

                          </div> */}
                        </div>
                      </>
                    </ScrollPanel>
                    <div className="button-container">
                      <Button className="ym-add-button-previous" onClick={handleBack} >
                        <i className="pi pi-angle-left mr-1"></i>  Back
                      </Button>
                      <Button
                        className="p-button-success ym-add-button"
                        label="Update"
                        onClick={handleEditRestaurant}
                      ></Button>
                    </div>
                  </>
                </TabPanel>
              </TabView>
            </div>

          </section>
        </div >

      </main >
    </>
  )
};

export default EditRestaurant;
