import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from "../../pages/login/login.page";
import Approved from "../../pages/restaurants/approved-restaurants/approved-restaurants";
import ApprovedEvents from '../../pages/foodEvents/approved-events/approvedEvents.page';
import PendingEvents from '../../pages/foodEvents/pending-events/pendingEvents.page';
import RejectedEvents from '../../pages/foodEvents/rejected-events/rejectedEvents.page';
import Dashboard from "../../pages/dashboard/dashboard";
import Pending from "../../pages/restaurants/pending-restaurants/pending-restaurants";
import Rejected from "../../pages/restaurants/rejected-restaurants/rejected-restaurants";
import Agents from "../../pages/agents/agents"
import Details from '../../pages/restaurants/details-restaurant/details';
import Statistics from '../../pages/statistics/statistics';
import ApprovedStores from '../../pages/organicStores/approved-stores/approvedStores.page';
import PendingStores from '../../pages/organicStores/pending-stores/pendingStores.page';
import RejectedStores from '../../pages/organicStores/rejected-stores/rejectedStores.page';
import Settings from '../../pages/settings/settings.page';
import StoreDetails from '../../pages/organicStores/Details-OrganicStores/storeDetails.page';
import Healthtips from '../../pages/healthTips/Healthtips';
import Analytics from '../../pages/Analytics/Analytics';
import PendingDietitians from '../../pages/dietitians/pending-dietitians/pendingDietitians.page';
import ApprovedDietitians from '../../pages/dietitians/approved-dietitians/approvedDietitians.page';
import RejectedDietitians from '../../pages/dietitians/rejected-dietitians/rejectedDietitians.page';
import DietitianDetails from '../../pages/dietitians/details-dietitians/dietitianDetails';
import EditRestaurant from '../../pages/restaurants/edit-restaurant/EditRestaurant';
import EditStore from '../../pages/organicStores/edit-organicstore/EditStore';
import EditDietitian from '../../pages/dietitians/edit-dietitian/editDietitian';

export const AppRouter: React.FC = () => {
  const isAuthenticated = !!localStorage.getItem("accessToken");

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/"
          element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route path="/dashboard"
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
        />
        <Route path="/restaurants/approved" element={isAuthenticated ? <Approved /> : <Navigate to="/" />} />
        <Route path="/restaurants/pending" element={isAuthenticated ? <Pending /> : <Navigate to="/" />} />
        <Route path="/restaurants/rejected" element={isAuthenticated ? <Rejected /> : <Navigate to="/" />} />
        <Route path='/restaurants/pending/details/:id' element={isAuthenticated ? <Details /> : <Navigate to="/" />} />
        <Route path='/restaurants/approved/details/:id' element={isAuthenticated ? <Details /> : <Navigate to="/" />} />
        <Route path='/restaurants/rejected/details/:id' element={isAuthenticated ? <Details /> : <Navigate to="/" />} />
        <Route path="/restaurants/:type/editrestaurant/:id" element={isAuthenticated ? <EditRestaurant /> : <Navigate to="/" />}
        />


        <Route path="/foodevents/approvedevents" element={isAuthenticated ? <ApprovedEvents /> : <Navigate to="/" />} />
        <Route path="/foodevents/pendingevents" element={isAuthenticated ? <PendingEvents /> : <Navigate to="/" />} />
        <Route path="/foodevents/rejectedevents" element={isAuthenticated ? <RejectedEvents /> : <Navigate to="/" />} />

        <Route path="/organicstores/approvedstores" element={isAuthenticated ? <ApprovedStores /> : <Navigate to="/" />} />
        <Route path="/organicstores/pendingstores" element={isAuthenticated ? <PendingStores /> : <Navigate to="/" />} />
        <Route path="/organicstores/rejectedstores" element={isAuthenticated ? <RejectedStores /> : <Navigate to="/" />} />
        <Route path='/organicstores/pendingstores/details/:id' element={isAuthenticated ? <StoreDetails /> : <Navigate to="/" />} />
        <Route path='/organicstores/approvedstores/details/:id' element={isAuthenticated ? <StoreDetails /> : <Navigate to="/" />} />
        <Route path='/organicstores/rejectedstores/details/:id' element={isAuthenticated ? <StoreDetails /> : <Navigate to="/" />} />
        <Route path='/organicstores/:type/editorganicstore/:id' element={isAuthenticated ? <EditStore /> : <Navigate to="/" />} />


        <Route path="/dietitian/pendingdietitian" element={isAuthenticated ? <PendingDietitians /> : <Navigate to="/" />} />
        <Route path="/dietitian/approveddietitian" element={isAuthenticated ? <ApprovedDietitians /> : <Navigate to="/" />} />
        <Route path="/dietitian/rejecteddietitian" element={isAuthenticated ? <RejectedDietitians /> : <Navigate to="/" />} />
        <Route path='/dietitian/approveddietitian/details/:id' element={isAuthenticated ? <DietitianDetails /> : <Navigate to='/' />} />
        <Route path='/dietitian/pendingdietitian/details/:id' element={isAuthenticated ? <DietitianDetails /> : <Navigate to='/' />} />
        <Route path='/dietitian/rejecteddietitian/details/:id' element={isAuthenticated ? <DietitianDetails /> : <Navigate to='/' />} />
         <Route path= '/dietitian/:type/editdietitian/:id' element={isAuthenticated ? <EditDietitian/>  : <Navigate to='/' />} />

        <Route path="/agents" element={isAuthenticated ? <Agents /> : <Navigate to="/" />} />
        <Route path="/analytics" element={isAuthenticated ? <Analytics /> : <Navigate to="/" />} />
        <Route path="/healthtips" element={isAuthenticated ? <Healthtips /> : <Navigate to='/' />} />
        <Route path='/statistics' element={isAuthenticated ? <Statistics /> : <Navigate to="/" />} />
        <Route path="/settings" element={isAuthenticated ? <Settings /> : <Navigate to="/" />} />



      </Routes>
    </BrowserRouter>
  );

}