import React, { FunctionComponent, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "./bannersTable.components.css";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import Navbar from "../../ui/navbar/sidenav.components";
import { SettingsTable } from "../settingsTable.components";
import { Dialog } from "primereact/dialog";
import AddBannersModal from "./addBanners.components";
import { GetData } from "../../../api/settings.api";
import { settingStatus } from "../../../api/settings.api";
import { yeldamwebimagessas } from "../../../config/constants";

export default function BannersTable() {
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState(undefined);
  const [addShow, setAddShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const EditStatus = async () => {
    const respData = await settingStatus(bannersobj._id, !bannersobj.status);
    setVisible(false);
    refreshPage();
  };
  const refreshPage = () => {
    window.location.reload();
  };
  const footerContent = (
    <div className="d-flex justify-content-between">
    <Button
      label="Cancel"
      icon="pi pi-times"
      onClick={() => setVisible(false)}
      className="btn_cancel"
    />
      <Button
        label="Confirm"
        icon="pi pi-check"
        className="p-button-rounded p-button-success change-btn-login"
        onClick={() => {
          EditStatus();
        }}
        autoFocus
      />
    </div>
  );
  var width = window.screen.width;
  var c_rows = 10;
  if (width >= 1920) {
    c_rows = 15;
  } else if (width >= 1400 || width <= 1600) {
    c_rows = 10;
  }
  const [bannersobj, setBannersObj] = useState({
    _id: "",
    name: "",
    type: "",
    imageurl: "",
    status: false,
  });
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: c_rows,
    page: 1,
    sortField: "",
    sortOrder: 0,
    filters: {
      type: { value: "", matchMode: "contains" },
    },
  });

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onSort = (event: any) => {
    setLazyParams(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyParams(event);
  };
  const setEdit = (rowData: any) => {
    console.log(rowData);
    console.log(rowData.status);
    setBannersObj(rowData);
  };

  useEffect(() => {
    const bannerData = async () => {
      try {
        setLoading(true);
        const params = {};
        const data = await GetData(params);
        console.log("bannerdata:", data.data.banner);
        setBanners(data.data.banner);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    bannerData();
  }, []);
  const representativeBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <img
          alt={rowData.representative.name}
          src={`https://www.primereact.org/images/avatar/${rowData.representative.image}`}
          width={32}
          style={{ verticalAlign: "middle" }}
        />
        <span className="vertical-align-middle ml-2">
          {rowData.representative.name}
        </span>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-file-edit"
          className="p-button-rounded mr-2 view-bt"
          onClick={() => {
            setEdit(rowData);
            setEditShow(true);
          }}
        />
      </React.Fragment>
    );
  };
  const imageBodyTemplate = (rowData: any) => {
    return <img src={rowData.imageurl+"?"+yeldamwebimagessas} alt={rowData.imageurl} className="w-6rem shadow-2 border-round" />;

  };
  const StatusBodyTemplate: React.FC = (rowData: any) => {
    const [status, setStatus] = useState(rowData.status);
    const ChangeStatus = (status: boolean) => {
      setEdit(rowData);
      setVisible(true);
      console.log(rowData);
      setStatus(!status);
    };

    return (
      <React.Fragment>
        <InputSwitch
          checked={status}
          onChange={(e: any) => ChangeStatus(e.value)}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      <main>
        <div>
          <Navbar />
          <div className="ml-auto mb-4 d-flex justify-content-end align-items-center">
            <Button
              className=" add-settings-button"
              icon="pi pi-plus-circle"
              label="Add Banners"
              onClick={() => {
                setAddShow(true);
              }}
            ></Button>
          </div>
          <div className="card">
            <SettingsTable
              data={banners}
              first={lazyParams.first}
              sortField={lazyParams.sortField}
              sortOrder={lazyParams.sortOrder}
              onPage={onPage}
              onSort={onSort}
              onFilter={onFilter}
              filters={lazyParams.filters}
              loading={loading}
              actionBodyTemplate={actionBodyTemplate}
              statusBodyTemplate={StatusBodyTemplate}
              imageBodyTemplate={imageBodyTemplate}
            />
          </div>

          <AddBannersModal show={addShow} setShow={setAddShow} />
          {/* <EditModal  show={editShow} setShow={setEditShow} id={exploreobj._id} name={exploreobj.name} type={exploreobj.type} imageurl={exploreobj.imageurl}/> */}
          <Dialog
            header="Confirm status change"
            visible={visible}
            style={{ width: "30vw" }}
            onHide={() => setVisible(false)}
            footer={footerContent}
          >
            <p>
              Are you sure you want to change {bannersobj.name} status to{" "}
              {!bannersobj.status ? "active" : "inactive"}?
            </p>
          </Dialog>
        </div>
      </main>
    </>
  );
}
