import React, { useEffect, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import Navbar from "../../../components/ui/navbar/sidenav.components";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { ApproveOrganicStore } from "../../../api/restaurant-details.api";
import { RejectOrganicStore } from "../../../api/restaurant-details.api";
import { PostComment } from "../../../api/adminComments.api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/ui/loader/loader.components";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { Image } from "primereact/image";
import "./storeDetails.page.css";
import { devsas } from "../../../config/constants"
import { GetStorebyID, DeleteStoreByID } from "../../../api/organicStores.api";
import ServiceOptions from "../../../components/StoreServiceoptions/serviceOptions.component";
import StoreTimings from "../../../components/StoreTimings/storeTimings.component";
import StoreImages from "../../../components/StoreImages/storeImages.component";
import EditStore from "../edit-organicstore/EditStore";
import StoreInfo from "../../../components/StoreInfo/storeInfo.component";
import rejectpic from "../../../assets/images/rejectpic.png";
import approvepic from "../../../assets/images/approvepic.png"

const StoreDetails: React.FC<any> = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log(splitLocation);
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<number>(0);
  const [visible, setVisible] = useState(false);
  const [lvisible, setLvisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [isAdding, setIsadding] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [deletevisible, setDeleteVisible] = useState<boolean>(false);
  const [storeIdToDelete, setStoreIdToDelete] = useState<string | null>(null);
  const [customers, setCustomers] = useState<any[]>([]);

  const storeStatus = async () => {
    setVisible(false);
  };
  const [commentObj, setCommentObj] = useState({
    comment: "",
    approvalstatus: "",
  });

  const loadLazyData = () => {
    //imitate delay of a backend call
    const loadLazyTimeout = setTimeout(async () => {
      setIsadding(true);
      const storeData = await GetStorebyID(id as string);

      console.log("storeData", storeData.data.data);

      setStoretObj(storeData.data.data);
      setLvisible(false);
      setIsadding(false);
    }, 100);
  };
  useEffect(() => {
    setIsadding(true);
    loadLazyData();
    setLvisible(true);
  }, []);

  const [storeObj, setStoretObj] = useState({
    _id: "",
    address: "",
    storename: "",
    phoneno: "",
    email: "",
    agentname: "",
    approvalstatus: "",
    city: "",
    country: "",
    comment: "",
    createdAt: "",
    dateadded: "",
    district: "",
    locality: "",
    location: { coordinates: [0.0, 0.0] },
    pincode: "",
    serviceoptions: "",
    servicetype: [],
    state: "",
    storeimages: [],
    storesize: [],
    storetype: [],
    thumbnails: [],
    timings: [],
    updatedAt: "",
    website: "",
    __v: "",
    facebookurl: "",
    instaurl: "",
    youtubeurl: "",
    twitterurl: "",
  });

  console.log("storeeeee", storeObj);

  const toast = useRef<Toast>(null);
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  const onSubmit = async () => {
    if (status === 1) {
      const respData = await ApproveOrganicStore(id as string);
      show(respData.data.message, "info", "Info");
    } else {

      const respData = await RejectOrganicStore(id as string, {
        approvalstatus: 2,
        comment: commentObj.comment
      });
      console.log("API hitting for reject");
      show(respData.data.message, "info", "Info");
    }

    const { pathname } = location;
    const splitpath = pathname.split("/");
    const pathname_s = "/" + splitpath[1] + "/" + splitpath[2];
    navigate(pathname_s);
  };

  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };


  const footerContent = (
    <div className="d-flex justify-content-between">
      <Button
        label="cancel"
        onClick={() => {
          setVisible(false);
        }}
        className="p-button-outlined p-button-rounded cancel_btn"
      />
      {status === 0 ? (
        <Button
          label="Yes, Reject"
          className={commentObj.comment.length > 0 ? "yes_reject_btn" : "disabled_reject_btn"}
          onClick={() => {
            if (commentObj.comment.length > 0) {
              onSubmit();
              storeStatus();
            }
          }}
          disabled={commentObj.comment.length === 0}
          autoFocus
        />
      ) : (
        <Button
          label="Yes, Approve"
          className="p-button-rounded p-button-success change-btn-login"
          onClick={() => {
            onSubmit();
            storeStatus();
          }}
          autoFocus
        />
      )}

    </div>
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest('.details-tooltip-box, .pi-ellipsis-v')) {
        setTooltipVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const openDeleteDialog = (storeId: string) => {
    setStoreIdToDelete(storeId);
    setTooltipVisible(false);
    setDeleteVisible(true);
  };

  const handleDeleteStore = async () => {
    setDeleteVisible(false);
    if (storeIdToDelete) {
      setLvisible(true);
      try {
        const response = await DeleteStoreByID(storeIdToDelete);
        if (response?.status === 200) {
          show("Organic Store Deleted Successfully", "info", "Info");

          setCustomers((prevCustomers: any[] | undefined) =>
            prevCustomers ? prevCustomers.filter((customer: { _id: string }) => customer._id !== storeIdToDelete) : []
          );

          console.log("Iam hitting delete 200");
          setTooltipVisible(false);
          setTimeout(() => {
            setLvisible(false);
            const { pathname } = location;
            const splitpath = pathname.split("/");
            const pathname_s = "/" + splitpath[1] + "/" + splitpath[2];
            navigate(pathname_s);

          }, 2000);
        }
      } catch (error) {
        console.error("Error deleting store:", error);
      }
    }
  };

  const isRejectedStore = location.pathname.includes("/rejected");

  return (
    <>
      <main>
        <Toast ref={toast}></Toast>

        {!lvisible ? '' : <Loader />}
        <div>
          <Navbar />
          <section className="page-content">
            <div className="ym-content-table mt-0">
              <div className="page_title d-flex">
                <div className="p-2 d-flex">
                  &nbsp;
                  <span
                    className="back_rest_btn d-flex gap-2 align-items-center"
                    onClick={() => {
                      const currentPath = window.location.pathname;
                      if (currentPath.includes("/pendingstores")) {
                        navigate(`/organicstores/pendingstores`);
                      } else if (currentPath.includes("/approvedstores")) {
                        navigate(`/organicstores/approvedstores`);
                      } else if (currentPath.includes("/rejected")) {
                        navigate(`/organicstores/rejectedstores`);
                      }
                    }}
                  >
                    <i className="pi pi-arrow-left"></i>
                    Organic Store Details
                  </span>
                </div>

                {splitLocation[2] === "pendingstores" ? (
                  <div className="ml-auto d-flex align-items-center gap-5">
                    <Button
                      label="Approve"
                      icon="pi pi-check-circle"
                      className="p-button-rounded p-button-success change-btn-login"
                      onClick={() => {
                        setVisible(true);
                        setStatus(1);

                      }}
                      autoFocus
                    />

                    <Button
                      label="Reject"
                      icon="pi pi-times-circle"
                      onClick={() => {
                        setVisible(true);
                        setStatus(0);
                        setCommentObj({ ...commentObj, approvalstatus: "0" });
                      }}
                      className="p-button-outlined p-button-rounded p-button-danger reject_btn"
                    />

                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
                        <i className="pi pi-ellipsis-v"></i>
                      </div>

                      {isTooltipVisible && (
                        <div className="details-tooltip-box mt-3">
                          <button className="tooltip-button mb-1"
                            onClick={() => {
                              const currentPath = window.location.pathname;
                              let organicstoreType = 'pendingstores';

                              if (currentPath.includes("approvedstores")) {
                                organicstoreType = 'approvedstores'
                              }
                              if (currentPath.includes("/rejected")) {
                                organicstoreType = 'rejectedstores';
                              }

                              navigate(`/organicstores/${organicstoreType}/editorganicstore/${id}`);
                            }}
                          >
                            <i className="pi  pi-pencil mr-2" style={{ fontSize: "12px" }}></i>Edit Organic Store
                          </button>
                          <button className="tooltip-button"
                            onClick={() => openDeleteDialog(storeObj._id)}
                          >
                            <i className="pi pi-trash mr-2" style={{ fontSize: "12px" }}></i>Delete Organic Store
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : splitLocation[2] === "approvedstores" ? (
                  <div className="ml-auto d-flex align-items-center gap-5">
                    <Button
                      label="Edit Organic Store"
                      icon="pi pi-pencil"
                      onClick={() => {
                        const currentPath = window.location.pathname;
                        let organicstoreType = 'pendingstores';
                        if (currentPath.includes("approvedstores")) {
                          organicstoreType = 'approvedstores'
                        }

                        if (currentPath.includes("/rejected")) {
                          organicstoreType = 'rejectedstores';
                        }

                        navigate(`/organicstores/${organicstoreType}/editorganicstore/${id}`);
                      }}
                      className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                    />

                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
                        <i className="pi pi-ellipsis-v"></i>
                      </div>

                      {isTooltipVisible && (
                        <div className="details-tooltip-box mt-3">
                          <button className="tooltip-button mb-1"
                            onClick={() => {
                              setVisible(true);
                              setStatus(0);
                              setCommentObj({ ...commentObj, approvalstatus: "0" });
                            }}
                          >
                            <i className="pi  pi-times-circle mr-2" style={{ fontSize: "12px" }}></i>Reject Organic Store
                          </button>
                          {/* <button className="tooltip-button" onClick={() => alert('Disable Restaurant')}>
                            <i className="pi pi-exclamation-triangle mr-2" style={{ fontSize: "12px" }}></i>Disable Organic Store
                          </button> */}
                          <button className="tooltip-button"
                            onClick={() => openDeleteDialog(storeObj._id)}
                          >
                            <i className="pi pi-trash mr-2" style={{ fontSize: "12px" }}></i>Delete Organic Store
                          </button>
                        </div>
                      )}
                    </div>

                  </div>
                ) : (
                  <div className="ml-auto d-flex align-items-center gap-5">
                    <Button
                      label="Edit Organic Store"
                      icon="pi pi-pencil"
                      onClick={() => {
                        const currentPath = window.location.pathname;
                        let organicstoreType = 'pendingstores';
                        if (currentPath.includes("approvedstores")) {
                          organicstoreType = 'approvedstores'
                        }

                        if (currentPath.includes("/rejected")) {
                          organicstoreType = 'rejectedstores';
                        }

                        navigate(`/organicstores/${organicstoreType}/editorganicstore/${id}`);
                      }}
                      className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                    />

                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
                        <i className="pi pi-ellipsis-v"></i>
                      </div>

                      {isTooltipVisible && (
                        <div className="details-tooltip-box mt-3">
                          <button className="tooltip-button mb-1"
                            onClick={() => {
                              setVisible(true);
                              setStatus(1);
                              setCommentObj({ ...commentObj, approvalstatus: "1" });
                            }}
                          >
                            <i className="pi  pi-check-circle mr-2" style={{ fontSize: "12px" }}></i>Approve Organic Store
                          </button>
                          {/* <button className="tooltip-button" onClick={() => alert('Disable Restaurant')}>
                            <i className="pi pi-exclamation-triangle mr-2" style={{ fontSize: "12px" }}></i>Disable Organic Store
                          </button> */}
                          <button className="tooltip-button"
                            onClick={() => openDeleteDialog(storeObj._id)}
                          >
                            <i className="pi pi-trash mr-2" style={{ fontSize: "12px" }}></i>Delete Organic Store
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <hr />
              <div className="ym-content-table mb-4">
                <div className=" gap-3 d-flex align-items-start">
                  <div
                    className="row mb-1 view-gallery-cols"
                    style={{ height: "180px" }}
                  >
                    <div className="pt-0 pl-0">
                      {/* @ts-ignore       */}
                      <Image className='dietitian-profile' src={storeObj.thumbnails[0] + "?" + devsas} alt="Image" preview />
                    </div>
                  </div>

                  <div className="col-lg-9 d-flex">

                    <div className="col-6 d-flex align-items-left mb-4 justify-content-center flex-column ml-1 gap-3 rest-details">
                      <div className='Agentname-div'>
                        Added By <span style={{ color: "#3c3c3c", fontSize: "12px" }}>{storeObj.agentname}</span>
                      </div>
                      <div className="d-flex gap-3" >
                        <div className="page-title">
                          {!lvisible ? (
                            <h1 className="ym-portal-heading">
                              {storeObj.storename}
                            </h1>
                          ) : (
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                          )}
                        </div>
                      </div>

                      <div className="address-div col-lg-6">
                        {!lvisible ? (
                          <div>
                            <p className="area-data mb-0">
                              {storeObj.address +
                                "," +
                                storeObj.locality +
                                "," +
                                storeObj.city +
                                "," +
                                " " +
                                storeObj.state +
                                "-" +
                                storeObj.pincode +
                                "."}
                            </p>
                          </div>
                        ) : (
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                    </div>
                    {isRejectedStore && (
                      <div className='col-6 p-1'>
                        <div className="">
                          <div className="ambience">
                            <h6 className="mb-2 rr-head">Rejection Reason</h6>
                            <div className='reason-disc-box'>
                              <span className="span-data">
                                {storeObj.comment ? storeObj.comment : "--"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <TabView>
                <TabPanel header="Organic Store Info">
                  <StoreInfo
                    phone={storeObj.phoneno}
                    email={storeObj.email}
                    website={storeObj.website}
                    facebookurl={storeObj.facebookurl}
                    instaurl={storeObj.instaurl}
                    youtubeurl={storeObj.youtubeurl}
                    twitterurl={storeObj.twitterurl}
                    latitude={storeObj.location.coordinates[1]}
                    longitude={storeObj.location.coordinates[0]}

                  />
                </TabPanel>
                <TabPanel header="Service Options">
                  <ServiceOptions
                    storetype={storeObj.storetype}
                    servicetype={storeObj.servicetype}
                  />
                </TabPanel>
                <TabPanel header="Timings">
                  <StoreTimings timings={storeObj.timings} />
                </TabPanel>

                <TabPanel header={`Photos (${(storeObj.storeimages?.length || 0) + (storeObj.thumbnails?.length || 0)})`}>
                  <StoreImages
                    storeimages={storeObj.storeimages}
                    thumbnailimages={storeObj.thumbnails}
                  />
                </TabPanel>
              </TabView>
            </div>
            <Dialog
              visible={visible}
              className="status_change_div"
              style={{ width: "20vw" }}
              onHide={() => {
                setVisible(false);
              }}
              footer={footerContent}
            >
              {status === 0 ? (
                <div className="d-flex justify-content-center mb-2">
                  <img className="aprjpic" src={rejectpic} />
                </div>
              ) : (
                <div className="d-flex justify-content-center mb-2">
                  <img className="aprjpic" src={approvepic} />
                </div>
              )
              }
              <p className="footer-text">
                Are you sure you want to {status === 0 ? "reject" : "approve"} the organic store?
              </p>

              {status === 0 ? (
                <div className="reject-com-div">
                  <p className="reason_head">
                    Reason<b style={{ color: "red" }}>*</b>
                  </p>
                  <div className="row">
                    <div className="flex flex-column gap-2">
                      <InputTextarea
                        placeholder="Write here"
                        value={commentObj.comment}
                        rows={6}
                        onChange={(e: any) =>
                          setCommentObj({ ...commentObj, comment: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="approve-div">
                  This will make the organic store visible to users. Please confirm.
                </div>
              )}
            </Dialog>

          </section>
        </div>
      </main>
      <Dialog
        visible={deletevisible}
        closeOnEscape={false}
        style={{ width: "25vw" }}
        className="delete-model"
        breakpoints={{
          "1920px": "50vw",
          "1420px": "50vw",
          "1120px": "50vw",
          "1090px": "40vw",
          "980px": "50vw",
          "840px": "60vw",
          "740px": "50vw",
          "641px": "70vw",
          "541px": "70vw",
          "490px": "80vw",
          "350px": "90vw",
        }}
        onHide={() => setDeleteVisible(false)}
      >
        <div className="d-flex  justify-content-center align-items-center flex-column p-4 gap-2">
          <div className="delete-icon-bg p-3 d-flex justify-content-center align-items-center">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 11C11.5523 11 12 11.4477 12 12V24C12 24.5523 11.5523 25 11 25C10.4477 25 10 24.5523 10 24V12C10 11.4477 10.4477 11 11 11Z" fill="#D83B01" />
              <path d="M16 11C16.5523 11 17 11.4477 17 12V24C17 24.5523 16.5523 25 16 25C15.4477 25 15 24.5523 15 24V12C15 11.4477 15.4477 11 16 11Z" fill="#D83B01" />
              <path d="M22 12C22 11.4477 21.5523 11 21 11C20.4477 11 20 11.4477 20 12V24C20 24.5523 20.4477 25 21 25C21.5523 25 22 24.5523 22 24V12Z" fill="#D83B01" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M29 6C29 7.10457 28.1046 8 27 8H26V26C26 28.2091 24.2091 30 22 30H10C7.79086 30 6 28.2091 6 26V8H5C3.89543 8 3 7.10457 3 6V4C3 2.89543 3.89543 2 5 2H12C12 0.895431 12.8954 0 14 0H18C19.1046 0 20 0.895431 20 2H27C28.1046 2 29 2.89543 29 4V6ZM8.23607 8L8 8.11803V26C8 27.1046 8.89543 28 10 28H22C23.1046 28 24 27.1046 24 26V8.11803L23.7639 8H8.23607ZM5 6V4H27V6H5Z" fill="#D83B01" />
            </svg>
          </div>
          <div className="d-head">
            Are you sure you want to delete organic store?
          </div>
          <div className="d-caption">
            This action is permanent. Would you like to continue?
          </div>
        </div>
        <div className="dlt-dialog-btns d-flex justify-content-between align-items-center pl-4 pr-4">
          <Button
            label="No"
            onClick={() => setDeleteVisible(false)}
            className="no_btn_dlt"
          ></Button>
          <Button
            label="Yes, Delete"
            type="submit"
            onClick={handleDeleteStore}
            className="yes_dlt_btn"
          ></Button>
        </div>

      </Dialog>
    </>
  );
}

export default StoreDetails;
