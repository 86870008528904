import React, { useEffect, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import "./details.css";
import { Button } from "primereact/button";
import Restaurantdetails from "../../../components/restaurant-details/restaurant-details.components";
import Navbar from "../../../components/ui/navbar/sidenav.components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { DeleteRestByID, GetRestByID } from "../../../api/pendingTable.api";
import { useNavigate, useParams } from "react-router-dom";
import { PostComment } from "../../../api/adminComments.api";
import { ApproveRestaurant } from "../../../api/restaurant-details.api";
import { RejectRestaurant } from "../../../api/restaurant-details.api";
import { useLocation } from "react-router-dom";
import Show from "../../../components/ui/toast/toast.components";
import Loader from "../../../components/ui/loader/loader.components";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { Image } from "primereact/image";
import { Tooltip } from "primereact/tooltip";
import Restaurantphotos from "../../../components/RestaurantPhotos/Restaurant.photos.component";
import Restaurantmenu from "../../../components/RestaurantMenu/Restaurant.menu.component";
import RestaurantTimings from "../../../components/RestaurantTimings/restaurantTimings.component";
import AditionalInfo from "../../../components/RestaurantAditionalInfo/aditionalInfo.component";
import { devsas } from "../../../config/constants"
import EditRestaurant from "../edit-restaurant/EditRestaurant";
import RestaurantInfo from "../../../components/RestaurantInfo/restaurantInfo.component";
import rejectpic from "../../../assets/images/rejectpic.png";
import approvepic from "../../../assets/images/approvepic.png"

const Details = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log(splitLocation);
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [deletevisible, setDeleteVisible] = useState<boolean>(false);
  const [restaurantIdToDelete, setRestaurantIdToDelete] = useState<string | null>(null);


  const [commentObj, setCommentObj] = useState({
    agentID: "",
    restID: "",
    comment: "",
    approvalstatus: "",
  });
  const [restObj, setRestObj] = useState({
    _id: "",
    name: "",
    address1: "",
    locality: "",
    city: "",
    state: "",
    maplink: "",
    diettype: [],
    location: {
      coordinates: [0, 0],
    },
    latitude: "",
    longitude: "",
    country: "",
    comment: "",
    pincode: "",
    landmark: "",
    website: "",
    email: "",
    phoneno: "",
    eaterytype: [],
    foodtype: [],
    preference: [],
    cuisine: [],
    ambience: "",
    serving: [],
    mealoftheday: [],
    serviceoptions: [],
    values: [],
    features: [],
    specialitems: [],
    sourcelink: "",
    timings: [],
    days: [],
    source: "",
    about: [],
    whyinyeldam: [],
    images: [],
    menuimages: [],
    thumbnails: [],
    zomatolink: "",
    swiggylink: "",
    dineoutlink: "",
    averagecost: "",
    approvalstatus: 0,
    addedby: "",
    agentname: "",
    sizeofestablishment: "",
    hygiene: 0,
    health: 0,
    taste: 0,
    costeffective: 0,
    valuesrating: 0,
    hospitality: 0,
    facebookurl: "",
    instaurl: "",
    youtubeurl: "",
    twitterurl: "",
  });
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [lvisible, setLvisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const restStatus = async () => {
    // const respData =await RestStatus(restObj._id,!restObj.status);
    // console.log(respData);
    // toastShow(respData.data.message)
    setVisible(false);
    // refreshPage();
  };
  const toast = useRef<Toast>(null);
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  const loadLazyData = () => {
    //imitate delay of a backend call
    const loadLazyTimeout = setTimeout(async () => {
      const resData = await GetRestByID(id as string);
      console.log("data called", resData.data.data.cuisine);
      setRestObj(resData.data.data);
      console.log(status);
      setCommentObj({
        ...commentObj,
        restID: resData.data.data._id,
        agentID: resData.data.data.addedby,
      });
      setLvisible(false);
    }, 100);
  };
  useEffect(() => {
    setLvisible(true);
    loadLazyData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const items = restObj.images.map((item) => {
    const style = { padding: 5 };
    return (
      <img src={item} width="300px" height="250px" style={style} alt="..." />
    );
  });

  const onSubmit = async () => {
    if (status === 1) {
      const respData = await ApproveRestaurant(id as string);
      show(respData.data.message, "info", "Info");
    } else {
      const respData = await RejectRestaurant(id as string, {
        approvalstatus: 2,
        comment: commentObj.comment
      });
      console.log("API hitting for reject");
      show(respData.data.message, "info", "Info");
    }
    const { pathname } = location;
    const splitpath = pathname.split("/");
    console.log("pathname" + splitpath[0]);
    const pathname_s = "/" + splitpath[1] + "/" + splitpath[2];
    navigate(pathname_s);
    // navigate("/pending")
  };
  const footerContent = (
    <div className="d-flex justify-content-between">
      <Button
        label="cancel"
        onClick={() => {
          setVisible(false);
        }}
        className="p-button-outlined p-button-rounded cancel_btn"
      />
      {status === 0 ? (
        <Button
          label="Yes, Reject"
          className={commentObj.comment.length > 0 ? "yes_reject_btn" : "disabled_reject_btn"}
          onClick={() => {
            if (commentObj.comment.length > 0) {
              onSubmit();
              restStatus();
            }
          }}
          disabled={commentObj.comment.length === 0}
          autoFocus
        />
      ) : (
        <Button
          label="Yes, Approve"
          className="p-button-rounded p-button-success change-btn-login"
          onClick={() => {
            onSubmit();
            restStatus();
          }}
          autoFocus
        />
      )}

    </div>
  );
  const pendingButtons = () => {
    return (
      <div className="ml-auto ">
        <Button
          label="Reject"
          icon="pi pi-times"
          onClick={() => {
            setVisible(true);
            setStatus(0);
            setCommentObj({ ...commentObj, approvalstatus: "1" });
            window.close();
          }}
          className="p-button-outlined p-button-rounded p-button-danger"
        />
        <Button
          label="Approve"
          icon="pi pi-check"
          className="p-button-rounded p-button-success change-btn-login"
          onClick={() => {
            setVisible(true);
            setStatus(1);
            setCommentObj({ ...commentObj, approvalstatus: "1" });
          }}
          autoFocus
        />
      </div>
    );
  };
  const approvedButton: React.FC = () => {
    return (
      <div className="ml-auto ">
        <Button
          label="Reject"
          icon="pi pi-times"
          onClick={() => {
            setVisible(true);
            setStatus(0);
          }}
          className="p-button-outlined p-button-rounded p-button-danger"
        />
      </div>
    );
  };
  const deniedButton: React.FC = () => {
    return (
      <div className="ml-auto ">
        <Button
          label="Approve"
          icon="pi pi-check"
          className="p-button-rounded p-button-success change-btn-login"
          onClick={() => {
            setVisible(true);
            setStatus(1);
          }}
          autoFocus
        />
      </div>
    );
  };

  function titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  const [isTooltipVisible, setTooltipVisible] = useState(false);


  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest('.details-tooltip-box, .pi-ellipsis-v')) {
        setTooltipVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const openDeleteDialog = (restaurantId: string) => {
    setRestaurantIdToDelete(restaurantId);
    setTooltipVisible(false);
    setDeleteVisible(true);
  };

  const handleDeleteRestaurant = async () => {
    setDeleteVisible(false);
    if (restaurantIdToDelete) {
      setLvisible(true);
      try {
        const response = await DeleteRestByID(restaurantIdToDelete);
        if (response?.status === 200) {
          show("Restaurant Deleted Successfully", "info", "Info");

          setCustomers((prevCustomers: any[] | undefined) =>
            prevCustomers ? prevCustomers.filter((customer: { _id: string }) => customer._id !== restaurantIdToDelete) : []
          );

          console.log("Iam hitting delete 200");
          setTooltipVisible(false);
          setTimeout(() => {
            setLvisible(false);
            const { pathname } = location;
            const splitpath = pathname.split("/");
            const pathname_s = "/" + splitpath[1] + "/" + splitpath[2];
            navigate(pathname_s);

          }, 2000);
        }
      } catch (error) {
        console.error("Error deleting restaurant:", error);
      }
    }
  };

  console.log("restdetails", restObj);


  const isRejectedRestaurant = location.pathname.includes("/rejected");

  return (
    <>
      <main>
        <Toast ref={toast}></Toast>
        {!lvisible ? '' : <Loader />}
        <div>
          <Navbar />
          <section className="page-content">
            <div className="ym-content-table mt-0">
              <div className="page_title d-flex align-items-center">
                <div className="p-2 d-flex">
                  &nbsp;
                  <span
                    className="back_rest_btn d-flex gap-2 align-items-center"
                    onClick={() => {
                      const currentPath = window.location.pathname;
                      if (currentPath.includes("/pending")) {
                        navigate(`/restaurants/pending`);
                      } else if (currentPath.includes("/approved")) {
                        navigate(`/restaurants/approved`);
                      } else if (currentPath.includes("/rejected")) {
                        navigate(`/restaurants/rejected`);
                      }
                    }}
                  >
                    <i className="pi pi-arrow-left"></i>
                    Restaurant Details
                  </span>
                </div>

                {splitLocation[2] === "pending" ? (
                  <div className="ml-auto d-flex align-items-center gap-5">

                    <Button
                      label="Approve"
                      icon="pi pi-check-circle"
                      className="p-button-rounded p-button-success change-btn-login"
                      onClick={() => {
                        setVisible(true);
                        setStatus(1);
                        setCommentObj({ ...commentObj, approvalstatus: "1" });
                      }}
                      autoFocus
                    />
                    <Button
                      label="Reject"
                      icon="pi pi-times-circle"
                      onClick={() => {
                        setVisible(true);
                        setStatus(0);
                        setCommentObj({ ...commentObj, approvalstatus: "0" });
                      }}
                      className="p-button-outlined p-button-rounded p-button-danger reject_btn"
                    />

                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
                        <i className="pi pi-ellipsis-v"></i>
                      </div>

                      {isTooltipVisible && (
                        <div className="details-tooltip-box mt-3">
                          <button className="tooltip-button mb-1"
                            onClick={() => {
                              const currentPath = window.location.pathname;
                              let restaurantType = 'pending';

                              if (currentPath.includes("/approved")) {
                                restaurantType = 'approved'
                              };

                              if (currentPath.includes("/rejected")) {
                                restaurantType = 'rejected';
                              }

                              navigate(`/restaurants/${restaurantType}/editrestaurant/${id}`);
                            }}
                          >
                            <i className="pi  pi-pencil mr-2" style={{ fontSize: "12px" }}></i>Edit Restaurant
                          </button>

                          <button className="tooltip-button"
                            onClick={() => openDeleteDialog(restObj._id)}
                          >
                            <i className="pi pi-trash mr-2" style={{ fontSize: "12px" }}></i>Delete Restaurant
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : splitLocation[2] === "approved" ? (
                  <div className="ml-auto d-flex align-items-center gap-5">
                    <Button
                      label="Edit Restaurant"
                      icon="pi pi-pencil"
                      onClick={() => {
                        const currentPath = window.location.pathname;
                        let restaurantType = 'pending';

                        if (currentPath.includes("/approved")) {
                          restaurantType = 'approved'
                        };

                        if (currentPath.includes("/rejected")) {
                          restaurantType = 'rejected';
                        }

                        navigate(`/restaurants/${restaurantType}/editrestaurant/${id}`);
                      }}
                      className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                    />

                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
                        <i className="pi pi-ellipsis-v"></i>
                      </div>

                      {isTooltipVisible && (
                        <div className="details-tooltip-box mt-3">
                          <button className="tooltip-button mb-1"
                            onClick={() => {
                              setVisible(true);
                              setStatus(0);
                              setCommentObj({ ...commentObj, approvalstatus: "0" });
                            }}
                          >
                            <i className="pi  pi-times-circle mr-2" style={{ fontSize: "12px" }}></i>Reject Restaurant
                          </button>
                          {/* <button className="tooltip-button" onClick={() => alert('Disable Restaurant')}>
                            <i className="pi pi-exclamation-triangle mr-2" style={{ fontSize: "12px" }}></i>Disable Restaurant
                          </button> */}
                          <button className="tooltip-button"
                            onClick={() => openDeleteDialog(restObj._id)}
                          >
                            <i className="pi pi-trash mr-2" style={{ fontSize: "12px" }}></i>Delete Restaurant
                          </button>
                        </div>
                      )}
                    </div>

                  </div>
                ) : (
                  <div className="ml-auto d-flex align-items-center gap-5">
                    <Button
                      label="Edit Restaurant"
                      icon="pi pi-pencil"
                      onClick={() => {
                        const currentPath = window.location.pathname;
                        let restaurantType = 'pending';

                        if (currentPath.includes("/approved")) {
                          restaurantType = 'approved'
                        };

                        if (currentPath.includes("/rejected")) {
                          restaurantType = 'rejected';
                        }

                        navigate(`/restaurants/${restaurantType}/editrestaurant/${id}`);
                      }}
                      className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                    />

                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
                        <i className="pi pi-ellipsis-v"></i>
                      </div>

                      {isTooltipVisible && (
                        <div className="details-tooltip-box mt-3">
                          <button className="tooltip-button mb-1"
                            onClick={() => {
                              setVisible(true);
                              setStatus(1);
                              setCommentObj({ ...commentObj, approvalstatus: "1" });
                            }}
                          >
                            <i className="pi  pi-check-circle mr-2" style={{ fontSize: "12px" }}></i>Approve Restaurant
                          </button>
                          {/* <button className="tooltip-button" onClick={() => alert('Disable Restaurant')}>
                            <i className="pi pi-exclamation-triangle mr-2" style={{ fontSize: "12px" }}></i>Disable Restaurant
                          </button> */}
                          <button className="tooltip-button"
                            onClick={() => openDeleteDialog(restObj._id)}
                          >
                            <i className="pi pi-trash mr-2" style={{ fontSize: "12px" }}></i>Delete Restaurant
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <hr />

              <div className="ym-content-table mb-3 container">
                <div className="gap-3 d-flex col-lg-12">
                  <div
                    className="row mb-1 view-gallery-cols"
                    style={{ height: "180px" }}
                  >
                    <div className=" pt-0 pl-0">
                      {/* @ts-ignore       */}
                      <Image className='dietitian-profile' src={restObj.thumbnails[0] + "?" + devsas} alt="Image" preview />
                    </div>
                  </div>

                  <div className="col-lg-9 d-flex">

                    <div className="col-6 d-flex align-items-left justify-content-center flex-column ml-1 gap-4 rest-details">
                      <div className='Agentname-div'>
                        Added By <span style={{ color: "#3c3c3c", fontSize: "12px" }}>{restObj.agentname}</span>
                      </div>
                      <div className="d-flex gap-3" >
                        <div className="page-title col-lg-6">
                          {!lvisible ? (
                            <h1 className="ym-portal-heading">{restObj.name}</h1>
                          ) : (
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                          )}
                        </div>

                      </div>
                      <div className="address-latlong gap-3 d-flex">
                        <div className="address-div col-lg-6">
                          {!lvisible ? (
                            <div>
                              <p className="area-data mb-0">
                                {restObj.address1 +
                                  "," +
                                  restObj.locality +
                                  "," +
                                  restObj.city +
                                  "," +
                                  " " +
                                  restObj.state +
                                  "-" +
                                  restObj.pincode +
                                  "."}
                              </p>
                            </div>
                          ) : (
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                          )}
                        </div>
                      </div>
                    </div>
                    {isRejectedRestaurant && (
                      <div className='col-6'>
                        <div className="">
                          <div className="ambience">
                            <h6 className="mb-2 rr-head">Rejection Reason</h6>
                            <div className='reason-disc-box'>
                              <span className="span-data">
                                {restObj.comment ? restObj.comment : "--"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <TabView
                  activeIndex={activeIndex}
                  onTabChange={(e) => setActiveIndex(e.index)}
                >
                  {/* @ts-ignore */}

                  <TabPanel header="Restaurant Info">
                    <RestaurantInfo
                      phoneno={restObj.phoneno}
                      email={restObj.email}
                      website={restObj.website}
                      facebookurl={restObj.facebookurl}
                      instaurl={restObj.instaurl}
                      youtubeurl={restObj.youtubeurl}
                      twitterurl={restObj.twitterurl}
                      latitude={restObj.location.coordinates[1]}
                      longitude={restObj.location.coordinates[0]}

                    />
                  </TabPanel>

                  <TabPanel header="Food Preference">
                    <Restaurantdetails
                      cuisine={restObj.cuisine}
                      specialitems={restObj.specialitems}
                      preference={restObj.preference}
                      foodtype={restObj.foodtype}
                      eaterytype={restObj.eaterytype}
                      ambience={restObj.ambience}
                      values={restObj.values}
                      serving={restObj.serving}
                      serviceoptions={restObj.serviceoptions}
                      phoneno={restObj.phoneno}
                      address={restObj.address1 + "," + restObj.locality}
                      sourcelink={restObj.sourcelink}
                      images={restObj.images}
                      description={restObj.about}
                      agentname={restObj.agentname}
                      whyyeldam={restObj.whyinyeldam}
                      zomatolink={restObj.zomatolink}
                      swiggylink={restObj.swiggylink}
                      dineoutlink={restObj.dineoutlink}
                      maplink={restObj.maplink}
                      email={restObj.email}
                      website={restObj.website}
                      city={restObj.city}
                      state={restObj.state}
                      pincode={restObj.pincode}
                      mealoftheday={restObj.mealoftheday}
                      averagecost={restObj.averagecost}
                      features={restObj.features}
                      diettype={restObj.diettype}
                      sizeofestablishment={restObj.sizeofestablishment}
                    />
                  </TabPanel>
                  {/* @ts-ignore       */}
                  <TabPanel header="Timings">
                    <RestaurantTimings timing={restObj.timings} editable={false} />
                  </TabPanel>
                  <TabPanel header={`Photos (${(restObj.images?.length || 0) + (restObj.thumbnails?.length || 0)})`}>
                    <Restaurantphotos
                      images={restObj.images}
                      thumbnail={restObj.thumbnails}
                    />
                  </TabPanel>
                  {/* @ts-ignore       */}
                  <TabPanel header={`Menu (${restObj.menuimages.length})`}>
                    <Restaurantmenu
                      menuimages={restObj.menuimages}
                    />
                  </TabPanel>
                  <TabPanel header="Additional Info">
                    <AditionalInfo
                      description={restObj.about}
                      whyyeldam={restObj.whyinyeldam}
                      features={restObj.features}
                      hygiene={restObj.hygiene}
                      health={restObj.health}
                      taste={restObj.taste}
                      costeffective={restObj.costeffective}
                      valuesrating={restObj.valuesrating}
                      hospitality={restObj.hospitality}
                      facebookurl={restObj.facebookurl}
                      instaurl={restObj.instaurl}
                      youtubeurl={restObj.youtubeurl}
                      twitterurl={restObj.twitterurl}
                    />
                  </TabPanel>
                </TabView>
              </div>
              <Dialog
                visible={visible}
                className="status_change_div"
                style={{ width: "20vw" }}
                onHide={() => {
                  setVisible(false);
                }}
                footer={footerContent}
              >
                {status === 0 ? (
                  <div className="d-flex justify-content-center mb-2">
                    <img className="aprjpic" src={rejectpic} />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center mb-2">
                    <img className="aprjpic" src={approvepic} />
                  </div>
                )
                }
                <p className="footer-text">
                  Are you sure you want to {status === 0 ? "reject" : "approve"} the restaurant?
                </p>

                {status === 0 ? (
                  <div className="reject-com-div">
                    <p className="reason_head">
                      Reason<b style={{ color: "red" }}>*</b>
                    </p>
                    <div className="row">
                      <div className="flex flex-column gap-2">
                        <InputTextarea
                          placeholder="Write here"
                          value={commentObj.comment}
                          rows={6}
                          onChange={(e: any) =>
                            setCommentObj({ ...commentObj, comment: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="approve-div">
                    This will make the restaurant visible to users. Please confirm.
                  </div>
                )}
              </Dialog>


            </div>
          </section>
        </div>
      </main>
      <Dialog
        visible={deletevisible}
        closeOnEscape={false}
        style={{ width: "25vw" }}
        className="delete-model"
        breakpoints={{
          "1920px": "50vw",
          "1420px": "50vw",
          "1120px": "50vw",
          "1090px": "40vw",
          "980px": "50vw",
          "840px": "60vw",
          "740px": "50vw",
          "641px": "70vw",
          "541px": "70vw",
          "490px": "80vw",
          "350px": "90vw",
        }}
        onHide={() => setDeleteVisible(false)}
      >
        <div className="d-flex  justify-content-center align-items-center flex-column p-4 gap-2">
          <div className="delete-icon-bg p-3 d-flex justify-content-center align-items-center">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 11C11.5523 11 12 11.4477 12 12V24C12 24.5523 11.5523 25 11 25C10.4477 25 10 24.5523 10 24V12C10 11.4477 10.4477 11 11 11Z" fill="#D83B01" />
              <path d="M16 11C16.5523 11 17 11.4477 17 12V24C17 24.5523 16.5523 25 16 25C15.4477 25 15 24.5523 15 24V12C15 11.4477 15.4477 11 16 11Z" fill="#D83B01" />
              <path d="M22 12C22 11.4477 21.5523 11 21 11C20.4477 11 20 11.4477 20 12V24C20 24.5523 20.4477 25 21 25C21.5523 25 22 24.5523 22 24V12Z" fill="#D83B01" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M29 6C29 7.10457 28.1046 8 27 8H26V26C26 28.2091 24.2091 30 22 30H10C7.79086 30 6 28.2091 6 26V8H5C3.89543 8 3 7.10457 3 6V4C3 2.89543 3.89543 2 5 2H12C12 0.895431 12.8954 0 14 0H18C19.1046 0 20 0.895431 20 2H27C28.1046 2 29 2.89543 29 4V6ZM8.23607 8L8 8.11803V26C8 27.1046 8.89543 28 10 28H22C23.1046 28 24 27.1046 24 26V8.11803L23.7639 8H8.23607ZM5 6V4H27V6H5Z" fill="#D83B01" />
            </svg>
          </div>
          <div className="d-head">
            Are you sure you want to delete restaurant?
          </div>
          <div className="d-caption">
            This action is permanent. Would you like to continue?
          </div>
        </div>
        <div className="dlt-dialog-btns d-flex justify-content-between align-items-center pl-4 pr-4">
          <Button
            label="No"
            onClick={() => setDeleteVisible(false)}
            className="no_btn_dlt"
          ></Button>
          <Button
            label="Yes, Delete"
            type="submit"
            onClick={handleDeleteRestaurant}
            className="yes_dlt_btn"
          ></Button>
        </div>

      </Dialog>
    </>
  );
};

export default Details;
