import React, { FunctionComponent} from 'react'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primeicons/primeicons.css";
import "./settingsTable.components.css"

type SettingsTableProps = {
  data:any, 
  first:any, 
  sortField:any, 
  sortOrder:any,
  // totalRecords:any, 
  onPage:any, 
  onSort:any, 
  onFilter:any, 
  filters:any,
  loading:any, 
  // countryBodyTemplate:any,
  // representativeBodyTemplate:any,
  imageBodyTemplate:any,
  actionBodyTemplate:any,
  statusBodyTemplate:any
  }


  const rowsNo = ()=>{
    var width = window.screen.width;
    
    if(width>=1920){
      return 5
    }else if(width>1420 && width<=1440 ){
      return 5
    }
    else{
      return 5
    }
  }


export const SettingsTable: FunctionComponent<SettingsTableProps> = ({ data, first, sortField,sortOrder, onPage, onSort, onFilter,filters, loading,actionBodyTemplate,statusBodyTemplate,imageBodyTemplate}) => 
  <DataTable 
  value={data} 
  lazy
  filterDisplay="row" 
  responsiveLayout="scroll" 
  paginator={data && data.length > 10} 
  first={first} 
  rows={rowsNo()} 
  onPage={onPage}
  onSort={onSort} 
  sortField={sortField} 
  sortOrder={sortOrder}
  onFilter={onFilter} 
  filters={filters} 
  loading={loading}
  >
  <Column field="name" header="Name"/>
  <Column field="type" header="Type" />
  <Column field="imageurl" header="Image" body={imageBodyTemplate} exportable={false} />
  <Column header="Status" body={statusBodyTemplate} exportable={false} style={{ minWidth: '3rem' }}></Column>
  {/* <Column header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: '3rem' }}></Column> */}

</DataTable>