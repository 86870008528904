import React, { useRef, useState } from 'react'
import { Image } from "primereact/image";
import "./storeImages.component.css"
import { devsas } from "../../config/constants"
import { Galleria } from 'primereact/galleria';


type props = {
  storeimages: string[];
  thumbnailimages: string[];
}

const StoreImages: React.FC<props> = ({ storeimages, thumbnailimages }) => {
  const galleriaThumbnail = useRef<Galleria>(null);
  const galleriaStore = useRef<Galleria>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const itemTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
  }

  const thumbnailTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ display: 'block' }} />;
  }

  return (
    <div className='d-flex flex-column gap-4 p-3'>
      <div className='d-flex flex-column gap-2'>
        <div className='picheads-store'>
          Thumbnail Photos ({thumbnailimages.length})
        </div>
        <div className='d-flex flex-wrap pt-2 rest-img row'>
          <Galleria ref={galleriaThumbnail} value={thumbnailimages} numVisible={7} style={{ maxWidth: '850px' }}
            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
            fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
          <div className="d-flex">
            {
              thumbnailimages && thumbnailimages.map((image: any, index: any) => {
                let imgEl = <img src={image + "?" + devsas} alt={image.alt} style={{ cursor: 'pointer' }} onClick={
                  () => { setActiveIndex(index); galleriaThumbnail.current?.show() }
                } />
                return (
                  <div className="" key={index}>
                    {imgEl}
                  </div>
                )
              })
            }
          </div>
        </div>

      </div>
      <div className='d-flex flex-column gap-2'>
        <div className='picheads-store'>
          Organic Store Photos ({storeimages.length})
        </div>
        <div className='pt-2 '>
          <Galleria ref={galleriaStore} value={storeimages} numVisible={7} style={{ maxWidth: '850px' }}
            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
            fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
          <div className="image_gallery">
            {
              storeimages && storeimages.map((image: any, index: any) => {
                let imgEl = <img src={image + "?" + devsas} alt={image.alt} style={{ cursor: 'pointer' }} onClick={
                  () => { setActiveIndex(index); galleriaStore.current?.show() }
                } />
                return (
                  <div className="rest-img" key={index}>
                    {imgEl}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default StoreImages