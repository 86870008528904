import React, { FunctionComponent } from 'react'; // importing FunctionComponent
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primeicons/primeicons.css";
import "./approve-table.css";

type ApproveTableProps = {
    customers: any, 
    first: any, 
    sortField: any, 
    sortOrder: any,
    totalRecords: any, 
    onPage: any, 
    onSort: any, 
    onFilter: any, 
    filters: any,
    loading: any, 
    countryBodyTemplate: any,
    representativeBodyTemplate: any,
    actionBodyTemplate: any
};



const calculateColumnWidth = (numberOfColumns: number) => `${100 / numberOfColumns}%`;

export const ApproveTable: FunctionComponent<ApproveTableProps> = ({
    customers, 
    first, 
    sortField, 
    sortOrder, 
    totalRecords, 
    onPage, 
    onSort, 
    onFilter, 
    filters, 
    loading, 
    countryBodyTemplate, 
    representativeBodyTemplate, 
    actionBodyTemplate
}) => {
    const numberOfColumns = 5; 
    const columnWidth = calculateColumnWidth(numberOfColumns);
    return (
        <DataTable 
            className='pending-table-heads'
            value={customers} 
            lazy
            filterDisplay="row" 
            responsiveLayout="scroll" 
            paginator={totalRecords >= 10} 
            first={first} 
            rows={10} 
            totalRecords={totalRecords} 
            onPage={onPage}
            onSort={onSort} 
            sortField={sortField} 
            sortOrder={sortOrder}
            onFilter={onFilter} 
            filters={filters} 
            loading={loading}
        >
            <Column 
                field="name" 
                header="Restaurant Name" 
                sortable 
                filter 
                filterPlaceholder="Search by Name" 
                style={{ width: columnWidth }}
            />
            <Column 
                field="agentname" 
                header="Owner/Agent Name" 
                sortable 
                filter 
                filterPlaceholder="Search by Owner/Agent" 
                style={{ width: columnWidth }}
            />
            {/* <Column 
                field="accountholder" 
                header="Account Holder" 
                sortable 
                filter 
                filterPlaceholder="Search by Account Holder" 
                style={{ width: columnWidth }}
            /> */}
            <Column 
                field="city" 
                header="City" 
                sortable 
                filter 
                filterPlaceholder="Search by City" 
                style={{ width: columnWidth }}
            />
            <Column 
                body={actionBodyTemplate} 
                header="Action"   
                exportable={false} 
                style={{ minWidth: '6rem', width: columnWidth }}

            />
        </DataTable>
    );
};
