import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../../../components/ui/navbar/sidenav.components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { TabPanel, TabView } from 'primereact/tabview';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { EditStoreDetails, GetStorebyID } from '../../../api/organicStores.api';
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import DropFileInput from '../../../components/drop-file-input/drop-file-input';
import { sas } from '../../../config/constants';
import Loader from '../../../components/ui/loader/loader.components';

type resObj = {
  // lat: any;
  storename: string;
  address: string;
  // address2: string;
  city: string;
  state: string;
  latitude: string;
  longitude: string;
  locality: string;
  country: string;
  pincode: string;
  website: string;
  email: string;
  phoneno: string;
  servicetype: string[];
  serviceoptions: string;
  timings: any[];
  district: string;
  thumbnails: any[];
  storeimages: any[];
  storesize: any[];
  storetype: string[];
  location: {
    coordinates: number[];
  };
  _id: string;
  facebookurl: string;
  instaurl: string;
  youtubeurl: string;
  twitterurl: string;
};
const initialState = {
  _id: "",
  storename: "",
  address: "",
  city: "",
  state: "",
  latitude: "",
  longitude: "",
  locality: "",
  country: "",
  pincode: "",
  website: "",
  email: "",
  phoneno: "",
  servicetype: [],
  serviceoptions: "",
  timings: [],
  district: "",
  storeimages: [],
  thumbnails: [],
  storesize: [],
  storetype: [],
  location: {
    coordinates: [0, 0],
  },
  facebookurl: "",
  instaurl: "",
  youtubeurl: "",
  twitterurl: "",
};

const EditStore = () => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [storeObj, setStoretObj] = useState<resObj>(initialState);
  const [isAdding, setIsadding] = useState(false);
  const [servicestype, setServicestype] = useState<string[]>([]);
  const [storetype, setStoretype] = useState<string[]>([]);
  const [totimes, settotimes] = useState();
  const [fromtimes, setfromtimes] = useState();
  const [storeThumbnailImages, setStoreThumbnailImages] = useState<any[]>([]);
  const [storeImages, setStoreImages] = useState<any[]>([]);

  const toast = useRef<Toast>(null);
  const { id, type } = useParams();
  const navigate = useNavigate();

  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };
  const getFormErrorMessage = (name: string) => {
    const errorObject = errors[name as keyof typeof defaultValues];

    return errorObject ? (
      <small className="p-error">{errorObject.message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const Timings = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "01:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
    "11:59pm",
    "12:00am",
  ];
  const daysOfWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const initialSelectedTimings = {};
  const [selectedTimings, setSelectedTimings] = useState<
    Record<string, string[]>
  >(initialSelectedTimings);

  const handleAddTiming = () => {
    if (fromtimes && totimes) {
      const selectedDays = Object.keys(checkedDays).filter(
        (day) => checkedDays[day]
      );

      setSelectedTimings((prevSelectedTimings) => {
        const updatedTimings = { ...prevSelectedTimings };

        selectedDays.forEach((dayKey) => {
          const existingTimings = updatedTimings[dayKey] || [];
          const newTiming = `${fromtimes} to ${totimes}`;
          updatedTimings[dayKey] = [...existingTimings, newTiming];
        });

        console.log("selectedTimings", updatedTimings);
        // setResObj({ ...resObj, timings: updatedTimings });
        return updatedTimings;
      });
    }
  };

  const [checkedDays, setCheckedDays] = useState(() => {
    const initialCheckedState: Record<string, boolean> = {};
    daysOfWeek.forEach((day) => {
      initialCheckedState[day] = false;
    });
    return initialCheckedState;
  });

  const handleCheckboxChange = (day: string) => {
    setCheckedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  const onFromTimeChange = (e: any) => {
    setfromtimes(e.value);
  };

  const onToTimeChange = (e: any) => {
    settotimes(e.value);
  };

  const defaultValues = {
    restaurantname: "",
    latitude: "",
    longitude: "",
    address: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
    specialitem: "",
    average: "",
    eaterytype: "",
    foodtype: "",
    ambience: "",
    aboutrestaurant: "",
    whyyeldam: "",
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });
  const loadLazyData = () => {
    setIsadding(true);

    const loadLazyTimeout = setTimeout(async () => {
      try {

        const storeData = await GetStorebyID(id as string);
        console.log("storeData", storeData.data.data);

        if (storeData.data.data) {
          const storeDetails = storeData.data.data;

          setStoretObj({
            ...storeDetails,
            latitude: storeDetails.location?.coordinates[1]?.toString() || "",
            longitude: storeDetails.location?.coordinates[0]?.toString() || ""
          });
        }

        setIsadding(false);
      } catch (error) {
        console.error("Error loading data:", error);
        setIsadding(false);
      }
    },);
  };

  useEffect(() => {
    setIsadding(true);
    loadLazyData();
  }, []);

  const Servicestyps = (e: any) => {
    let _servicestypes = [...servicestype];

    if (e.checked) _servicestypes.push(e.value);
    else _servicestypes.splice(_servicestypes.indexOf(e.value), 1);

    setServicestype(_servicestypes);
    setStoretObj({ ...storeObj, servicetype: _servicestypes });
  };

  const Storetypes = (e: any) => {
    let _Storetypes = [...storetype];

    if (e.checked) _Storetypes.push(e.value);
    else _Storetypes.splice(_Storetypes.indexOf(e.value), 1);

    setStoretype(_Storetypes);
    setStoretObj({ ...storeObj, storetype: _Storetypes });
  };
  const validateFields = (): boolean => {
    const show = (message: any, severity: any, summary: any) => {
      if (toast.current) {
        toast.current.show({
          severity: severity,
          summary: summary,
          detail: message,
        });
      }
    };

    if (activeIndex === 0) {
      if (
        storeObj.storename.length === 0 ||
        storeObj.latitude.length === 0 ||
        storeObj.longitude.length === 0 ||
        storeObj.address.length === 0 ||
        storeObj.locality.length === 0 ||
        storeObj.city.length === 0 ||
        storeObj.district.length === 0 ||
        storeObj.state.length === 0 ||
        storeObj.country.length === 0 ||
        storeObj.pincode.length === 0

      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }

    }
    else if (activeIndex === 1) {
      if (
        storeObj.storetype.length === 0 ||
        storeObj.servicetype.length === 0
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }

    }
    else if (activeIndex === 3) {
      if (
        storeObj.thumbnails.length === 0 ||
        storeObj.storeimages.length === 0
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }

    }

    return true;
  };
  const fetchAddress = async (lat: any, long: any) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBadx8pwSuzfCbHeQaRPa36kaOFhMj7KrQ`
      );
      const data = await response.json();
      console.log(data);

      let address = '';
      let district = '';
      let city = '';
      let state = '';
      let pincode = '';
      let countryName = '';
      let locality = '';

      data.results.forEach((result: any) => {
        result.address_components.forEach((component: any) => {
          if (component.types.includes('street_number') || component.types.includes('route') || component.types.includes('street_address')) {
            address += component.long_name + ', ';
          } else if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
          } else if (component.types.includes('administrative_area_level_3')) {
            district = component.long_name;
          } else if (component.types.includes('postal_code')) {
            pincode = component.long_name;
          } else if (component.types.includes('country')) {
            countryName = component.long_name;
          }

          else if (component.types.includes('sublocality_level_1') || component.types.includes('sublocality_level_2') || component.types.includes('neighborhood')) {
            locality = component.long_name;
          }
        });
      });


      locality = locality || address.slice(0, -2);

      setStoretObj({
        ...storeObj,
        locality: locality,
        district: district,
        city: city,
        state: state,
        pincode: pincode,
        country: countryName,
      });

    } catch (error) {
      console.error("Error fetching city:", error);
    }
  };

  const handleNext = () => {
    console.log("clicked next");
    if (storeThumbnailImages.length > 5) {
      show(
        "You can upload up to 5 thumbnail images only.",
        "error",
        "Limit Exceeded"
      );
      return; 
    }

    if (storeImages.length > 25) {
      show("You can upload up to 25 organic store images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }
    if (!validateFields()) {
      return;
    }
    setActiveIndex(prevIndex => (prevIndex + 1) % 5);
  };

  const handleBack = () => {
    setActiveIndex(prevIndex => (prevIndex - 1) % 5);
  };

  const handleEditStore = async () => {

    if (storeThumbnailImages.length > 5) {
      show(
        "You can upload up to 5 thumbnail images only.",
        "error",
        "Limit Exceeded"
      );
      return; 
    }

    if (storeImages.length > 25) {
      show("You can upload up to 25 organic store images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }
    if (!validateFields()) {
      return;
    }

    setIsadding(true);

    if (
      storeObj.storename.length === 0 ||
      storeObj.latitude.length === 0 ||
      storeObj.longitude.length === 0 ||
      storeObj.address.length === 0 ||
      storeObj.locality.length === 0 ||
      storeObj.city.length === 0 ||
      storeObj.district.length === 0 ||
      storeObj.state.length === 0 ||
      storeObj.country.length === 0 ||
      storeObj.pincode.length === 0 ||
      storeObj.storetype.length === 0 ||
      storeObj.servicetype.length === 0 ||
      storeObj.thumbnails.length === 0 ||
      storeObj.storeimages.length === 0

    ) {
      show("Please check all Mandatory fields", "warn", "Warn");
      setIsadding(false)
      return;
    }
    if (storeObj) {

      const updatedStoreObj = {
        ...storeObj,
        location: {
          coordinates: [
            parseFloat(storeObj.longitude) || 0,
            parseFloat(storeObj.latitude) || 0,
          ],
        },
      };
      const formData = new FormData();
      // storetype
      updatedStoreObj.storetype.forEach((store: any, index: number) => {
        formData.append(`storetype[${index}]`, store);
      });
      // servicetype
      updatedStoreObj.servicetype.forEach((service: any, index: number) => {
        formData.append(`servicetype[${index}]`, service);
      });
      // store size 
      updatedStoreObj.storesize.forEach((size: any, index: number) => {
        formData.append(`storesize [${index}]`, size);
      })

      // basic details
      formData.append("storename", updatedStoreObj.storename);
      formData.append("phoneno", updatedStoreObj.phoneno);
      formData.append("email", updatedStoreObj.email);
      formData.append("website", updatedStoreObj.website);
      formData.append("facebookurl", updatedStoreObj.facebookurl);
      formData.append("instaurl", updatedStoreObj.instaurl);
      formData.append("youtubeurl", updatedStoreObj.youtubeurl)
      formData.append("twitterurl", updatedStoreObj.twitterurl);
      formData.append("address", updatedStoreObj.address);
      formData.append("longitude", updatedStoreObj.longitude);
      formData.append("latitude", updatedStoreObj.latitude);
      formData.append("locality", updatedStoreObj.locality);
      formData.append("city", updatedStoreObj.city);
      formData.append("district", updatedStoreObj.district);
      formData.append("state", updatedStoreObj.state);
      formData.append("country", updatedStoreObj.country);
      formData.append("pincode", updatedStoreObj.pincode);

      updatedStoreObj.storeimages.forEach((storeimages: File | string) => {
        if (typeof storeimages === "string") {
            formData.append("existingImageURLs", storeimages);
        } else {
            formData.append("storeimages", storeimages);
        }
    });

    // Images (files or URLs)
    updatedStoreObj.thumbnails.forEach((thumbnails: File | string) => {
        if (typeof thumbnails === "string") {
            formData.append("existingThumbnailURLs", thumbnails);
        } else {
            formData.append("thumbnails", thumbnails);
        }
    });

      // timings
      if (JSON.stringify(selectedTimings) !== JSON.stringify(initialSelectedTimings)) {
        formData.append("timings", JSON.stringify(selectedTimings));
      }
      console.log("Updated Store Object:", updatedStoreObj);
      try {
        const response = await EditStoreDetails(updatedStoreObj._id, formData);
        console.log("API Response:", response); 
        const currentPath = window.location.pathname;
        const productId = updatedStoreObj._id;

        if (response.data && response.data.message === "Organic store succesfully updated.") {
          show("Organic Store Updated Successfully", "success", "Success");

          let targetUrl = '';
          setTimeout(async () => {
            console.log('currentPath',currentPath);
            if (currentPath.includes("/pendingstores")) {
              targetUrl = `/organicstores/pendingstores/details/${productId}`;
            } else if (currentPath.includes("/approvedstores")) {
              targetUrl = `/organicstores/approvedstores/details/${productId}`;
            } else if (currentPath.includes("/rejectedstores")) {
              targetUrl = `/organicstores/rejectedstores/details/${productId}`;
            }

            if (targetUrl) {
              console.log("target URL clicked");
              navigate(targetUrl);
              setIsadding(false);

            }
            setIsadding(false);

          }, 2000);


        } else {
          setIsadding(false);
          show("Invalid Access", "error", "Error");
          console.log("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("Error updating store:", error);
        setIsadding(false);

      }

    }

  };

  const handleDeleteClick = (index: any, purpose: any) => {
    const updatedRestObj = { ...storeObj };
    console.log("updatedRestObj", updatedRestObj);
    if (purpose === "ThumbnailImages") {
      const updatedThumbnails = [...storeObj.thumbnails];
      updatedThumbnails.splice(index, 1);
      updatedRestObj.thumbnails = updatedThumbnails;
    } else if (purpose === "Storeimages") {
      const updatedImages = [...updatedRestObj.storeimages];
      updatedImages.splice(index, 1);
      updatedRestObj.storeimages = updatedImages;
    }

    setStoretObj(updatedRestObj);
  };
  const handleEditClick = () => {
    const fromPath = location.state?.from;

    console.log("Current Path:", window.location.pathname);
    console.log("From Path:", fromPath);

    if (fromPath) {

      navigate(fromPath);
    } else {
      const currentPath = window.location.pathname;
      if (currentPath.includes("/pending")) {
        navigate(`/organicstores/pendingstores/details/${id}`);
      } else if (currentPath.includes("/approved")) {
        navigate(`/organicstores/approvedstores/details/${id}`);
      } else if (currentPath.includes("/rejected")) {
        navigate(`/organicstores/rejectedstores/details/${id}`);
      } else {

        navigate('/');
      }
    }
  };

  return (
    <>
      {!isAdding ? "" : <Loader />}
      <main>
        <div>
          <Navbar />
          <section
            className="page-content pr-0 pb-0"
          >
            <div className='pl-5'>
              <p className='m-0 edit-info-heading'
                onClick={handleEditClick}><i className="pi pi-arrow-left mr-2"></i>Edit Organic Store</p>
            </div>
            <hr style={{ color: "#ececec", opacity: "100%" }} />
            <Toast ref={toast}></Toast>
            <div className='edit-background mx-1'>
              <TabView
                className='editing-business'
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
              >
                <TabPanel header={"Organic Store Details"}>
                  <>
                    <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", backgroundColor: "#FAFAFA" }}>
                      <div className='col-12 p-3 d-flex flex-column gap-5'>
                        <div className='col-12 p-2 d-flex flex-column gap-4'>
                          <div className='d-flex'>
                            <div className="col-8 py-0 flex flex-column email-entry">
                              <div className="row">
                                <Controller
                                  name="restaurantname"
                                  control={control}
                                  rules={{
                                    required: "Organicstore name  is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="edit-details-head mb-1">
                                        Organic Store Name{" "}
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="flex flex-column">
                                        <InputText
                                          placeholder="Enter Organic Store Name"
                                          id={field.name}
                                          value={storeObj.storename}
                                          className={classNames({
                                            "p-invalid": fieldState.error,
                                          })}
                                          onChange={(e) => {
                                            let value = e.target.value;
                                            value = value.trimStart();
                                            field.onChange(e);
                                            setStoretObj({
                                              ...storeObj,
                                              storename: value,
                                            });
                                          }}
                                        />
                                      </div>
                                      {getFormErrorMessage(field.name)}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-4 py-0 flex flex-column email-entry">
                              <div className="row">
                                <label className="edit-details-head mb-1">
                                  Phone Number
                                </label>
                                <div className="flex flex-column">
                                  <InputText
                                    id="phoneno"
                                    className="no-spinner"
                                    maxLength={14}
                                    value={storeObj.phoneno}
                                    onChange={(e: any) => {
                                      const rawValue = e.target.value;
                                      const trimmedValue = rawValue.trimStart();
                                      setStoretObj({
                                        ...storeObj,
                                        phoneno: trimmedValue,
                                      });
                                    }}
                                    placeholder="Enter Phone Number"
                                    aria-describedby="username-help"
                                    keyfilter={/^[0-9+\-\s]*$/}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='d-flex'>
                            <div className="col-4 py-0 flex flex-column email-entry">
                              <div className="row">
                                <label className="edit-details-head mb-1">
                                  Email
                                </label>

                                <div className="flex flex-column gap-2">
                                  <InputText
                                    id="email"
                                    value={storeObj.email}
                                    placeholder="Enter Email Address"
                                    aria-describedby="username-help"
                                    onChange={(e: any) => {
                                      const rawValue = e.target.value;
                                      const trimmedValue = rawValue.trimStart();
                                      setStoretObj({
                                        ...storeObj,
                                        email: trimmedValue,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="col-4 py-0 flex flex-column email-entry">
                              <label className="edit-details-head mb-1">WebSite</label>
                              <InputText
                                id="website"
                                value={storeObj.website}
                                placeholder="Enter Website URL"
                                aria-describedby="username-help"
                                onChange={(e: any) => {
                                  const rawValue = e.target.value;
                                  const trimmedValue = rawValue.trimStart();
                                  setStoretObj({

                                    ...storeObj,
                                    website: trimmedValue,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-4 py-0 flex flex-column email-entry">
                              <label className="edit-details-head mb-1">Instagram</label>
                              <InputText
                                placeholder="Enter Instagram URL"
                                value={storeObj.instaurl}
                                className="col-12"
                                onChange={(e) => {
                                  let value = e.target.value.trimStart();
                                  setStoretObj({
                                    ...storeObj,
                                    instaurl: value.length > 0 ? value : '',
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className='d-flex'>
                            <div className="col-4 py-0 flex flex-column email-entry">
                              <label className="edit-details-head mb-1">Facebook</label>
                              <InputText
                                placeholder="Enter Facebook URL"
                                value={storeObj.facebookurl}
                                className="col-12"
                                onChange={(e) => {
                                  let value = e.target.value.trimStart();
                                  setStoretObj({
                                    ...storeObj,
                                    facebookurl: value.length > 0 ? value : '',
                                  });
                                }}
                              />
                            </div>


                            <div className="col-4 py-0 flex flex-column email-entry">
                              <label className="edit-details-head mb-1">X</label>
                              <InputText
                                placeholder="Enter X URL"
                                className="col-12"
                                value={storeObj.twitterurl}
                                onChange={(e) => {
                                  let value = e.target.value.trimStart();
                                  setStoretObj({
                                    ...storeObj,
                                    twitterurl: value.length > 0 ? value : '',
                                  });
                                }}
                              />
                            </div>
                            <div className="col-4 py-0 flex flex-column email-entry">
                              <label className="edit-details-head mb-1">Youtube</label>
                              <InputText
                                placeholder="Enter Youtube URL"
                                className="col-12"
                                value={storeObj.youtubeurl}
                                onChange={(e) => {
                                  let value = e.target.value.trimStart();
                                  setStoretObj({
                                    ...storeObj,
                                    youtubeurl: value.length > 0 ? value : '',
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className='col-12 py-0 head-contact'>Location</div>
                          <div className='d-flex align-items-center'>
                            <div className="col-4 py-0 flex flex-column email-entry">
                              <Controller
                                name="latitude"
                                control={control}
                                rules={{
                                  required: "Latitude is required.",
                                  pattern: {
                                    value: /^[+-]?([0-9]*[.]?[0-9]+)$/,
                                    message: "Enter a valid latitude with only numbers and dots.",
                                  },
                                }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <div className="d-flex align-items-baseline">
                                      <label className="edit-details-head mb-1">
                                        Latitude
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                    </div>
                                    <div className="flex flex-column">
                                      <InputText
                                        maxLength={30}
                                        placeholder="Enter a valid latitude"
                                        id={field.name}
                                        value={storeObj.latitude}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                        onChange={(e) => {
                                          const value = e.target.value.trim();
                                          const latitude = value === "" ? null : parseFloat(value);
                                          field.onChange(e);
                                          setStoretObj({
                                            ...storeObj,
                                            latitude: value,
                                            location: {
                                              ...storeObj.location,
                                              coordinates: [
                                                storeObj.location.coordinates[0],
                                                latitude !== null ? latitude : 0,
                                              ],
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            </div>
                            <div className="col-4 py-0 flex flex-column email-entry">
                              <Controller
                                name="longitude"
                                control={control}
                                rules={{
                                  required: "Longitude is required.",
                                  pattern: {
                                    value: /^[+-]?([0-9]*[.]?[0-9]+)$/,
                                    message: "Enter a valid longitude with only numbers and dots.",
                                  },
                                }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <div className="d-flex align-items-baseline">
                                      <label className="edit-details-head mb-1">
                                        Longitude
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                    </div>
                                    <div className="flex flex-column">
                                      <InputText
                                        maxLength={30}
                                        placeholder="Enter a valid longitude"
                                        id={field.name}
                                        value={storeObj.longitude}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                        onChange={(e) => {
                                          const value = e.target.value.trim();
                                          const longitude = value === "" ? null : parseFloat(value);
                                          field.onChange(e);
                                          setStoretObj({
                                            ...storeObj,
                                            longitude: value,
                                            location: {
                                              ...storeObj.location,
                                              coordinates: [
                                                longitude !== null ? longitude : 0,
                                                storeObj.location.coordinates[1],
                                              ],
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            </div>
                            <div className='col-1'>
                              <Button
                                label='Get Address'
                                className='getData_btn'
                                onClick={() => fetchAddress(storeObj.latitude, storeObj.longitude)}
                              />
                            </div>
                          </div>

                          <div className="col-12 py-0 flex flex-column email-entry">
                            <Controller
                              name="address"
                              control={control}
                              rules={{ required: "Address  is required." }}
                              render={({ field, fieldState }) => (
                                <>
                                  <label className="edit-details-head mb-1">
                                    Address <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputTextarea
                                      maxLength={130}
                                      id={field.name}
                                      {...field}
                                      placeholder="Enter Address Line"
                                      value={storeObj.address}
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart();
                                        if (trimmedValue) {
                                          field.onChange(e);
                                          setStoretObj({
                                            ...storeObj,
                                            address: trimmedValue,
                                          });
                                        } else {
                                          setStoretObj({
                                            ...storeObj,
                                            address: '',
                                          });
                                        }
                                      }}
                                      rows={5}
                                      cols={30}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>


                          <div className='d-flex'>
                            <div className="col-4 py-0 flex flex-column email-entry">
                              <label className="edit-details-head mb-1">Locality <b style={{ color: 'red' }}>*</b></label>
                              <InputText
                                placeholder="Enter Locality"
                                value={storeObj.locality}
                                className="col-12"
                                onChange={(e) => {
                                  const rawValue = e.target.value;
                                  const trimmedValue = rawValue.trimStart();
                                  if (trimmedValue) {
                                    setStoretObj({
                                      ...storeObj,
                                      locality: trimmedValue,
                                    });
                                  } else {
                                    setStoretObj({
                                      ...storeObj,
                                      locality: '',
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-4 py-0 flex flex-column email-entry">
                              <Controller
                                name="city"
                                control={control}
                                rules={{ required: "city is required." }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <label className="edit-details-head mb-1">
                                      City<b style={{ color: "red" }}>*</b>
                                    </label>

                                    <div className="flex flex-column">
                                      <InputText
                                        maxLength={30}
                                        id={field.name}
                                        value={storeObj.city}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                        placeholder="Enter City"
                                        onChange={(e) => {
                                          const rawValue = e.target.value;
                                          const trimmedValue = rawValue.trimStart();
                                          if (trimmedValue) {
                                            setStoretObj({
                                              ...storeObj,
                                              city: trimmedValue,
                                            });
                                          } else {
                                            setStoretObj({
                                              ...storeObj,
                                              city: '', // Set to empty if invalid
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />

                            </div>
                            <div className="col-4 py-0 flex flex-column email-entry">

                              <Controller
                                name="pincode"
                                control={control}
                                rules={{ required: "pincode  is required." }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <label className="edit-details-head mb-1">
                                      Pincode<b style={{ color: "red" }}>*</b>
                                    </label>
                                    <div className="flex flex-column">
                                      <InputText
                                        maxLength={6}
                                        placeholder="Enter Pincode"
                                        id={field.name}
                                        value={storeObj.pincode}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                        onChange={(e) => {
                                          const rawValue = e.target.value;

                                          const trimmedValue = rawValue.trimStart();
                                          if (/^\d{0,6}$/.test(trimmedValue)) {
                                            setStoretObj({
                                              ...storeObj,
                                              pincode: trimmedValue,
                                            });
                                          } else {
                                            setStoretObj({
                                              ...storeObj,
                                              pincode: '',
                                            });
                                          }
                                        }}
                                        keyfilter={/^[0-9]*$/}

                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />

                            </div>
                          </div>
                          <div className='d-flex'>
                            <div className="col-4 py-0 flex flex-column email-entry">

                              <Controller
                                name="district"
                                control={control}
                                rules={{ required: "district is required." }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <label className="edit-details-head mb-1">
                                      District
                                      <b style={{ color: "red" }}>*</b>
                                    </label>

                                    <div className="flex flex-column">
                                      <InputText
                                        maxLength={30}
                                        placeholder="Enter District"
                                        id={field.name}
                                        value={storeObj.district}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                        onChange={(e) => {
                                          const rawValue = e.target.value;
                                          const trimmedValue = rawValue.trimStart();
                                          if (trimmedValue) {
                                            setStoretObj({
                                              ...storeObj,
                                              district: trimmedValue,
                                            });
                                          } else {
                                            setStoretObj({
                                              ...storeObj,
                                              district: '',
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />

                            </div>


                            <div className="col-4 py-0 flex flex-column email-entry">

                              <Controller
                                name="state"
                                control={control}
                                rules={{ required: "state is required." }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <label className="edit-details-head mb-1">
                                      State<b style={{ color: "red" }}>*</b>
                                    </label>

                                    <div className="flex flex-column">
                                      <InputText
                                        maxLength={30}
                                        id={field.name}
                                        value={storeObj.state}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                        placeholder="Enter State"
                                        onChange={(e) => {
                                          const rawValue = e.target.value;
                                          const trimmedValue = rawValue.trimStart();
                                          if (trimmedValue) {
                                            setStoretObj({
                                              ...storeObj,
                                              state: trimmedValue,
                                            });
                                          } else {
                                            setStoretObj({
                                              ...storeObj,
                                              state: '',
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />

                            </div>
                            <div className="col-4 py-0 flex flex-column email-entry">

                              <Controller
                                name="country"
                                control={control}
                                rules={{ required: "country is required." }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <label className="edit-details-head mb-1">
                                      Country<b style={{ color: "red" }}>*</b>
                                    </label>

                                    <div className="flex flex-column">
                                      <InputText
                                        maxLength={30}
                                        id={field.name}
                                        value={storeObj.country}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                        placeholder="Entry Country"
                                        onChange={(e) => {
                                          const rawValue = e.target.value;
                                          const trimmedValue = rawValue.trimStart();
                                          if (trimmedValue) {
                                            setStoretObj({
                                              ...storeObj,
                                              country: trimmedValue,
                                            });
                                          } else {
                                            setStoretObj({
                                              ...storeObj,
                                              country: '',
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />

                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="d-flex justify-content-end pr-5 pt-2 pb-0">
                        <div className="row">
                          <Button className="next" type="submit" onClick={handleNext}>
                            Next <i className="pi pi-angle-right ml-1"></i>
                          </Button>
                        </div>
                      </div>
                    </ScrollPanel>
                  </>
                </TabPanel>
                <TabPanel header={"Service Options"}>
                  <>
                    <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", height: "calc(100vh - 24vh)", backgroundColor: "#FAFAFA" }}>
                      <div className='col-12 p-3 d-flex flex-column gap-5'>
                        <div className="row">
                          <label className="edit-details-head mb-2">
                            Store Type<b style={{ color: "red" }}>*</b>

                          </label>
                          <div className="d-flex flex-wrap gap-4">
                            <div className="">
                              <div className="flex align-items-center gap-2">
                                <Checkbox
                                  inputId="storetype1"
                                  name="storetype"
                                  value="Groceries"
                                  onChange={Storetypes}
                                  checked={(storeObj.storetype as string[]).includes("Groceries")}
                                />
                                <label htmlFor="preference1" className="">
                                  Groceries
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center">
                                <Checkbox
                                  inputId="storetype2"
                                  name="storetype"
                                  value="Vegetables"
                                  onChange={Storetypes}
                                  checked={(storeObj.storetype as string[]).includes("Vegetables")}
                                />
                                <label htmlFor="preference2" className="ml-2">
                                  Vegetables
                                </label>
                              </div>
                            </div>

                            <div className="">
                              <div className="flex align-items-center">
                                <Checkbox
                                  inputId="storetype3"
                                  name="storetype"
                                  value="Fruits"
                                  onChange={Storetypes}
                                  checked={(storeObj.storetype as string[]).includes("Fruits")}
                                />
                                <label htmlFor="storetype3" className="ml-2">
                                  Fruits
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center">
                                <Checkbox
                                  inputId="storetype3"
                                  name="storetype"
                                  value="Dairy"
                                  onChange={Storetypes}
                                  checked={(storeObj.storetype as string[]).includes("Dairy")}
                                />
                                <label htmlFor="storetype4" className="ml-2">
                                  Dairy
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center">
                                <Checkbox
                                  inputId="storetype5"
                                  name="storetype"
                                  value="Nuts"
                                  onChange={Storetypes}
                                  checked={(storeObj.storetype as string[]).includes("Nuts")}
                                />
                                <label htmlFor="storetype5" className="ml-2">
                                  Nuts
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center">
                                <Checkbox
                                  inputId="storetype5"
                                  name="storetype"
                                  value="Eggs"
                                  onChange={Storetypes}
                                  checked={(storeObj.storetype as string[]).includes("Eggs")}
                                />
                                <label htmlFor="storetype5" className="ml-2">
                                Eggs
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center">
                                <Checkbox
                                  inputId="storetype5"
                                  name="storetype"
                                  value="Oils"
                                  onChange={Storetypes}
                                  checked={(storeObj.storetype as string[]).includes("Oils")}
                                />
                                <label htmlFor="storetype5" className="ml-2">
                                  Oils
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center">
                                <Checkbox
                                  inputId="storetype5"
                                  name="storetype"
                                  value="Millets"
                                  onChange={Storetypes}
                                  checked={(storeObj.storetype as string[]).includes("Millets")}
                                />
                                <label htmlFor="storetype5" className="ml-2">
                                  Millets
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <label className="edit-details-head mb-2">
                            Service Type<b style={{ color: "red" }}>*</b>
                          </label>
                          <div className="d-flex flex-wrap gap-4">
                            <div className="">
                              <div className="flex align-items-center gap-2">
                                <Checkbox
                                  inputId="service1"
                                  name="servicestype"
                                  value="Delivery"
                                  onChange={Servicestyps}
                                  checked={(storeObj.servicetype as string[]).includes("Delivery")}
                                />
                                <label htmlFor="service1" className="">
                                  Delivery
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center">
                                <Checkbox
                                  inputId="service2"
                                  name="servicestype"
                                  value="In-store Shopping"
                                  onChange={Servicestyps}
                                  checked={(storeObj.servicetype as string[]).includes("In-store Shopping")}
                                />
                                <label htmlFor="service2" className="ml-2">
                                  In-store Shopping
                                </label>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex align-items-center">
                                <Checkbox
                                  inputId="service3"
                                  name="servicestype"
                                  value="In-store Pickup"
                                  onChange={Servicestyps}
                                  checked={(storeObj.servicetype as string[]).includes("In-store Pickup")}
                                />
                                <label htmlFor="service3" className="ml-2">
                                  In-store Pickup
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ScrollPanel>
                    <div className="button-container">
                      <Button className="ym-add-button-previous" onClick={handleBack} >
                        <i className="pi pi-angle-left mr-1"></i>  Back
                      </Button>
                      <Button className="next" type="submit" onClick={handleNext}>
                        Next <i className="pi pi-angle-right ml-1"></i>
                      </Button>
                    </div>
                  </>

                </TabPanel>
                <TabPanel header={"Timings"}>
                  <>
                    <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", height: "calc(100vh - 24vh)", backgroundColor: "#FAFAFA" }}>
                      <>
                        <div className='col-12 p-3 d-flex flex-column gap-5'>
                          <div className="row ">
                            <div className="col-12">
                              <div className="child-lef t" id="Timings">
                                <label className="ym-input-label mb-2">
                                  Timings
                                </label>

                                <div className="">
                                  <div className="row">
                                    <div className="d-flex gap-6 mb-5">
                                      {daysOfWeek.map((day: string) => (
                                        <div className="" key={day}>
                                          <div className="d-flex align-items-center">
                                            <Checkbox
                                              inputId={`day${day}`}
                                              name="day"
                                              value={day}
                                              checked={checkedDays[day]}
                                              onChange={() => handleCheckboxChange(day)}
                                            />
                                            <label htmlFor={`day${day}`} className="ml-2 pref-label">
                                              {day}
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div className=" d-flex from-to gap-2 align-items-center mb-3">
                                      <div className="from d-flex flex-column col-lg-3">
                                        <span className="restaurant-name">From</span>
                                        <Dropdown
                                          style={{
                                            width: "100%",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: "white",
                                          }}
                                          id="eater"
                                          placeholder="Select"
                                          value={fromtimes}
                                          options={Timings}
                                          onChange={onFromTimeChange}
                                        />
                                      </div>
                                      <div className="from d-flex flex-column col-3">
                                        <span className="restaurant-name">To</span>
                                        <Dropdown
                                          style={{
                                            width: "100%",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: "white",
                                          }}
                                          id="eater"
                                          placeholder="Select"
                                          value={totimes}
                                          options={Timings}
                                          onChange={onToTimeChange}
                                        />
                                      </div>
                                      <div className="mt-4">
                                        <button
                                          className="add-button"
                                          onClick={handleAddTiming}
                                        >
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center mt-3">
                                      <div className="">
                                        {Object.entries(selectedTimings).map(
                                          ([day, timing]) => (
                                            <div
                                              className="Sunday-timing col-12 gap-3 py-0 d-flex align-items-center mt-3"
                                              key={day}
                                            >
                                              <div className="day col-2 p-0 ">{day}</div>
                                              <div className="col-10 d-flex align-items-center p-0">
                                                <div className="col gap-1">
                                                  {timing.map((time, index) => (
                                                    <div className="col d-flex align-items-center p-0 mt-1 gap-1">
                                                      <span className="col-8 time" key={index}>
                                                        {time}
                                                      </span>

                                                      <i
                                                        className="col-4 bi bi-trash-fill"
                                                        style={{
                                                          color: "#818181",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          const updatedTimings = {
                                                            ...selectedTimings,
                                                          };
                                                          updatedTimings[day] =
                                                            updatedTimings[day].filter(
                                                              (t) => t !== time
                                                            );

                                                          // Check if there are no times left, delete the entire day
                                                          if (
                                                            updatedTimings[day].length === 0
                                                          ) {
                                                            delete updatedTimings[day];
                                                          }

                                                          setSelectedTimings(updatedTimings);
                                                        }}
                                                      ></i>
                                                      <br />
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    {/* <div className="row d-flex justify-content-start align-items-center mt-3">
                                                            <RestaurantTimings
                                                                timing={resObj.timings}
                                                                editable={true}
                                                            />
                                                        </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </ScrollPanel>
                    <div className="button-container">
                      <Button className="ym-add-button-previous" onClick={handleBack} >
                        <i className="pi pi-angle-left mr-1"></i>  Back
                      </Button>
                      <Button className="next" type="submit" onClick={handleNext}>
                        Next <i className="pi pi-angle-right ml-1"></i>
                      </Button>
                    </div>
                  </>
                </TabPanel>
                <TabPanel header={"Photos"}>
                  <>
                    <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", height: "calc(100vh - 24vh)", backgroundColor: "#FAFAFA" }}>
                      <div className='col-12 p-3 d-flex flex-column gap-5'>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                            <div className='d-flex flex-column gap-5'>
                              <div className="">
                                <label className="edit-details-head mb-2">
                                  Thumbnail Images <b style={{ color: "red" }}>*</b> <span style={{fontSize:"12px"}}>(Add a min 1 or a max 5 Thumbnail Images)</span>
                                </label>
                                <div className="d-flex gap-4">

                                  <DropFileInput
                                    uploadedFiles={storeThumbnailImages}
                                    setUploadedFiles={setStoreThumbnailImages}
                                    resObj={storeObj}
                                    setResObj={setStoretObj}
                                    purpose="storeThumbnailImages"
                                  />

                                  <div className="grid mt-2 ml-1 gap-6">
                                    {Array.isArray(storeObj.thumbnails) &&
                                      storeObj.thumbnails.every(
                                        (item: any) => typeof item === "string"
                                      ) &&
                                      storeObj.thumbnails.map((item: any, index: any) => {
                                        return (
                                          <div
                                            id="Imagecrd"
                                            style={{ position: "relative" }}
                                            key={index}
                                          >
                                            <img
                                              className="uploadimage fefe"
                                              key={item}
                                              src={item + "?" + sas}
                                              alt=""
                                            />

                                            <i
                                              className="pi pi-trash icontrash"
                                              onClick={() =>
                                                handleDeleteClick(index, "ThumbnailImages")
                                              }
                                              style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                                margin: "8px",
                                              }}
                                            ></i>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <label className="edit-details-head mb-2">
                                  Organic Store Images
                                  <b style={{ color: "red" }}>*</b>
                                </label>
                                <div className="d-flex gap-4">
                                  <DropFileInput
                                    uploadedFiles={storeImages}
                                    setUploadedFiles={setStoreImages}
                                    resObj={storeObj}
                                    setResObj={setStoretObj}
                                    purpose="Storeimages"
                                  />

                                  <div className="grid mt-2 ml-1 gap-6">
                                    {Array.isArray(storeObj.storeimages) &&
                                      storeObj.storeimages.every(
                                        (item: any) => typeof item === "string"
                                      ) &&
                                      storeObj.storeimages.map((item: any, index: any) => {
                                        return (
                                          <div
                                            id="Imagecrd"
                                            style={{ position: "relative" }}
                                            key={index}
                                          >
                                            <img
                                              className="uploadimage fefe"
                                              key={item}
                                              src={item + "?" + sas}
                                              alt=""
                                            />

                                            <i
                                              className="pi pi-trash icontrash"
                                              onClick={() =>
                                                handleDeleteClick(index, "Storeimages")
                                              }
                                              style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                                margin: "8px",
                                              }}
                                            ></i>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ScrollPanel>
                        </div>
                      </div>
                    </ScrollPanel>
                    <div className="button-container">
                      <Button className="ym-add-button-previous" onClick={handleBack} >
                        <i className="pi pi-angle-left mr-1"></i>  Back
                      </Button>
                      <Button
                        className="p-button-success ym-add-button"
                        label="Update"
                        onClick={handleEditStore}
                      ></Button>
                    </div>
                  </>

                </TabPanel>

              </TabView>
            </div>

        
          </section>
        </div>
      </main>
    </>
  );
}

export default EditStore;
