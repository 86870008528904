import HttpInstance from "./http.api";
type Healthtip={
    tipname:string,
    tipcategory:string,
    tipimages:string,
    tipstatus:string,
    description:string
}
export const AddHealthtips = async (resObj: any) => {
    const RespData = await HttpInstance.post("/healthtips/addhealthtips", resObj);
    return RespData.data;
  };

  export const GetHealthtips = async (reqData:any) => {

        const response = await HttpInstance.post("/healthtips/adminhealthtiptable", reqData);
        return response.data;
  }

  export const EditHealthtips = async (tipid: string, editObj: any) => {

      const response = await HttpInstance.patch(`/healthtips/edithealthtip?tipid=${tipid}`, editObj);
      return response;
  }

export const DeleteHealthtips = async (tipid: string) => {

        const response = await HttpInstance.delete(`/healthtips/deletehealthtip?tipid=${tipid}`);
        return response;
}

 export const GetHealthtipsbyID = (tipid: string) => {
    const RespData = HttpInstance.get("/healthtips/gethealthtipbyid?tipid=" + tipid);
    return RespData;
  };
  