import React from 'react';

interface props {
    clinicname: string;
    phone: string;
    email: string;
    address: string;
    locality: string;
    city: string;
    state: string;
    pincode: string;
    latitude: number;
    longitude: number;
    clinicwebsite: string;
    clinicinstagram: string;
    clinictwitter: string;
    clinicyoutube: string;
    clinicfacebook: string;
    district: string;
    country: string
}

const ClinicInfo: React.FC<props> = ({
    clinicname,
    phone,
    email,
    address,
    locality,
    city,
    state,
    pincode,
    latitude,
    longitude,
    clinicwebsite,
    clinicfacebook,
    clinicinstagram,
    clinictwitter,
    clinicyoutube,
    country,
    district
}) => {
    return (
        <>
            <div className='p-2'>
                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Clinic Name</h6>
                        <p className='sub-detail-data'>{clinicname ? clinicname : "--"}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Clinic Phone Number</h6>
                        <p className='sub-detail-data'>{phone ? phone : "--"}</p>
                    </div>
                    <div className='col-3'>
                        <h6 className="mb-1 sub-detail-head">Clinic Email</h6>
                        <p className='sub-detail-data'>{email ? email : "--"}</p>
                    </div>
                </div>

                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Website</h6>
                        <p className='sub-detail-data'>{clinicwebsite ? clinicwebsite : "--"}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Instagram</h6>
                        <p className='sub-detail-data'>{clinicinstagram ? clinicinstagram : "--"}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Youtube</h6>
                        <p className='sub-detail-data'>{clinicyoutube ? clinicyoutube : "--"}</p>
                    </div>
                </div>

                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Twitter</h6>
                        <p className='sub-detail-data'>{clinictwitter ? clinictwitter : "--"}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Facebook</h6>
                        <p className='sub-detail-data'>{clinicfacebook ? clinicfacebook : "--"}</p>
                    </div>
                </div>

                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Latitude & Longitude</h6>
                        <p className='sub-detail-data'>{latitude + " & " + longitude}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Clinic Address</h6>
                        <p className='sub-detail-data'>
                            {address ? address : "--"}
                        </p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Locality</h6>
                        <p className='sub-detail-data'>{locality ? locality : "--"}</p>
                    </div>
                </div>

                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Town/City</h6>
                        <p className='sub-detail-data'>{city ? city : "--"}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">District</h6>
                        <p className='sub-detail-data'>{district ? district : "--"}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">State</h6>
                        <p className='sub-detail-data'>{state ? state : "--"}</p>
                    </div>
                </div>
                <div className='d-flex'>
                <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Country</h6>
                        <p className='sub-detail-data'>{country ? country : "--"}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Pincode</h6>
                        <p className='sub-detail-data'>{pincode ? pincode : "--"}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ClinicInfo;
