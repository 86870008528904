import React from 'react';
import picture from "../../assets/images/img2.png"
import { Button } from 'primereact/button';
import { yeldamwebimagessas } from '../../config/constants';

type props = {
    rowData: any,
    setdeletevisible: any,
    seteditvisible: any,
    setviewvisible: any,
    onDelete:()=>void,
}

const Viewhealthtip: React.FC<props> = ({ rowData, setdeletevisible, seteditvisible, setviewvisible,onDelete }) => {
    const GetHealthtipbyid = async (tipid: string) => {
        const GetHealthtipByid = await GetHealthtipbyid(tipid);
    }

    return (
        <div className='d-flex justify-content-center flex-column gap-2 mt-3 '>
            <div className='tip-image-div d-flex justify-content-center'>
                <img className='tip-image' src={rowData.tipimages + "?" + yeldamwebimagessas} />
            </div>
            <div className='status-div'>
                {rowData.tipstatus && (
                    <span className='status-text'>{rowData.tipstatus}</span>
                )}
            </div>
            <div className='title-show'>
                <p className='head-htip'>Title</p>
                <p className='health-tip-text'>{rowData.tipname}</p>
            </div>
            <div>
                <p className='head-htip'>Description</p>
                <p className='health-tip-text'>{rowData.description}</p>
            </div>
            <div>
                <p className='head-htip'>Category</p>
                <p className='health-tip-text'>{rowData.tipcategory}</p>
            </div>
            <div className='d-flex justify-content-between'>
                <Button
                    className='edt-btn'
                    label='Edit'
                    onClick={() => {
                        seteditvisible(true);
                        setviewvisible(false);
                    }}
                >
                </Button>
                <Button
                    className='dlt-btn'
                    label='Delete'
                    onClick={() => {
                        setdeletevisible(true);
                        setviewvisible(false);
                        onDelete()
                    }}
                >
                </Button>
            </div>

        </div>

    );
}

export default Viewhealthtip;
