import React, { useState, useEffect, useRef } from 'react';
import { AgentsTable } from "../../components/agents/agent-table.components";
import Navbar from '../../components/ui/navbar/sidenav.components';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import "../agents/agents.css";
import { GetAllAgents, AgentStatus } from '../../api/agents.api';
import AddModal from '../../components/add-agent/add-agent';
import EditModal from '../../components/edit-agent/EditModal';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import Loader from '../../components/ui/loader/loader.components';

export default function Agents() {
    const [loading, setLoading] = useState(false);
    const [originalCustomers, setOriginalCustomers] = useState<any[]>([]); // Holds unfiltered data
    const [customers, setCustomers] = useState<any[]>([]); // Holds filtered data
    const [totalagentsRecords, setTotalagentsRecords] = useState(0);
    const [addShow, setAddShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [visible, setVisible] = useState(false);
    const [agentObj, setAgentObj] = useState({
        _id: "",
        name: "",
        email: "",
        city: "",
        mobile: "",
        status: false
    });
    const toast = useRef<Toast>(null);

    const width = window.screen.width;

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 15,
        page: 0,
        sortField: '',
        sortOrder: 0,
        filters: {
            name: { value: "", matchMode: "contains" },
            email: { value: '', matchMode: 'contains' },
            city: { value: '', matchMode: 'contains' },
            mobile: { value: '', matchMode: 'contains' },
        }
    });

    useEffect(() => {
        loadLazyData();
    }, []);

    const loadLazyData = async () => {
        setLoading(true);
        const respObj = await GetAllAgents();
        const fetchedData = respObj.data.data;

        setOriginalCustomers(fetchedData);
        setCustomers(fetchedData);
        setTotalagentsRecords(fetchedData.length);
        setLoading(false);
    };

    const onFilter = (event: any) => {
        const activeFilters = event.filters;
        let filteredData = [...originalCustomers];

        Object.keys(activeFilters).forEach((field) => {
            const filter = activeFilters[field];
            if (filter.value) {
                filteredData = filteredData.filter((item) =>
                    item[field]?.toString().toLowerCase().includes(filter.value.toLowerCase())
                );
            }
        });

        setCustomers(filteredData);
        setTotalagentsRecords(filteredData.length);
        setLazyParams({ ...lazyParams, filters: activeFilters });
    };
    console.log('Lazy Params on Mount:', lazyParams);

    const onPage = (event: any) => {
        setLazyParams(event);
    }

    const onSort = (event: any) => {
        setLazyParams(event);
    }
    
    
    const setEdit = (rowData: any) => setAgentObj(rowData);

    return (
        <>
            <main>
                <Navbar />
                <section className="page-content">
                    <div className="ym-content-table">
                        <div className="d-flex pb-2" style={{ borderBottom: "1px solid #ececec" }}>
                            <h1 className='ym-portal-heading p-2'>Agents</h1>
                            <Button
                                className="ml-auto add_agent_btn d-flex justify-content-center"
                                icon="pi pi-plus-circle"
                                label='Add Agent'
                                onClick={() => setAddShow(true)}
                            />

                        </div>
                        <div className="p-3">
                        {loading ? ( 
                  <Loader />
                ) : (
                            <AgentsTable
                                customers={customers}
                                first={lazyParams.first}
                                sortField={lazyParams.sortField}
                                sortOrder={lazyParams.sortOrder}
                                totalRecords={totalagentsRecords}
                                onPage={onPage}
                                onSort={onSort}
                                onFilter={onFilter}
                                filters={lazyParams.filters}
                                loading={loading}

                            />
                )}
                        </div>
                    </div>
                </section>
                <AddModal show={addShow} setShow={setAddShow} />
                {/* <EditModal show={editShow} setShow={setEditShow} id={agentObj._id} name={agentObj.name} city={agentObj.city} mobile={agentObj.mobile} email={agentObj.email} /> */}
            </main>
        </>
    );
}
