import axios from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from "../config/constants";

export const GetOrganicStoresTableData = async (params:any,approvalstatus:number)=>{
    const RespData= await HttpInstance.post("/organicstore/adminstoretable",{...params,approvalstatus:approvalstatus})
    console.log(RespData.data);
    return RespData.data;
}
export const GetStorebyID = (id:string) =>{
    const RespData = HttpInstance.get("/organicstore/getstorebyid?id="+id)
    return RespData
 }
 
 export const EditStoreDetails = async (id:string, formData:any) => {
    try {
      const response = await axios.patch(
        `${apiUrl}/organicstore/updatestore?id=${id}`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  export const DeleteStoreByID = (id:string) =>{
    const RespData=HttpInstance.delete("/organicstore/deleteorganicstore?id="+id)
    return RespData
    }