import React, { useState } from 'react';
import "./consent.css";

const Consent: React.FC<{ status: string }> = ({ status }) => {
    const [timestamps, setTimestamps] = useState<string[]>([]);

    const handleResend = () => {
        const now = new Date();
        const formattedDate = `${now.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} | ${now.toLocaleTimeString('en-US', { hour12: false })}`;
        setTimestamps([...timestamps, formattedDate]);
    };

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'Accepted':
                return 'accepted-status';
            case 'Pending':
                return 'pending-status';
            case 'Denied':
                return 'denied-status';
            default:
                return '';
        }
    };

    return (
        <>
            <div className='d-flex p-2'>
                <div className='col-4'>
                    <div>
                        <h6 className="mb-1 sub-detail-head">Status</h6>
                        <p className={`${getStatusClass(status)}`}>
                            Consent {status}
                        </p>
                    </div>
                    <div className='pt-4'>
                        <h6 className="mb-1 sub-detail-head">Consent Sent On:</h6>
                        {timestamps.map((timestamp, index) => (
                            <p key={index} className='consent-sent-timing'>{timestamp}</p>
                        ))}
                        <button className='btn_resend' onClick={handleResend}>
                            <i className="pi pi-envelope mr-1" style={{ fontSize: "12px" }}></i> Re-send Consent
                        </button>
                    </div>
                </div>
                <div className='col-4'>
                    <div>
                        <h6 className="mb-1 sub-detail-head">Captured Details:</h6>
                    </div>
                    {status === 'Accepted' ? (
                        <div className='dietitian-access-data d-flex flex-column gap-4 mt-3'>
                            <div className='dietitian-name-consent'>
                                <p className='dietitian-name-consent-head'>Dietitian Name:</p>
                                <p className='dietitian-name-consent-value'>Vinod Kumar</p>
                            </div>
                            <div className='dietitian-name-consent'>
                                <p className='dietitian-name-consent-head'>Web Application Name:</p>
                                <p className='dietitian-name-consent-value'>Yeldam</p>
                            </div>
                            <div className='dietitian-name-consent'>
                                <p className='dietitian-name-consent-head'>IP Address:</p>
                                <p className='dietitian-name-consent-value'>IPv4: 192.168.1.1</p>
                            </div>
                            <div className='dietitian-name-consent'>
                                <p className='dietitian-name-consent-head'>Timestamp:</p>
                                <p className='dietitian-name-consent-value'>June 24, 2024 | 15:42:18</p>
                            </div>
                        </div>
                    ) : (
                        <p className='no-data'>--</p>
                    )}
                </div>
            </div>
        </>
    );
}

export default Consent;
