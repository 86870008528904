import React, { FunctionComponent } from 'react'; // importing FunctionComponent
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primeicons/primeicons.css";

type PendingTableProps = {
    customers: any,
    first: any,
    sortField: any,
    sortOrder: any,
    totalRecords: any,
    onPage: any,
    onSort: any,
    onFilter: any,
    filters: any,
    loading: any,
    countryBodyTemplate: any,
    representativeBodyTemplate: any,
    actionBodyTemplate: any
};

const calculateColumnWidth = (numberOfColumns: number) => `${100 / numberOfColumns}%`;

export const PendingDietitianTable: FunctionComponent<PendingTableProps> = ({
    customers,
    first,
    sortField,
    sortOrder,
    totalRecords,
    onPage,
    onSort,
    onFilter,
    filters,
    loading,
    countryBodyTemplate,
    representativeBodyTemplate,
    actionBodyTemplate
}) => {
    const numberOfColumns = 7;
    const columnWidth = calculateColumnWidth(numberOfColumns);

    return (
        <DataTable
            className='pending-table-heads'
            value={customers}
            lazy
            filterDisplay="row"
            responsiveLayout="scroll"
            paginator={totalRecords >= 10}
            first={first}
            rows={10}
            totalRecords={totalRecords}
            onPage={onPage}
            onSort={onSort}
            sortField={sortField}
            sortOrder={sortOrder}
            onFilter={onFilter}
            filters={filters}
            loading={loading}
        >
            <Column
                field="dietitianname"
                header="Dietitian Name"
                sortable
                filter
                filterPlaceholder="Search by Name"
                style={{ width: columnWidth }}
            />
            <Column
                field="agentname"
                header="Owner/Agent Name"
                sortable
                filter
                filterPlaceholder="Search by Owner/Agent"
                style={{ width: columnWidth }}
            />
            {/* <Column
                field="accountholder"
                sortable
                header="Account Holder"
                filterField="accountholder"
                filter
                filterPlaceholder="Search by Account Holder"
                style={{ width: columnWidth }}
            /> */}

            <Column
                field="city"
                sortable
                header="City"
                filter
                filterPlaceholder="Search by City"
                style={{ width: columnWidth }}
            />
            <Column
                field="profession"
                header="Profession"
                sortable
                body={(rowData) => {
                    if (Array.isArray(rowData.profession) && rowData.profession.length > 1) {
                        return rowData.profession.join(' / ');
                    }
                    return rowData.profession;
                }}
                style={{ width: columnWidth }}
            />
            {/* <Column 
                field='consentstatus' 
                header='Status' 
                style={{ width: columnWidth }} 
            /> */}
            <Column
                body={actionBodyTemplate}
                header="Action"
                className='action_btn'
                exportable={false}
                style={{ minWidth: '6rem', width: columnWidth }}
            />
        </DataTable>
    );
};
