import HttpInstance from "./http.api";

export const GetTableData = (params:any,approvalstatus:number)=>{
   const RespData= HttpInstance.post("/restaurant/tablefilter",{...params,approvalstatus:approvalstatus})
   return RespData;
}

export const GetRestByID = (id:string)=>{
    const RespData=HttpInstance.get("/restaurant/getrestaurantbyid?id="+id);
    return RespData;
}
export const DeleteRestByID = (id:string) =>{
const RespData=HttpInstance.delete("/restaurant/deleterestaurant?id="+id)
return RespData
}