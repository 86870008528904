import React from 'react';
import { Image } from "primereact/image";
import { devsas } from "../../../config/constants"

interface props {
    degrees: {
        degree: string;
        college: string;
        yearOfCompletion: string;
    }[];
    certification: string;
    membership: string[];
    awardsandrecognitions: string[];
    certificationimages: string[];
}
const EducationalInfo: React.FC<props> = ({ degrees, certification, membership, awardsandrecognitions, certificationimages }) => {
    console.log("cer", certificationimages);
    return (
        <>
            <div className='p-2'>
                {degrees.map((edu, index) => (
                    <div key={index} className="d-flex">
                        <div className="col-4">
                            <h6 className="mb-1 sub-detail-head">Degree</h6>
                            <p className="sub-detail-data">{edu.degree ? edu.degree : "--"}</p>
                        </div>
                        <div className="col-4">
                            <h6 className="mb-1 sub-detail-head">College</h6>
                            <p className="sub-detail-data">{edu.college ? edu.college : "--"}</p>
                        </div>
                        <div className="col-3">
                            <h6 className="mb-1 sub-detail-head">Year of Completion</h6>
                            <p className="sub-detail-data">{edu.yearOfCompletion ? edu.yearOfCompletion : "--"}</p>
                        </div>
                    </div>
                ))}
                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Certification</h6>
                        <p className='sub-detail-data'>{certification ? certification : "--"}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Memberships</h6>
                        <p className='sub-detail-data'>{membership.join(",") ? membership : "--"}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Awards & Recognitions</h6>
                        <p className='sub-detail-data'>{awardsandrecognitions.join(", ") ? awardsandrecognitions : "--"}</p>
                    </div>
                </div>

                <div className="col-5">
                    <h6 className="mb-2 sub-detail-head">Medical Registration Proof</h6>
                    {certificationimages.length > 0 ? (
                        <Image className='medical-proof-image' src={certificationimages[0] + "?" + devsas} />
                    ) : (
                        <span>--</span>
                    )}
                </div>

            </div>
        </>
    );
}

export default EducationalInfo;
