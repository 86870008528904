import React, { useRef } from "react";
import "./drop-file-input.css";
import { Toast } from "primereact/toast";
import { devsas } from "../../config/constants";

const DropFileInput: React.FC<any> = ({
  uploadedFiles,
  setUploadedFiles,
  resObj,
  setResObj,
  purpose,
}) => {
  const toast = useRef<Toast>(null);
  const handleUploadFiles = (files: File[]) => {
    if (purpose === "ThumbnailImages") {
      setResObj({
        ...resObj,
        thumbnails: files,
      });
    }
    if (purpose === "RestaurantImages") {
      setResObj({
        ...resObj,
        images: files,
      });
    }
    if (purpose === "MenuImages") {
      setResObj({
        ...resObj,
        menuimages: files,
      });
    }
    if (purpose === "storeThumbnailImages") {
      setResObj({ ...resObj, thumbnails: files });
    }
    if (purpose === "Storeimages") {
      setResObj({ ...resObj, storeimages: files });
    }
    if (purpose === "medicalregistrationprooffile") {
      setResObj({
        ...resObj,
        certificationimages: files,
      });
    }

    if (purpose === "DietitianImage") {
      setResObj({
        ...resObj,
        dietitianimages: files,
      });
    }

    if (purpose === "clinicimages") {
      setResObj({
        ...resObj,
        clinicimages: files,
      });
    }

    console.log('Uploaded Files:', files);
    setUploadedFiles(files);
  };



  const handleFileEvent = (e: any) => {

    const show = (message: any, severity: any, summary: any) => {
      console.log("Outside of toast");
      if (toast.current) {
        console.log("going in toast");
        toast.current.show({
          severity: severity,
          summary: summary,
          detail: message,
        });
      } else {
        console.log("Toast not available");
      }
    };

    const chosenFiles = Array.prototype.slice.call(e.target.files);
    const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    const validFiles = chosenFiles.filter(file => allowedTypes.includes(file.type));
    const invalidFiles = chosenFiles.filter(file => !allowedTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      show(
        `The following files are not allowed: ${invalidFiles.map(file => file.name).join(', ')}`,
        'error',
        'Invalid Files'
      );
    }
    handleUploadFiles(validFiles);
  };



  const fileRemove = (fileToRemove: any) => {
    const updatedFiles = uploadedFiles.filter(
      (item: any) => item !== fileToRemove
    );
    setUploadedFiles(updatedFiles);

    // Update the resObj based on the current purpose
    if (purpose === "ThumbnailImages") {
      setResObj({
        ...resObj,
        thumbnails: updatedFiles,
      });
    } else if (purpose === "RestaurantImages") {
      setResObj({
        ...resObj,
        images: updatedFiles,
      });
    } else if (purpose === "MenuImages") {
      setResObj({
        ...resObj,
        menuimages: updatedFiles,
      });
    } else if (purpose === "storeThumbnailImages") {
      setResObj({ ...resObj, thumbnails: updatedFiles });
    } else if (purpose === "Storeimages") {
      setResObj({ ...resObj, storeimages: updatedFiles });
    } else if (purpose === "medicalregistrationprooffile") {
      setResObj({
        ...resObj,
        certificationimages: updatedFiles,
      });
    } else if (purpose === "DietitianImage") {
      setResObj({
        ...resObj,
        dietitianimages: updatedFiles,
      });
    } else if (purpose === "clinicimages") {
      setResObj({
        ...resObj,
        clinicimages: updatedFiles,
      });
    }
  };

  function isNullOrEmpty(array: any) {
    return array === null || array === undefined || array.length === 0;
  }


  const handleDeleteClick = (index: any, purpose: any) => {
    const updatedRestObj = { ...resObj };
    console.log("updatedRestObj", updatedRestObj);
    if (purpose === "ThumbnailImages") {
      const updatedThumbnails = [...resObj.thumbnails];
      updatedThumbnails.splice(index, 1);
      updatedRestObj.thumbnails = updatedThumbnails;
    } else if (purpose === "RestaurantImages") {
      const updatedImages = [...updatedRestObj.images];
      updatedImages.splice(index, 1);
      updatedRestObj.images = updatedImages;
    } else if (purpose === "MenuImages") {
      const updatedMenuImages = [...updatedRestObj.menuimages];
      updatedMenuImages.splice(index, 1);
      updatedRestObj.menuimages = updatedMenuImages;
    }
    setResObj(updatedRestObj);


  };

  return (
    <>
      <Toast ref={toast} />
      <div className="d-flex gap-4">
      <div className="col-2 upload-image-box m-2">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center mb-2">
            <i
              className="bi bi-cloud-upload"
              style={{
                color: "#3c3c3c",
                padding: "2px 8px 2px 8px",
                background: "#FFFFFF",
                borderRadius: "4px",
                border: "1px solid #ECECEC"
              }}
            ></i>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <label htmlFor={`upload-input-${purpose}`} className="upload-label">
              Click to upload
            </label>
          </div>
        </div>

        <input
          id={`upload-input-${purpose}`}
          type="file"
          value=""
          multiple
          onChange={handleFileEvent}
          accept="image/png,image/jpg,image/jpeg"

        />
      </div>

      <div className="d-flex flex-wrap mt-2  gap-6">

        {uploadedFiles.map((item: any, index: any) => {
          return (
            <div id="Imagecrd" style={{ position: "relative" }}>
              <img
                className="uploadimage"
                src={URL.createObjectURL(item)}
                alt=""
              />

              <i
                className="pi pi-trash icontrash"
                onClick={() => fileRemove(item)}
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  margin: "8px",
                }}
              ></i>
            </div>
          );
        })}
      </div>
      </div>


    </>
  );
};

export default DropFileInput;
