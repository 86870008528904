import React, { useRef, useState } from 'react'
import { devsas } from "../../config/constants"
import { Image } from "primereact/image";
import nomenu from "../../assets/images/nomenu.png";
import { Galleria } from 'primereact/galleria';

type props = {
  menuimages: string[];
}
const Restaurantmenu: React.FC<props> = ({
  menuimages
}) => {
  const galleria = useRef<Galleria>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const itemTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
  }

  const thumbnailTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ display: 'block' }} />;
  }

  return (
    <div>
      {/* <h6>Menu</h6> */}
      <div className="d-flex p-3">
        {menuimages?.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center gap-3">
            <img src={nomenu} alt="No Menu" className="nomenu-image" style={{ width: "200px", height: "200px" }} />
            <p className="span-data" >No Menu Available</p>
          </div>
        ) : (
          <div className='pt-2'>
            <Galleria ref={galleria} value={menuimages} numVisible={7} style={{ maxWidth: '850px' }}
              activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
              fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
            <div className="image_gallery">
              {
                menuimages && menuimages.map((image: any, index: any) => {
                  let imgEl = <img src={image + "?" + devsas} alt={image.alt} style={{ cursor: 'pointer' }} onClick={
                    () => { setActiveIndex(index); galleria.current?.show() }
                  } />
                  return (
                    <div className="rest-img" key={index}>
                      {imgEl}
                    </div>
                  )
                })
              }
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Restaurantmenu
