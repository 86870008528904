import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useRef, useState } from 'react';
import "./HealthtipsPopups.component.css";
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { AddHealthtips } from '../../api/healthtips.api';
import { Toast } from 'primereact/toast';

type props = {
    setAddShow: any;
}

const Addhealthtip: React.FC<props> = ({ setAddShow }) => {

    const initialCategories = [
        'Diabetes',
        'Cardiovascular Disease',
        'Hypertension (High Blood Pressure)',
        'Obesity',
        'Asthma',
        'Cancer (General Prevention)',
        'Depression & Anxiety',
        'Kidney Chronic Disease',
        'Gastrointestinal Disorders',
        'Rheumatoid Arthritis'
    ];

    const [categorylist, setcategorylist] = useState(initialCategories);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [status, setStatus] = useState('Active');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const toast = useRef<Toast>(null);

    const handleCategoryChange = (e: any) => {
        setSelectedCategory(e.value);
    };
    const handleStatusChange = (e: any) => {
        setStatus(e.value);
    };
    const show = (message: any, severity: any, summary: any) => {
        if (toast.current) {
            toast.current.show({
                severity: severity,
                summary: summary,
                detail: message,
            });
        } else {
            console.log("toast issue");
        }
    };


    const handleAddhealthtip = async () => {
        try {
            // Validate mandatory fields
            if (!selectedCategory || !title.trim() || !description.trim() || !imageUrl.trim()) {
                show("Please check all mandatory fields", "warn", "Warning");
                return;
            }
    
            // API call to add health tip
            const res = await AddHealthtips({
                tipcategory: selectedCategory,
                tipname: title.trimStart(),
                description: description.trimStart(),
                tipimages: imageUrl.trimStart(),
                tipstatus: status,
            });
    
            // Show success message and reset form
            console.log("qwerty",res);
            show("Health Tip added successfully", "success", "Success");
            setSelectedCategory(null);
            setTitle('');
            setDescription('');
            setImageUrl('');
    
            // Close popup after a short delay
            setTimeout(() => {
                setAddShow(false);
                window.location.reload(); // Optional, reloads the page
            }, 2000);
        } catch (error) {
            console.error('Error adding health tip:', error);
            show("Error adding health tip", "error", "Error");
        }
    };
    


    const handleTitleChange = (event: any) => {
        const inputValue = event.target.value;
        setTitle(inputValue);
    };

    const maxTitleLength = 50;
    const titleLength = title.length;



    const handleDescriptionChange = (event: any) => {
        const inputValue = event.target.value;
        setDescription(inputValue);
    };
    const handleImageChange = (event: any) => {
        const inputValue = event.target.value;
        setImageUrl(inputValue);
    }

    const maxDescriptionLength = 150;
    const descriptionLength = description.length;

    return (
        <div className='d-flex flex-column gap-3 mt-3'>
            <Toast ref={toast}></Toast>
            <div className='title-input'>
                <div className='mb-1 d-flex justify-content-between'>
                    <label className='tips-page-head'>Title <b style={{ color: 'red' }}>*</b></label>
                    <span className='count-text'>
                        {titleLength}/{maxTitleLength}
                    </span>
                </div>
                <div className='flex flex-column'>
                    <InputText
                        placeholder='Enter Title'
                        maxLength={maxTitleLength}
                        value={title.trimStart()}
                        onChange={handleTitleChange}
                    />
                </div>
            </div>
            <div className='category-dropdown'>
                <div className='mb-1'>
                    <label className='tips-page-head'>
                        Category <b style={{ color: 'red' }}>*</b>
                    </label>
                </div>
                <div className="flex flex-column">
                    <Dropdown
                        placeholder="Select"
                        options={categorylist}
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                       
                    />
                </div>

            </div>
            <div className='desc-input'>
                <div className='mb-1 d-flex justify-content-between' >
                    <label className='tips-page-head'>Description <b style={{ color: 'red' }}>*</b></label>
                    <span className='count-text'>
                        {descriptionLength}/{maxDescriptionLength}
                    </span>
                </div>
                <div className='flex flex-column'>
                    <InputTextarea
                        maxLength={maxDescriptionLength}
                        placeholder='Enter description'
                        value={description.trimStart()}
                        onChange={handleDescriptionChange}
                        rows={3}
                        cols={30}
                    />

                </div>
            </div>
            <div className='image-input'>
                <div className='mb-1'>
                    <label className='tips-page-head'>
                        Image <b style={{ color: 'red' }}>*</b>
                    </label>
                </div>
                <div className="flex flex-column">
                    <InputText
                        placeholder="Enter image URL"
                        // maxLength={}
                        value={imageUrl.trimStart()}
                        onChange={handleImageChange}
                    />
                </div>

            </div>

            <div className='status-radio'>
                <div>
                    <label className='tips-page-head'>
                        Status
                    </label>
                </div>
                <div className="flex  gap-4">
                    <div className="p-field-radiobutton">
                        <RadioButton inputId="active" name="status" value="Active" onChange={handleStatusChange} checked={status === 'Active'} />
                        <label className='radio-check' htmlFor="active">Active</label>
                    </div>
                    <div className="p-field-radiobutton">
                        <RadioButton inputId="inactive" name="status" value="Inactive" onChange={handleStatusChange} checked={status === 'Inactive'} />
                        <label className='radio-check' htmlFor="inactive">Inactive</label>
                    </div>
                </div>

            </div>
            <hr style={{ color: "#f5f5f5", height: "1px", margin: "0.5rem" }} />
            <div className='d-flex justify-content-end'>
                <Button
                    className="tip-submit-button"
                    label="Add Health Tip"
                    onClick={handleAddhealthtip}
                ></Button>
            </div>
        </div>
    );
}

export default Addhealthtip;
