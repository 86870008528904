import { Skeleton } from 'primereact/skeleton';
import React, { useState } from 'react';

type props = {
    phoneno: string;
    email: string;
    website: string;
    facebookurl: string;
    instaurl: string;
    twitterurl: string;
    youtubeurl: string;
    latitude: number;
    longitude: number;
}

const RestaurantInfo: React.FC<props> = ({
    phoneno, email, website,  facebookurl, instaurl, twitterurl, youtubeurl, latitude, longitude
}) => {
    const [lvisible, setLvisible] = useState(false);

    return (
        <div className="Main">
            <div className="row gap-3 p-3">
                <div className='d-flex'>
                    <div className='col-lg-4'>
                        <h6 className="mb-1 sub-detail-head">Phone Number</h6>
                        <p className='sub-detail-data'>{phoneno ? phoneno : "--"}</p>
                    </div>
                    <div className='col-lg-4'>
                        <h6 className="mb-1 sub-detail-head">Email</h6>
                        <p className='sub-detail-data'>{email ? email : "--"}</p>
                    </div>
                    <div className='col-lg-4'>
                        <h6 className="mb-1 sub-detail-head">Website</h6>
                        <p className='sub-detail-data'>{website ? website : "--"}</p>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='col-lg-4'>
                        <h6 className="mb-1 sub-detail-head">Facebook</h6>
                        <p className='sub-detail-data'>{facebookurl ? facebookurl : "--"}</p>
                    </div>
                    <div className='col-lg-4'>
                        <h6 className="mb-1 sub-detail-head">Instagram</h6>
                        <p className='sub-detail-data'>{instaurl ? instaurl : "--"}</p>
                    </div>
                    <div className='col-lg-4'>
                        <h6 className="mb-1 sub-detail-head">X</h6>
                        <p className='sub-detail-data'>{twitterurl ? twitterurl : "--"}</p>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='col-lg-4'>
                        <h6 className="mb-1 sub-detail-head">Youtube</h6>
                        <p className='sub-detail-data'>{youtubeurl ? youtubeurl : "--"}</p>
                    </div>
                    <div className='col-lg-4'>
                        <h6 className="mb-1 sub-detail-head">Latitude & Longitude</h6>
                        <p className='sub-detail-data'>{latitude ? latitude : "--"} & {longitude ? longitude : "--"}</p>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default RestaurantInfo;
