import React, { FunctionComponent } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import "primeicons/primeicons.css";
import "../agents/agent-table.css";

type AgentsTableProps = {
    customers: any[];
    totalRecords: any,
    first: number;
    sortField: string;
    sortOrder: any;
    onPage: (event: any) => void;
    onSort: (event: any) => void;
    onFilter: (event: any) => void;
    filters: any;
    loading: boolean;

};

const width = window.screen.width;
const calculateColumnWidth = (numberOfColumns: number) => `${100 / numberOfColumns}%`;


export const AgentsTable: FunctionComponent<AgentsTableProps> = ({
    customers,
    totalRecords,
    first,
    sortField,
    sortOrder,
    onPage,
    onSort,
    onFilter,
    filters,
    loading,
}) => {
    const numberOfColumns = 4;
    const columnWidth = calculateColumnWidth(numberOfColumns);
    console.log("table total records", totalRecords);
    console.log("table first", first);
    return (
        <DataTable
        className='pending-table-heads'
            value={customers}
            lazy
            filterDisplay="row"
            responsiveLayout="scroll"
            paginator={totalRecords >= 10} 
            first={first}
            rows={100}
            onPage={onPage}
            onSort={onSort}
            sortField={sortField}
            sortOrder={sortOrder}
            onFilter={onFilter}
            filters={filters}
            loading={loading}
        >
            <Column field="name"
                header="Full Name"
                style={{ width: columnWidth }}
                filter
                filterPlaceholder="Search by Name"
            />
            <Column field="email"
                header="Email"
                style={{ width: columnWidth }}
                filter
                filterPlaceholder="Search by Email"
            />
            <Column field="mobile"
                header="Phone Number"
                filter
                filterPlaceholder="Search by PhoneNo"
                style={{ width: columnWidth }}
            />
            <Column field="city"
                header="City"
                filter
                style={{ width: columnWidth }}
                filterPlaceholder="Search by City" />
        </DataTable>

    );
};
