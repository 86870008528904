import React, { useRef, useState } from 'react'
import "./Restaurant.photos.component.css"
import { Image } from "primereact/image";
import { devsas } from "../../config/constants"
import { Galleria } from 'primereact/galleria';


type props = {
    images: string[];
    thumbnail: string[];
}

const Restaurantphotos: React.FC<props> = ({ images, thumbnail }) => {
    const galleriaThumbnail = useRef<Galleria>(null);
    const galleriaRestaurant = useRef<Galleria>(null);
        const [activeIndex, setActiveIndex] = useState<number>(0);

    const itemTemplate = (item: any) => {
        return <img src={item + "?" + devsas} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item: any) => {
        return <img src={item + "?" + devsas} alt={item.alt} style={{ display: 'block' }} />;
    }

    return (
        <div className='d-flex flex-column gap-4 p-3'>
            <div className='d-flex flex-column gap-2'>
                <div className='picheads-store'>
                    Thumbnail Photos ({thumbnail.length})
                </div>
                <div className='d-flex flex-wrap pt-2 rest-img row'>
                    <Galleria ref={galleriaThumbnail} value={thumbnail} numVisible={7} style={{ maxWidth: '850px' }}
                        activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                        fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
                    <div className="d-flex">
                        {
                            thumbnail && thumbnail.map((image: any, index: any) => {
                                let imgEl = <img src={image + "?" + devsas} alt={image.alt} style={{ cursor: 'pointer' }} onClick={
                                    () => { setActiveIndex(index); galleriaThumbnail.current?.show() }
                                } />
                                return (
                                    <div className="" key={index}>
                                        {imgEl}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>
            <div className='d-flex flex-column gap-2'>
                <div className='picheads-store'>
                    Restaurant Photos  ({images.length})
                </div>
                <div className='pt-2 '>
                    <Galleria ref={galleriaRestaurant} value={images} numVisible={7} style={{ maxWidth: '850px' }}
                        activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
                        fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
                    <div className="image_gallery">
                        {
                            images && images.map((image: any, index: any) => {
                                let imgEl = <img src={image + "?" + devsas} alt={image.alt} style={{ cursor: 'pointer' }} onClick={
                                    () => { setActiveIndex(index); galleriaRestaurant.current?.show() }
                                } />
                                return (
                                    <div className="rest-img" key={index}>
                                        {imgEl}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Restaurantphotos
