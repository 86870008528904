import axios from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from '../config/constants'

export const TotalRestaurants =() => {
    const respData = HttpInstance.get("/admindashboard/restaurantcount")
    return respData
}

export const TotalAgentsCount =()=> {
    const resdata = HttpInstance.get("/admindashboard/agentcount")
    return resdata
}
export const TotalRestautrantSummary =()=>{
    const summarydata = HttpInstance.get("/admindashboard/totalrestaurantsummary")
    return summarydata
}
export const AgentsWeeklyPerformance =()=>{
    const RespData = HttpInstance.get("/admindashboard/admintimedata",
    { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken') 
    } } 
    );
    return RespData
   
}
export const PopularCatogeriess =()=>{
    const RespData = HttpInstance.get("/admindashboard/popularcategorygraph",
    { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken') 
    } } 
    );
    return RespData

}
export const CuisinesChart =()=>{
    const RespData = HttpInstance.get("/admindashboard/cuisinegraphcount",
    { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken') 
    } } 
    );
 
    return RespData
}
export const weeklyapprovedrejectedbydate = async (startDate:any, endDate:any) => {
    try {
      const response = await HttpInstance.post("admindashboard/adminapdatabydate", {
        startDate: startDate,
        endDate: endDate,
      }, {
        headers: {
          'Accept': '*/*',
          'Authorization': "Bearer " + localStorage.getItem('accessToken')
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const WeeklyApprovedRejectedStores = async (startDate:any, endDate:any) => {
    try {
        const response = await HttpInstance.post("organicstore/adminstoreapprej", {
          startDate: startDate,
          endDate: endDate,
        }, {
          headers: {
            'Accept': '*/*',
            'Authorization': "Bearer " + localStorage.getItem('accessToken')
          }
        });
        return response.data;
      }
    catch (error) {
        throw error;
      }
  
    
}

export const WeeklyApprovedRejected =()=>{
    const RespData = HttpInstance.get("/admindashboard/adminapdata",
    { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken') 
    } } 
    );
    return RespData
}



export const RestaurantsAndUsersAdded =()=>{
    const RespData = HttpInstance.get("/admindashboard/weeklycounts",
    { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken')
    } }
    );
    return RespData
}
export const DashboardCards =()=>{

    const RespData = HttpInstance.get("/admindashboard/restaurantcount",
    { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken')
    } }
    );
    return RespData

}
export const StoresandEvents = () =>{
    const RespData = HttpInstance.get("/admindashboard/adminstoreeventcount",
    { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken')
    } }
    );
    return RespData
}
export const DietitianAdminCounts = () =>{
  const RespData = HttpInstance.get("/admindashboard/admindietitiancount",
    { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken')
    } }
    );
    return RespData
}


export const ReviewsperDay = ()=>{

    const RespData = HttpInstance.get("/admindashboard/reviewsperday",
 
    { headers: {
 
      'Accept': '*/*',
 
     'Authorization': "Bearer "+localStorage.getItem('accessToken')
 
    } }
 
    );
 
    return RespData
 
 }