import React, { useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { AddAgent } from "../../api/agents.api";
import "../add-agent/add-agent.css"
type props = {
  show: boolean;
  setShow: Function;
};
const AddModal: React.FC<props> = ({ show, setShow }) => {
  const toast = useRef<Toast>(null);
  const toastShow = (message: any) => {
    if (toast.current) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };
  // const formRef = useRef(null);
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format"),
    name: Yup.string()
      .trim("Name cannot include leading or trailing spaces")
      .required("Name is a required field"),
    mobile: Yup.string().required("Mobile number is required"),
    city: Yup.string().required("City is required"),
  });

  const AddFooter = (
    <div className="d-flex justify-content-end">
      <Button
        label="Add Agent"
        form="add"
        type="submit"
        className="p-button-rounded p-button-success save-btn-login"
      ></Button>
    </div>
  );
  const addAgent = async (values: any, resetForm: Function) => {
    console.log("Inside Add agent", values.name);
    try {
      const respData = await AddAgent(values);
      if (respData?.status === 200 || 201) {
        toastShow("Agent Added Successfully");
        setShow(false);
        refreshPage();
      }
    } catch (error: any) {
      console.log('error', JSON.stringify(error));
      resetForm()
      toastShow("Agent already existed");

    }

  };

  const refreshPage = () => {
    window.location.reload();
  }
  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Agent Details"
        visible={show}
        draggable={false}
        closeOnEscape={false}

        className="Agent-details-dialog"
        style={{ width: "25vw" }}
        onHide={() => setShow(false)}
        footer={AddFooter}
      >
        <Formik
          validationSchema={schema}
          initialValues={{ name: "", city: "", email: "", mobile: "" }}
          onSubmit={(values, { resetForm }) => {
            addAgent(values, resetForm);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <div className="justify-content-center">

              <form id="add" noValidate onSubmit={handleSubmit}>
                <div className="flex flex-column">
                  <label htmlFor="name" className="add-agent-header">Full Name <b style={{ color: "red" }}>*</b></label>
                  <InputText
                    id="name"
                    type="name"
                    className="add-agent-inputfield"
                    placeholder="Enter Name"
                    name="name"
                    value={values.name.trimStart()}
                    onChange={handleChange}
                  />
                  <p className="error">
                    {errors.name && touched.name && errors.name}
                  </p>
                </div>

                <div className="flex flex-column">
                  <label htmlFor="email" className="add-agent-header">Email <b style={{ color: "red" }}>*</b></label>
                  <InputText
                    id="email"
                    type="email"
                    className="add-agent-inputfield"
                    placeholder="Enter Email"
                    name="email"
                    value={values.email.trimStart()}
                    onChange={handleChange}
                  />
                  <p className="error">
                    {errors.email && touched.email && errors.email}
                  </p>
                </div>
                <div className="flex flex-column">
                  <label htmlFor="mobile" className="add-agent-header">Phone Number <b style={{ color: "red" }}>*</b></label>
                  <InputText
                    id="mobile"
                    placeholder="Enter Phone Number"
                    name="mobile"
                    className="add-agent-inputfield"
                    keyfilter={/^[0-9+\-\s]*$/}
                    value={values.mobile.trimStart()}
                    onChange={handleChange}
                  />
                  <p className="error">
                    {errors.mobile && touched.mobile && errors.mobile}
                  </p>
                </div>

                <div className="flex flex-column">
                  <label htmlFor="city" className="add-agent-header">City <b style={{ color: "red" }}>*</b></label>
                  <InputText
                    id="city"
                    className="add-agent-inputfield"
                    placeholder="Enter City"
                    name="city"
                    value={values.city.trimStart()}
                    onChange={handleChange}
                  />
                  <p className="error">
                    {errors.city && touched.city && errors.city}
                  </p>
                </div>
              </form>
            </div>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddModal;

