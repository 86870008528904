import React, { useEffect, useRef, useState } from 'react';
import "./dietitianDetails.css";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import Navbar from '../../../components/ui/navbar/sidenav.components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApproveDietitian, DeleteDietitianByID, GetDietitiandetailsbyID, RejectDietitian } from '../../../api/dietitians.api';
import { Skeleton } from 'primereact/skeleton';
import { Image } from "primereact/image";
import { devsas } from "../../../config/constants"
import DietitianInfo from '../../../components/Dietitians/DietitianInfo/dietitianInfo';
import EducationalInfo from '../../../components/Dietitians/EducationalInfo/educationalInfo';
import ClinicInfo from '../../../components/Dietitians/ClinicInfo/clinicInfo';
import DietitianTimings from '../../../components/Dietitians/DietitianTimings/dietitianTimings';
import ClinicAndDietitianImages from '../../../components/Dietitians/ClinicAndDietitianImages/clinicAndDietitianImages';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import Consent from '../../../components/Dietitians/DietitianConsent/consent';
import Loader from '../../../components/ui/loader/loader.components';
import rejectpic from "../../../assets/images/rejectpic.png";
import approvepic from "../../../assets/images/approvepic.png"

const DietitianDetails = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    console.log(splitLocation);
    const { id } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState<number>(0);
    const [visible, setVisible] = useState(false);
    const [lvisible, setLvisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [isAdding, setIsadding] = useState(false);
    const [deletevisible, setDeleteVisible] = useState<boolean>(false);
    const [dietitianIdToDelete, setDietitianIdToDelete] = useState<string | null>(null);
    const [customers, setCustomers] = useState<any[]>([]);


    const [commentObj, setCommentObj] = useState({
        comment: "",
        approvalstatus: "",
    });
    const DietitianStatus = async () => {

        setVisible(false);
    };

    const loadlazydata = () => {
        const loadLazyTimeout = setTimeout(async () => {
            setIsadding(true);
            const storeData = await GetDietitiandetailsbyID(id as string);

            console.log("storeData", storeData.data.data);

            setDietObj(storeData.data.data);
            setLvisible(false);
            setIsadding(false);
        }, 100);
    };
    useEffect(() => {
        setIsadding(true);
        loadlazydata();
        setLvisible(true);
    }, []);

    const [dietObj, setDietObj] = useState({
        about: '',
        agentname: "",
        approvalstatus: "",
        awards: [],
        certification: "",
        certificationimages: [],
        city: "",
        clinicaddress: "",
        clinicfacebookurl: "",
        clinicimages: [],
        clinicinstaurl: "",
        clinicname: "",
        clinicemail: "",
        clinicphoneno: "",
        clinictwitterurl: "",
        clinicwebsite: "",
        clinicyoutubeurl: "",
        comment: "",
        country: "",
        collage: "",
        createdAt: "",
        dateadded: "",
        dietitianfacebookurl: "",
        dietitianimages: [],
        dietitianinstaurl: "",
        dietitianname: "",
        dietitiantitle: "",
        dietitiantwitterurl: "",
        dietitianwebsite: "",
        dietitianyoutubeurl: "",
        district: "",
        degree: "",
        degrees: [
            {
                degree: '',
                college: '',
                yearOfCompletion: ''
            }
        ],
        email: "",
        experience: "",
        fees: "",
        gender: "",
        languages: [],
        locality: "",
        location: { coordinates: [0.0, 0.0] },
        mode: [],
        membership: [],
        phoneno: "",
        pincode: "",
        registereddietitian: "",
        idamembershipnumber: "",
        services: [],
        speciality: [],
        profession: [],
        timings: [],
        yearofcompletion: "",
        state: "",
        _id: ""
    });

    const toast = useRef<Toast>(null);
    const show = (message: any, severity: any, summary: any) => {
        if (toast.current) {
            toast.current.show({
                severity: severity,
                summary: summary,
                detail: message,
            });
        } else {
            console.log("toast issue");
        }
    };

    const onSubmit = async () => {

        if (status === 1) {
            const respData = await ApproveDietitian(id as string);
            show(respData.data.message, "info", "Info");
            console.log("status1");
        } else {
            console.log("status2");
            const respData = await RejectDietitian(id as string, {
                approvalstatus: 2,
                comment: commentObj.comment

            });
            console.log("API hitting for reject");
            show(respData.data.message, "info", "Info");
        }

        const { pathname } = location;
        const splitpath = pathname.split("/");
        const pathname_s = "/" + splitpath[1] + "/" + splitpath[2];
        navigate(pathname_s);
    };

    const [isTooltipVisible, setTooltipVisible] = useState(false);

    const toggleTooltip = () => {
        setTooltipVisible(!isTooltipVisible);
    };

    console.log("diettttt", dietObj);

  const footerContent = (
    <div className="d-flex justify-content-between">
      <Button
        label="cancel"
        onClick={() => {
          setVisible(false);
        }}
        className="p-button-outlined p-button-rounded cancel_btn"
      />
      {status === 0 ? (
        <Button
          label="Yes, Reject"
          className={commentObj.comment.length > 0 ? "yes_reject_btn" : "disabled_reject_btn"}
          onClick={() => {
            if (commentObj.comment.length > 0) {
              onSubmit();
              DietitianStatus();
            }
          }}
          disabled={commentObj.comment.length === 0}
          autoFocus
        />
      ) : (
        <Button
          label="Yes, Approve"
          className="p-button-rounded p-button-success change-btn-login"
          onClick={() => {
            onSubmit();
            DietitianStatus();
          }}
          autoFocus
        />
      )}

    </div>
  );

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!(event.target as HTMLElement).closest('.details-tooltip-box, .pi-ellipsis-v')) {
                setTooltipVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const openDeleteDialog = (dietitianId: string) => {
        setDietitianIdToDelete(dietitianId);
        setTooltipVisible(false);
        setDeleteVisible(true);
    };

    const handleDeleteDietitian = async () => {
        setDeleteVisible(false);
        if (dietitianIdToDelete) {
            setLvisible(true);
            try {
                const response = await DeleteDietitianByID(dietitianIdToDelete);
                if (response?.status === 200) {
                    show("Dietitian Deleted Successfully", "info", "Info");

                    setCustomers((prevCustomers: any[] | undefined) =>
                        prevCustomers ? prevCustomers.filter((customer: { _id: string }) => customer._id !== dietitianIdToDelete) : []
                    );

                    console.log("Iam hitting delete 200");
                    setTooltipVisible(false);
                    setTimeout(() => {
                        setLvisible(false);
                        const { pathname } = location;
                        const splitpath = pathname.split("/");
                        const pathname_s = "/" + splitpath[1] + "/" + splitpath[2];
                        navigate(pathname_s);

                    }, 2000);
                }
            } catch (error) {
                console.error("Error deleting store:", error);
            }
        }
    };

    const isRejectedDietitian = location.pathname.includes("/rejected");
    return (
        <>
            <main>
                <Toast ref={toast}></Toast>

                {!lvisible ? '' : <Loader />}
                <div>
                    <Navbar />
                    <section className="page-content">
                        <div className="ym-content-table mb-0 mt-0">
                            <div className="page_title d-flex">
                                <div className="p-2 d-flex">
                                    &nbsp;
                                    <span
                                        className="back_rest_btn d-flex gap-2 align-items-center"
                                        onClick={() => {
                                            const currentPath = window.location.pathname;
                                            if (currentPath.includes("/pending")) {
                                                navigate(`/dietitian/pendingdietitian`);
                                            } else if (currentPath.includes("/approved")) {
                                                navigate(`/dietitian/approveddietitian`);
                                            } else if (currentPath.includes("/rejected")) {
                                                navigate(`/dietitian/rejecteddietitian`);
                                            }
                                        }}
                                    >
                                        <i className="pi pi-arrow-left"></i>
                                        Dietitian Details
                                    </span>
                                </div>

                                {splitLocation[2] === "pendingdietitian" ? (
                                    <div className="ml-auto d-flex align-items-center gap-5">
                                        <Button
                                            label="Approve"
                                            icon="pi pi-check-circle"
                                            className="p-button-rounded p-button-success change-btn-login"
                                            onClick={() => {
                                                setVisible(true);
                                                setStatus(1);

                                            }}
                                            autoFocus
                                        />

                                        <Button
                                            label="Reject"
                                            icon="pi pi-times-circle"
                                            onClick={() => {
                                                setVisible(true);
                                                setStatus(0);
                                                setCommentObj({ ...commentObj, approvalstatus: "0" });
                                            }}
                                            className="p-button-outlined p-button-rounded p-button-danger reject_btn"
                                        />

                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
                                                <i className="pi pi-ellipsis-v"></i>
                                            </div>

                                            {isTooltipVisible && (
                                                <div className="details-tooltip-box mt-3">
                                                    <button className="tooltip-button mb-1"
                                                        onClick={() => {
                                                            const currentPath = window.location.pathname;
                                                            let dietitianType = 'pendingdietitian';

                                                            if (currentPath.includes("/approved")) {
                                                                dietitianType = 'approveddietitian';
                                                            }
                                                            if (currentPath.includes("/rejected")) {
                                                                dietitianType = 'rejecteddietitian';
                                                            }

                                                            navigate(`/dietitian/${dietitianType}/editdietitian/${id}`);
                                                        }}
                                                    >
                                                        <i className="pi  pi-pencil mr-2" style={{ fontSize: "12px" }}></i>Edit Dietitian
                                                    </button>

                                                    <button className="tooltip-button"
                                                        onClick={() => openDeleteDialog(dietObj._id)}
                                                    >
                                                        <i className="pi pi-trash mr-2" style={{ fontSize: "12px" }}></i>Delete Dietitian
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : splitLocation[2] === "approveddietitian" ? (
                                    <div className="ml-auto d-flex align-items-center gap-5">
                                        <Button
                                            label="Edit Dietitian"
                                            icon="pi pi-pencil"
                                            onClick={() => {
                                                const currentPath = window.location.pathname;
                                                let dietitianType = 'pendingdietitian';

                                                if (currentPath.includes("/approved")) {
                                                    dietitianType = 'approveddietitian';
                                                }
                                                if (currentPath.includes("/rejected")) {
                                                    dietitianType = 'rejecteddietitian';
                                                }

                                                navigate(`/dietitian/${dietitianType}/editdietitian/${id}`);
                                            }}
                                            className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                                        />

                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
                                                <i className="pi pi-ellipsis-v"></i>
                                            </div>

                                            {isTooltipVisible && (
                                                <div className="details-tooltip-box mt-3">
                                                    <button className="tooltip-button mb-1"
                                                        onClick={() => {
                                                            setVisible(true);
                                                            setStatus(0);
                                                            setCommentObj({ ...commentObj, approvalstatus: "0" });
                                                        }}
                                                    >
                                                        <i className="pi  pi-times-circle mr-2" style={{ fontSize: "12px" }}></i>Reject Dietitian
                                                    </button>
                                                    {/* <button className="tooltip-button" onClick={() => alert('Disable Restaurant')}>
                                                        <i className="pi pi-exclamation-triangle mr-2" style={{ fontSize: "12px" }}></i>Disable Dietitian
                                                    </button> */}
                                                    <button className="tooltip-button"
                                                        onClick={() => openDeleteDialog(dietObj._id)}
                                                    >
                                                        <i className="pi pi-trash mr-2" style={{ fontSize: "12px" }}></i>Delete Dietitian
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="ml-auto d-flex align-items-center gap-5">
                                        <Button
                                            label="Edit Dietitian"
                                            icon="pi pi-pencil"
                                            onClick={() => {
                                                const currentPath = window.location.pathname;
                                                let dietitianType = 'pendingdietitian';

                                                if (currentPath.includes("/approved")) {
                                                    dietitianType = 'approveddietitian';
                                                }
                                                if (currentPath.includes("/rejected")) {
                                                    dietitianType = 'rejecteddietitian';
                                                }

                                                navigate(`/dietitian/${dietitianType}/editdietitian/${id}`);
                                            }}
                                            className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                                        />

                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
                                                <i className="pi pi-ellipsis-v"></i>
                                            </div>

                                            {isTooltipVisible && (
                                                <div className="details-tooltip-box mt-3">
                                                    <button className="tooltip-button mb-1"
                                                        onClick={() => {
                                                            setVisible(true);
                                                            setStatus(1);
                                                            setCommentObj({ ...commentObj, approvalstatus: "1" });
                                                        }}
                                                    >
                                                        <i className="pi  pi-check-circle mr-2" style={{ fontSize: "12px" }}></i>Approve Dietitian
                                                    </button>
                                                    {/* <button className="tooltip-button" onClick={() => alert('Disable Restaurant')}>
                                                        <i className="pi pi-exclamation-triangle mr-2" style={{ fontSize: "12px" }}></i>Disable Dietitian
                                                    </button> */}
                                                    <button className="tooltip-button"
                                                        onClick={() => openDeleteDialog(dietObj._id)}
                                                    >
                                                        <i className="pi pi-trash mr-2" style={{ fontSize: "12px" }}></i>Delete Dietitian
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <hr />
                            <div className='ym-content-table d-flex'>
                            <div className="col-6 p-0">
                                <div className=" gap-3 d-flex align-items-center">
                                    <div className="row mb-1 view-gallery-cols mr-3" style={{ height: "160px", width: "160px" }}>
                                        <div className="pt-0 pl-0">

                                            <Image className='main-details-profile' src={dietObj.dietitianimages[0] + "?" + devsas} alt="Image" preview />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column gap-3">

                                        <div className="page-title">
                                            {!lvisible ? (
                                                <h1 className="ym-portal-heading">
                                                    {dietObj.dietitiantitle} {dietObj.dietitianname}
                                                </h1>
                                            ) : (
                                                <Skeleton width="10rem" className="mb-2"></Skeleton>
                                            )}
                                        </div>


                                        <div className="">
                                            {!lvisible ? (
                                                <div>
                                                    <p className="area-data mb-0">
                                                        {dietObj.gender}
                                                    </p>
                                                </div>
                                            ) : (
                                                <Skeleton width="5rem" className="mb-2"></Skeleton>
                                            )}
                                        </div>

                                        <div className="">
                                            {!lvisible ? (
                                                <div>
                                                    <p className="area-data mb-0">
                                                        {dietObj.profession.join('/')}
                                                    </p>

                                                </div>
                                            ) : (
                                                <Skeleton width="5rem" className="mb-2"></Skeleton>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {isRejectedDietitian && (
                                <div className='col-6 p-0'>
                                    <div className="">
                                        <div className="ambience">
                                            <h6 className="mb-2 rr-head">Rejection Reason</h6>
                                            <div className='reason-disc-box'>
                                                <span className="span-data">
                                                    {dietObj.comment ? dietObj.comment : "--"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        </div>

                        <div className="row">
                            <TabView>
                                <TabPanel header="Dietitian Details">
                                    <DietitianInfo
                                    experience={dietObj.experience}
                                    mode={dietObj.mode}
                                    fee={dietObj.fees}
                                    phone={dietObj.phoneno}
                                    email={dietObj.email}
                                    registereddietitian={dietObj.registereddietitian}
                                    languages={dietObj.languages}
                                    services={dietObj.services}
                                    specialities={dietObj.speciality}
                                    idamembershipnumber={dietObj.idamembershipnumber}
                                    about={dietObj.about}
                                    dietitianinstagram={dietObj.dietitianinstaurl}
                                    dietitiantwitter={dietObj.dietitiantwitterurl}
                                    dietitianyoutube={dietObj.dietitianyoutubeurl}
                                    dietitianfacebook={dietObj.dietitianfacebookurl}
                                    dietitianwebsite={dietObj.dietitianwebsite}

                                    />
                                </TabPanel>
                                <TabPanel header="Education Details">
                                    <EducationalInfo
                                        degrees={dietObj.degrees}
                                        certification={dietObj.certification}
                                        membership={dietObj.membership}
                                        awardsandrecognitions={dietObj.awards}
                                        certificationimages={dietObj.certificationimages}
                                    />
                                </TabPanel>
                                <TabPanel header="Clinic Details">
                                    <ClinicInfo
                                        clinicname={dietObj.clinicname}
                                        phone={dietObj.clinicphoneno}
                                        email={dietObj.clinicemail}
                                        address={dietObj.clinicaddress}
                                        locality={dietObj.locality}
                                        city={dietObj.city}
                                        state={dietObj.state}
                                        district={dietObj.district}
                                        country={dietObj.country}
                                        pincode={dietObj.pincode}
                                        latitude={dietObj.location.coordinates[1]}
                                        longitude={dietObj.location.coordinates[0]}
                                        clinicwebsite={dietObj.clinicwebsite}
                                        clinicinstagram={dietObj.clinicinstaurl}
                                        clinictwitter={dietObj.clinictwitterurl}
                                        clinicyoutube={dietObj.clinicyoutubeurl}
                                        clinicfacebook={dietObj.clinicfacebookurl}
                                    />
                                </TabPanel>
                                <TabPanel header="Timings">
                                    <DietitianTimings timings={dietObj.timings} />
                                </TabPanel>
                                <TabPanel header={`Photos (${(dietObj.clinicimages?.length || 0) + (dietObj.dietitianimages?.length || 0)})`}>
                                    <ClinicAndDietitianImages
                                        clinicimages={dietObj.clinicimages}
                                        dietitianimages={dietObj.dietitianimages}
                                    />
                                </TabPanel>
                                <TabPanel header="Consent">
                                    <Consent status={'Accepted'} />
                                </TabPanel>
                            </TabView>
                        </div>

                        <Dialog
                visible={visible}
                className="status_change_div"
                style={{ width: "20vw" }}
                onHide={() => {
                  setVisible(false);
                }}
                footer={footerContent}
              >
                {status === 0 ? (
                  <div className="d-flex justify-content-center mb-2">
                    <img className="aprjpic" src={rejectpic} />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center mb-2">
                    <img className="aprjpic" src={approvepic} />
                  </div>
                )
                }
                <p className="footer-text">
                  Are you sure you want to {status === 0 ? "reject" : "approve"} the dietitian?
                </p>

                {status === 0 ? (
                  <div className="reject-com-div">
                    <p className="reason_head">
                      Reason<b style={{ color: "red" }}>*</b>
                    </p>
                    <div className="row">
                      <div className="flex flex-column gap-2">
                        <InputTextarea
                          placeholder="Write here"
                          value={commentObj.comment}
                          rows={6}
                          onChange={(e: any) =>
                            setCommentObj({ ...commentObj, comment: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="approve-div">
                    This will make the dietitian visible to users. Please confirm.
                  </div>
                )}
              </Dialog>

                        
                    </section>

                </div>
            </main>
            <Dialog
                visible={deletevisible}
                closeOnEscape={false}
                style={{ width: "25vw" }}
                className="delete-model"
                breakpoints={{
                    "1920px": "50vw",
                    "1420px": "50vw",
                    "1120px": "50vw",
                    "1090px": "40vw",
                    "980px": "50vw",
                    "840px": "60vw",
                    "740px": "50vw",
                    "641px": "70vw",
                    "541px": "70vw",
                    "490px": "80vw",
                    "350px": "90vw",
                }}
                onHide={() => setDeleteVisible(false)}
            >
                <div className="d-flex  justify-content-center align-items-center flex-column p-4 gap-2">
                    <div className="delete-icon-bg p-3 d-flex justify-content-center align-items-center">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 11C11.5523 11 12 11.4477 12 12V24C12 24.5523 11.5523 25 11 25C10.4477 25 10 24.5523 10 24V12C10 11.4477 10.4477 11 11 11Z" fill="#D83B01" />
                            <path d="M16 11C16.5523 11 17 11.4477 17 12V24C17 24.5523 16.5523 25 16 25C15.4477 25 15 24.5523 15 24V12C15 11.4477 15.4477 11 16 11Z" fill="#D83B01" />
                            <path d="M22 12C22 11.4477 21.5523 11 21 11C20.4477 11 20 11.4477 20 12V24C20 24.5523 20.4477 25 21 25C21.5523 25 22 24.5523 22 24V12Z" fill="#D83B01" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M29 6C29 7.10457 28.1046 8 27 8H26V26C26 28.2091 24.2091 30 22 30H10C7.79086 30 6 28.2091 6 26V8H5C3.89543 8 3 7.10457 3 6V4C3 2.89543 3.89543 2 5 2H12C12 0.895431 12.8954 0 14 0H18C19.1046 0 20 0.895431 20 2H27C28.1046 2 29 2.89543 29 4V6ZM8.23607 8L8 8.11803V26C8 27.1046 8.89543 28 10 28H22C23.1046 28 24 27.1046 24 26V8.11803L23.7639 8H8.23607ZM5 6V4H27V6H5Z" fill="#D83B01" />
                        </svg>
                    </div>
                    <div className="d-head">
                        Are you sure you want to delete dietitian?
                    </div>
                    <div className="d-caption">
                        This action is permanent. Would you like to continue?
                    </div>
                </div>
                <div className="dlt-dialog-btns d-flex justify-content-between align-items-center pl-4 pr-4">
                    <Button
                        label="No"
                        onClick={() => setDeleteVisible(false)}
                        className="no_btn_dlt"
                    ></Button>
                    <Button
                        label="Yes, Delete"
                        type="submit"
                        onClick={handleDeleteDietitian}
                        className="yes_dlt_btn"
                    ></Button>
                </div>

            </Dialog>
        </>
    );
}

export default DietitianDetails;
