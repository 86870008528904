import axios from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from "../config/constants";

export const GetDietitianTableData = (params: any, approvalstatus: number) => {
    const RespData = HttpInstance.post("/dietitians/admindietitiantable", { ...params, approvalstatus });
    return RespData;
}

export const GetDietitiandetailsbyID = (id: string) => {
    const RespData = HttpInstance.get(`/dietitians/getdietitianbyid?id=${id}`);
    return RespData;
}

export const ApproveDietitian = (id: string) => {
    const RespData = HttpInstance.patch(`/dietitians/approvedietitian?id=${id}`, { approvalstatus: 1 });
    return RespData;
}

export const RejectDietitian = (id: string, data: any) => {
    const RespData = HttpInstance.patch(`/dietitians/approvedietitian?id=${id}`, data);
    return RespData;
}

export const EditDietitianSubmit = async (id: string, resObj: any) => {
    try {
        const response = await HttpInstance.patch(`/dietitians/updatedietitian?id=${id}`, resObj, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    } catch (error: any) {
        console.error("Error updating dietitian:", error.response.data);

        throw error;
    }
};

export const DeleteDietitianByID = (id: string) => {
    const RespData = HttpInstance.delete("/dietitians/deletedietitian?id=" + id)
    return RespData
}

// export const DietitianConsentSent = async(data:any) => {
//     const RespData = await HttpInstance.post("/dietitians/sendConsentEmail",data);
//     return RespData
// }