import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import "./login.page.css";
import { signIn, verifyOtp } from "../../api/auth.api";
import Loader from "../../components/ui/loader/loader.components";
import Blobimages from "../../assets/images/Blobimages/Blobimages";
import LoginImage from "../../assets/images/LoginImages.png";

import OtpInput from "react-otp-input";
import {
  IResolveParams,
  LoginSocialMicrosoft
} from "reactjs-social-login";
import { Dialog } from "primereact/dialog";
import { yeldamwebimagessas } from "../../config/constants";
import { Button } from "primereact/button";
export default function Login() {
  const toast = useRef<Toast>(null);
  const [visible, setVisible] = useState(false);
  const [isAdding, setIsadding] = useState(false); // Loader state..
  const [otpVisible, setOtpVisible] = useState(false);
  const [code, setCode] = useState("");
  const [userEmail, setUserEmail] = useState("");


  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  // Creating schema
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format"),
    password: Yup.string().required("Password is a required field"),
  });

  const header = (
    <div className="logo">
      <img alt="Logo" width={180} height={60} src={Blobimages.mainlogo} />
    </div>
  );

  const footer = (
    <div className="flex flex-wrap justify-content-end gap-2">
      {/* <p className="privacy">
           By logging in or Signing up, you agree to the YELDAM user Agreement Privacy policy and Cookie policy
      </p> */}
    </div>
  );
  const navigate = useNavigate();

  const handleOnClick = async () => {
    try {
      if (code.length === 6) {
        const responseobj = await verifyOtp({ otp: parseInt(code) });
        console.log("responseobj", responseobj);

        if (responseobj === 200) {
          setTimeout(() => {
            const isAuthenticated = !!localStorage.getItem("accessToken");
            setIsadding(true);
            console.log("responseobj obj", responseobj);

            if (isAuthenticated) {
              console.log("isAuthenticated obj", responseobj);
              window.location.reload();
              navigate("/dashboard");
              setIsadding(false);
            }
          }, 100);
        } else {
          show("Invalid OTP. Please try again.", "error", "OTP Verification Failed");
          setCode("");
        }
      } else {
        throw new Error("Invalid OTP format");
      }
    } catch (error) {
      console.log("error obj", error);
      setIsadding(false);
      show("Invalid Access", "error", "Error");
      window.location.reload();
    }
  };
  const handleChange = async (code: any) => {
    const regex = /^[0-9]*$/;
    if (regex.test(code)) {
      setCode(code);
    }
  }

  return (

    <>
      <Toast ref={toast}></Toast>

      {!isAdding ? "" : <Loader />}
      <div className="container-fluid vh-100">
        <div className="row main-login d-flex justify-content-center align-items-center vh-100">
          <div className="col-6 d-flex justify-content-center ">
            <div className="content-details d-flex flex-column pt-8 gap-6">
              <div className="image-section">
                <img
                  src={Blobimages.mainlogo + "?" + yeldamwebimagessas}
                  style={{ height: "50px", width: "150px" }}
                ></img>
              </div>

              {otpVisible ?

                <div className="d-flex mt-6">

                  <div className="col box p-2">
                    <div className="m-0 d-flex justify-content-center align-items-center flex-column ">
                      <div className="Otp-title">
                        Enter OTP
                      </div>
                    </div>
                    <div className="m-2 d-flex justify-content-center otp-subhead">
                      <p className="otp-head">
                        Please enter the One-Time Password to verify your account A One-Time Password has been sent to <b className="mail">{userEmail}</b>
                      </p>
                    </div>
                    <div className="mt-4 ml-2 d-flex justify-content-center" onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleOnClick();
                      }
                    }}>

                      <OtpInput
                        value={code}
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                        numInputs={6}
                        shouldAutoFocus={false}
                        inputStyle={{
                          border: "2px solid #c3c3c3",
                          borderRadius: "4px",
                          width: "44px",
                          marginRight: "10px",
                          height: "44px",
                          fontSize: "12px",
                          color: "#000",
                          fontWeight: "400",
                        }}


                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <div className="mt-4 d-flex justify-content-center">
                      <Button className="Val-btn" onClick={() => handleOnClick()}>
                        Validate
                      </Button>
                    </div>
                  </div>
                </div>
                :
                <div className="d-flex justify-content-center pt-6">
                  <div className="col box p-2">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <div className="admin">Admin Login</div>
                      {/* <div className="content">
                        sign in
                      </div> */}
                    </div>


                    <div className="login-buttons">
                      <div className="col box">
                        <LoginSocialMicrosoft
                          client_id={"7fc0abdb-b68c-4604-82b9-9f1d225ca151"}
                          redirect_uri={window.location.origin}
                          onResolve={async ({ provider, data }: IResolveParams) => {
                            if (provider === "microsoft") {
                              setIsadding(true);

                              if (data) {
                                const statusCode = await signIn(provider, data);
                                //console.log("statusCode", statusCode);
                                if (statusCode === 200) {
                                  setIsadding(false);
                                  setUserEmail(data.userPrincipalName);
                                  setOtpVisible(true);
                                } else {
                                  console.log("invaild accesss")
                                  setIsadding(false);
                                  show("Invalid Access", "error", "Error");
                                }
                              }
                            }
                          }}
                          onReject={(err: any) => {
                            return err;
                          }}
                          scope="profile openid email User.Read"
                        >
                          <button type="button" className="login-btn-microsoftbutton">
                            <div className="row align-items-center">
                              <div className="col-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                >
                                  <title>MS-SymbolLockup</title>
                                  <rect
                                    x="1"
                                    y="1"
                                    width="9"
                                    height="9"
                                    fill="#f25022"
                                  />
                                  <rect
                                    x="1"
                                    y="11"
                                    width="9"
                                    height="9"
                                    fill="#00a4ef"
                                  />
                                  <rect
                                    x="11"
                                    y="1"
                                    width="9"
                                    height="9"
                                    fill="#7fba00"
                                  />
                                  <rect
                                    x="11"
                                    y="11"
                                    width="9"
                                    height="9"
                                    fill="#ffb900"
                                  />
                                </svg>
                              </div>
                              <div className="col-8 login-text-microsoft text-left">
                                <span onClick={() => setVisible(true)}>
                                  {" "}
                                  Sign in with Microsoft
                                </span>
                              </div>
                            </div>
                          </button>
                        </LoginSocialMicrosoft>
                      </div>
                    </div>
                  </div>
                </div>
              }


              {/* {otpVisible ? (
                <div className="resendotp-div">
                  <p className="otp-text">Did not receive OTP?</p>
                  <p className="resend-link">RESEND OTP</p>
                </div>
              ) : (
                <div>{""}</div>
              )} */}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}