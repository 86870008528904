import React, { useRef, useState } from 'react';
import { Image } from "primereact/image";
import { devsas } from "../../../config/constants";
import { Galleria } from 'primereact/galleria';


interface props {
  clinicimages: string[];
  dietitianimages: string[];
}

const ClinicAndDietitianImages: React.FC<props> = ({ dietitianimages, clinicimages }) => {
  const galleriaDietitian = useRef<Galleria>(null);
  const galleriaClinic = useRef<Galleria>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const itemTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
  }

  const thumbnailTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ display: 'block' }} />;
  }
  return (
    <div className='d-flex flex-column gap-4 p-3'>
      <div className='d-flex flex-column gap-2'>

        <div className='picheads-store'>
          Dietitian Photos ({dietitianimages.length})
        </div>
        <div className='pt-2 '>
          <Galleria ref={galleriaDietitian} value={dietitianimages} numVisible={7} style={{ maxWidth: '850px' }}
            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
            fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
          <div className="image_gallery">
            {
              dietitianimages && dietitianimages.map((image: any, index: any) => {
                let imgEl = <img src={image + "?" + devsas} alt={image.alt} style={{ cursor: 'pointer' }} onClick={
                  () => { setActiveIndex(index); galleriaDietitian.current?.show() }
                } />
                return (
                  <div className="rest-img" key={index}>
                    {imgEl}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className='d-flex flex-column gap-2'>

        <div className='picheads-store'>
          Clinic Photos ({clinicimages.length})
        </div>
        <div className='pt-2'>
          <Galleria ref={galleriaClinic} value={clinicimages} numVisible={7} style={{ maxWidth: '850px' }}
            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
            fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
          <div className="image_gallery">
            {
              clinicimages && clinicimages.map((image: any, index: any) => {
                let imgEl = <img src={image + "?" + devsas} alt={image.alt} style={{ cursor: 'pointer' }} onClick={
                  () => { setActiveIndex(index); galleriaClinic.current?.show() }
                } />
                return (
                  <div className="rest-img" key={index}>
                    {imgEl}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

    </div>
  );
}

export default ClinicAndDietitianImages;
