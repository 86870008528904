import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GetHealthtips } from '../../api/healthtips.api';
import { yeldamwebimagessas } from '../../config/constants';
import "./Healthtips-Table.component.css";

type healthtipsprops = {
    actionBodyTemplate: any,
    setLoading: any

}


const HealthtipsTable: React.FC<healthtipsprops> = ({ actionBodyTemplate, setLoading }) => {
    const imageBodyTemplate = (rowData: any) => {
        return <img src={rowData.tipimages + "?" + yeldamwebimagessas} alt={rowData.imageurl} className="w-6rem shadow-2 border-round" />;

    };
    const [data, setData] = useState<any[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const requestData = {
                    rows:10,
                    page:1,
                    sortOrder: -1,
                    sortField: "createdAt",
                    filters: {
                        tipcategory: { value: "" },
                        tipname: { value: "" }
                    }
                };
                const healthTipsData = await GetHealthtips(requestData);
                console.log("Healthtips all:", healthTipsData?.data?.healthtips);
                setData(healthTipsData?.data?.healthtips);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        setLoading(false)
    }, []);



    return (
        <div>
            <DataTable
                className='healthtips-table'
                value={data}
                paginator
                rows={10}
            >

                <Column field='serialnumber' header='S.NO' body={(rowData, columnMeta) => columnMeta.rowIndex + 1} />
                <Column field='tipname' header='Title' />
                <Column field='tipcategory' header='Category' />
                <Column field='tipimages' body={imageBodyTemplate} header='Image' />
                <Column field='tipstatus' header='Status' />
                <Column body={(rowData, columnMeta) => actionBodyTemplate(rowData)} field='action' header='Action' />

            </DataTable>

        </div>
    );
}

export default HealthtipsTable;
